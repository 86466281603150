import React, { useState, useEffect, memo } from 'react';
import { DataEntry } from './DataEntryFormFunctions';
import { Button } from 'semantic-ui-react';

const handleChange = (name, value, setResourceState) => {
  if (name.at(-1) === "]") {
    let nameSplit = name.split("[");
    setResourceState(prevState => {
      let newValue = prevState[nameSplit[0]].map((entry, entryIndex) => {
        if (entryIndex === parseInt(nameSplit[1])) {
          return value;
        } else {
          return entry;
        }
      })
      return { ...prevState, [nameSplit[0]]: newValue }
    });
  } else {
    setResourceState(prevState => { return { ...prevState, [name]: value } })
  }
}

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}

const SectionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed, isSubsection, profile }) => {
  let codeValueSet = [
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "evidence", "display": "Evidence" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "evidence-with-comparator-alone", "display": "Evidence with comparator alone" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "evidence-with-intervention-alone", "display": "Evidence with intervention alone" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "evidence-with-total-group", "display": "Evidence with total group" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "evidence-with-intervention-vs-comparator", "display": "Evidence with intervention vs. comparator" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-comparator-alone", "display": "Result with comparator alone" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-intervention-alone", "display": "Result with intervention alone" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-intervention-alone-calculated", "display": "Result with intervention alone (calculated)" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-intervention-vs-comparator", "display": "Result with intervention vs. comparator" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "population", "display": "Population" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "intervention", "display": "Intervention" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "intervention-description", "display": "Intervention Description" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "intervention-group", "display": "Intervention Group" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "comparator", "display": "Comparator" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "comparator-description", "display": "Comparator Description" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "comparator-group", "display": "Comparator Group" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "study-design", "display": "Study Design" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "baseline-measures", "display": "Baseline Measures" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "participant-flow", "display": "Participant Flow" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "outcome-measures", "display": "Outcome Measures" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "outcome-measure", "display": "Outcome Measure" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "EvidenceVariable", "display": "Evidence Variables used" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "EvidenceVariable-observed", "display": "Evidence Variables actually observed" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "EvidenceVariable-intended", "display": "Evidence Variables intended for interpretation" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "EvidenceVariable-exposure", "display": "Evidence Variable in variable role Exposur" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "EvidenceVariable-outcome", "display": "Evidence Variable in variable role Outcome (MeasuredVariable)" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Efficacy-outcomes", "display": "Efficacy-outcomes" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Harms-outcomes", "display": "Harms outcomes" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "sample-size", "display": "Sample Size" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "references", "display": "References" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "assertion", "display": "Assertion" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Reasons", "display": "Reasons" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "certainty-of-evidence", "display": "Certainty of Evidence" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "classifier", "display": "Classifier" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "introduction", "display": "Introduction" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "recommendations", "display": "Recommendations" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "discussion", "display": "Discussion" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "methods", "display": "Methods" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "acknowledgements", "display": "Acknowledgements" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "appendices", "display": "Appendices" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "recommendation-specification", "display": "Recommendation Specification" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "ratings", "display": "Ratings" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "summary", "display": "Summary" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "related-items", "display": "Related Items" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "strength-of-recommendation", "display": "Strength of Recommendation" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "direction-of-recommendation", "display": "Direction of Recommendation" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "action", "display": "Action" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "opposite-action", "display": "Opposite Action" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "desirable-effects", "display": "Desirable Effects" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "undesirable-effects", "display": "Undesirable Effects" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "net-effect", "display": "Net Effect" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Warnings", "display": "Warnings" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Text-Summary", "display": "Text Summary" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "SummaryOfBodyOfEvidenceFindings", "display": "Summary of Body of Evidence Findings" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "SummaryOfIndividualStudyFindings", "display": "Summary of Individual Study Findings" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Header", "display": "Header" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Tables", "display": "Tables" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Table", "display": "Table" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Row-Headers", "display": "Row Headers" },
    { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Column-Header", "display": "Column Header" },
    { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "column-headers", "display": "Column Headers" }
  ];
  let focusReferencedResourceTypes;
  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let entryReferencedResourceTypes;
  let emptyReasonValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
  ];
  if (profile === "SummaryOfFindings") {
    codeValueSet = [
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "outcome-measure", "display": "Outcome Measure" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "sample-size", "display": "Sample Size" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-comparator-alone", "display": "Result with comparator alone" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-intervention-alone", "display": "Result with intervention alone" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-intervention-alone-calculated", "display": "Result with intervention alone (calculated)" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "result-with-intervention-vs-comparator", "display": "Result with intervention vs. comparator" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "certainty-of-evidence", "display": "Certainty of Evidence" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "assertion", "display": "Assertion" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "summary-of-findings-entry-for-a-single-outcome", "display": "Summary of findings entry for a single outcome" },
      { "system": "http://hl7.org/fhir/evidence-report-section", "code": "data-source", "display": "Data Source" },
      { "system": "http://hl7.org/fhir/evidence-report-section", "code": "References", "display": "References" },
      { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Warnings", "display": "Warnings" },
      { "system": "http://hl7.org/fhir/evidence-report-section", "code": "Text-Summary", "display": "Text Summary" },
      { "system": "http://hl7.org/fhir/evidence-report-section", "code": "SummaryOfBodyOfEvidenceFindings", "display": "Summary of Body of Evidence Findings" },
      { "system": "http://hl7.org/fhir/evidence-report-section", "code": "SummaryOfIndividualStudyFindings", "display": "Summary of Individual Study Findings" },
      { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "column-headers", "display": "Column Headers" }
    ];
    focusReferencedResourceTypes = ['Citation', 'Composition', 'Evidence', 'EvidenceVariable']
    entryReferencedResourceTypes = ['ArtifactAssessment', 'Citation', 'Composition', 'Evidence', 'EvidenceVariable', 'ResearchStudy'];
  }
  let startingSection = {
    title: "", code: "", author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      let newSection = {};
      if (sectionState.extension) { newSection.extension = sectionState.extension; }
      if (sectionState.title) { newSection.title = sectionState.title; }
      if (sectionState.code) { newSection.code = sectionState.code; }
      if (Array.isArray(sectionState.author) && sectionState.author.length > 0) {
        newSection.author = sectionState.author;
      }
      if (sectionState.focus) { newSection.focus = sectionState.focus; }
      if (sectionState.text) { newSection.text = sectionState.text; }
      if (sectionState.orderedBy) { newSection.orderedBy = sectionState.orderedBy; }
      if (Array.isArray(sectionState.entry) && sectionState.entry.length > 0) {
        newSection.entry = sectionState.entry;
      }
      if (sectionState.emptyReason) { newSection.emptyReason = sectionState.emptyReason; }
      if (Array.isArray(sectionState.section) && sectionState.section.length > 0) {
        newSection.section = sectionState.section;
      }
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      handleChange(elementName, newSection, setResourceState);
    }
  }), [sectionState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <div style={{ marginLeft: "24px" }}>
          {startingSection.title && <p><b>Title: </b>{startingSection.title}</p>}
          {startingSection.text?.div && <p><b>Summary: </b>{startingSection.text.div}</p>}
          {startingSection.section?.length > 0 && <div>
            <p><b>Subsections:</b></p>
            {startingSection.section.map((section, sectionIndex) => {
              return <div key={sectionIndex} style={{ marginLeft: "24px" }}>
                {section.title && <span><b>Title: </b>{section.title}</span>}
                {section.text?.div && <p><b>Summary: </b>{section.text.div}</p>}
              </div>
            })}
          </div>}
          <br />
        </div>}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
        &nbsp;&nbsp;
        {fieldLabel}
      </div>
    </>
  } else {
    return <>
      <p><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Section Title'
          startingValue={sectionState.title} setResourceState={setSectionState} />
        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Section Code'
          startingValue={sectionState.code} valueSet={codeValueSet} startCollapsed
          setResourceState={setSectionState} />
        <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel='Section Author'
          startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
          setResourceState={setSectionState} startCollapsed />
        <DataEntry datatype='Reference' elementName='focus' fieldLabel='Section Focus'
          startingValue={sectionState.focus} referencedResourceTypes={focusReferencedResourceTypes}
          setResourceState={setSectionState} startCollapsed />
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Section Summary' startCollapsed
          startingValue={sectionState.text} setResourceState={setSectionState} />
        <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
          startingValue={sectionState.orderedBy} valueSet={orderedByValueSet} startCollapsed
          setResourceState={setSectionState} />
        <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='Content Entry'
          startingValue={sectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
          setResourceState={setSectionState} startCollapsed />
        <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
          startingValue={sectionState.emptyReason} valueSet={emptyReasonValueSet}
          setResourceState={setSectionState} startCollapsed />
        {!isSubsection && <><p><b>Contained Sections:</b></p>
          <CompositionSectionDataEntry startingValue={sectionState.section} 
          setResourceState={setSectionState}
            isSubsection={true} profile={profile} />
        </>
        }
      </div>
    </>
  }
})

const CompositionSectionDataEntry = memo(({ startingValue, setResourceState, isSubsection, profile }) => {
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    //startingArrayAsObject['0'] = null;
  }
  let selectedProfile;
  if (Array.isArray(profile)) {
    for (const itemEntry of profile) {
      let item = itemEntry.replace('https://hl7.org', 'http://hl7.org')
      if (item === "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings") {
        selectedProfile = "SummaryOfFindings";
      } else if (item === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation") {
        selectedProfile = "Recommendation";
      } else if (item === "http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline") {
        selectedProfile = "Guideline";
      } else if (item === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report") {
        selectedProfile = "ComparativeEvidenceReport";
      }
    }
  }
  if (typeof profile === "string") {
    selectedProfile = profile;
  }

  const [arrayState, setArrayState] = useState(startingArrayAsObject);

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null &&
          !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) && !(Array.isArray(arrayState[key]) && arrayState[key].length === 0)
          && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      if (newArray.length > 0) {
        handleChange("section", newArray, setResourceState);
      } else {
        handleChange("section", null, setResourceState);
      }
    }
  }), [arrayState]);

  return <div>
    <div style={{ marginLeft: "24px" }}>
      {Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
        return <div key={keyValuePairIndex}>
          {keyValuePair[1] === "DELETEME" ?
            <>
              <p><b>{'Section entry ' + (keyValuePairIndex + 1)}: </b></p>
              <div style={{ marginLeft: "24px" }}>WILL BE DELETED.</div>
            </>
            :
            <SectionEntry elementName={keyValuePair[0]} profile={selectedProfile}
              fieldLabel={'Section entry ' + (keyValuePairIndex + 1)}
              startingValue={keyValuePair[1]} setResourceState={setArrayState}
              startCollapsed={keyValuePair[1] && true} isSubsection={isSubsection} />
          }
          {keyValuePairIndex > 0 && <span style={{ marginLeft: "24px" }}>
            <Button className="formButton" style={{ color: "#000000" }} content={"x Delete this Section"}
              onClick={() => {
                setArrayState(prevState => {
                  return {
                    ...prevState,
                    [keyValuePairIndex.toString()]: "DELETEME"
                  };
                });
              }} />
            <br /><br /><br />
          </span>}
        </div>
      })}
      <><br />
        <Button className="formButton" style={{ color: "#000000" }} content={"+ Add Section"}
          onClick={() => {
            setArrayState(prevState => {
              let arrayLength = Object.keys(prevState).length;
              let arrayIndexNumber = arrayLength.toString();
              if (prevState['0'] === null) {
                arrayIndexNumber = '1';
              }
              return {
                ...prevState,
                //['0']: prevState['0'] || "",
                [arrayIndexNumber]: ""
              };
            });
          }} />
      </>
    </div>
  </div>
});

export default CompositionSectionDataEntry;