import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { getTargetResourceRatingDictionary, submitResource } from './SummaryOfFindingsFunctions';
import RateRelativeImportance from './RateRelativeImportance';
import GenerateNetEffectReport from './GenerateNetEffectReport';
import createCompositionTableDict from './createCompositionTableDict';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const createCustomizedSummaryOfFindings = async (fhirJson, globalContext) => {
  let newResource = JSON.parse(JSON.stringify(fhirJson));
  if (!newResource.useContext) {
    newResource.useContext = [];
  }
  newResource.useContext.push({
    "code": {
      "system": "http://terminology.hl7.org/CodeSystem/usage-context-type",
      "code": "user",
      "display": "User Type"
    },
    "valueCodeableConcept": {
      "text": "FEvIR Platform User"
    }
  });
  let starterFOI = newResource.id;
  let oldTitle = JSON.parse(JSON.stringify(newResource.title));
  newResource.relatesTo = [{
    "type": "derived-from",
    "classifier": [{
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "SummaryOfFindings",
        display: "SummaryOfFindings"
      }],
      "text": "Summary of Findings Report"
    }],
    "resourceReference": {
      "reference": "Composition/" + starterFOI,
      "type": "Composition",
      "display": oldTitle
    }
  }];
  newResource.title += " customized for " + globalContext.userState.name;
  for (let section of newResource.section) {
    if (section.code.coding && section.code.coding[0].code.toLowerCase() === "column-headers") {
      section.section.push({
        "title": "Relative Importance",
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "relative-importance",
              "display": "Relative Importance"
            }
          ],
          "text": "relative importance of outcome"
        },
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Relative Importance</div>"
        }
      });
    } else {
      section.section.push({
        "title": "Relative Importance",
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "relative-importance",
              "display": "Relative Importance"
            }
          ],
          "text": "relative importance of outcome"
        },
        "emptyReason": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
              "code": "notstarted",
              "display": "Not Started"
            }
          ]
        }
      });
    }
  }

  let newFOI = await submitResource(globalContext, newResource);
  if (newFOI) {
    return { "success": true, "newFOI": newFOI };
  }
};

const SectionDisplay = ({ sect, sectIndex }) => {
  if (!sect || !Object.keys(sect).length) {
    return <div key={sectIndex} >
      <br />
      <p><b>{'Section ' + (sectIndex + 1).toString()}:</b></p>
      <>No content in this section.</>
    </div>
  }
  return <div key={sectIndex} >
    <br />
    <p><b>{sect.title ? sect.title : 'Section ' + (sectIndex + 1).toString()}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(sect.code && Object.keys(sect.code).length > 0) && <span><b>Code: </b><DisplayFromFHIR codeableConcept={sect.code} /><br /></span>}
      {(sect.focus && Object.keys(sect.focus).length > 0) && <span><b>Focus: </b><DisplayFromFHIR reference={sect.focus} /><br /></span>}
      {(Array.isArray(sect.author) && sect.author.length > 0) &&
        sect.author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(sect.text && Object.keys(sect.text).length > 0) && <div>
        {sect.text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={sect.text.div} />
            </div>
          </div>}
        <br />
      </div>}
      {(sect.orderedBy && Object.keys(sect.orderedBy).length > 0) && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={sect.orderedBy} /><br /></span>}
      {(Array.isArray(sect.entry) && sect.entry.length > 0) &&
        sect.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {(sect.emptyReason && Object.keys(sect.emptyReason).length > 0) && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={sect.emptyReason} /><br /></span>}
      {(Array.isArray(sect.section) && sect.section.length > 0) &&
        sect.section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}><SectionDisplay sect={subsection} sectIndex={subsectionIndex} /></span>;
        })}
    </div>
  </div>
}

const SummaryOfFindingsViewerTool = ({ changeFormState, fhirEntryState, setFhirEntryState, fhirJson, formInputsStateRef, classificationsArrayState, classificationsLoadedState, globalContext, history }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
        break;
      }
    }
  }
  const getNetEffectPointEstimate = () => {
    if (fhirJson?.section) {
      for (const section of fhirJson.section) {
        if (section.code?.text === 'Net Effect Point Estimate') {
          if (section.text?.div) {
            return section.text.div;
          }
        }
      }
    }
  }
  let netEffectPointEstimate = getNetEffectPointEstimate() || null;
  const getNetEffectEstimate = () => {
    if (fhirJson?.section) {
      for (const section of fhirJson.section) {
        if (section.code?.text === 'Net Effect Estimate' || section.code?.coding?.[0]?.code === "net-effect-estimate") {
          if (section.text?.div) {
            return section.text.div;
          }
        }
      }
    }
  }
  let netEffectEstimate = getNetEffectEstimate() || null;

  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description' && extension.valueMarkdown) {
        description = extension.valueMarkdown;
      }
    }
  }

  let startingCustomizedState = { "customized": false, "userid": "", "username": "" };
  if (fhirJson.useContext?.length > 0) {
    for (const useContext of fhirJson.useContext) {
      if (useContext.code?.system === "http://terminology.hl7.org/CodeSystem/usage-context-type" && useContext.code.code === "user" &&
        useContext.valueCodeableConcept?.text === "FEvIR Platform User") {
        startingCustomizedState = {
          "customized": true,
          "userid": globalContext.userState.id,
          "username": globalContext.userState.name
        };
      }
    }
  }
  const [customizedState, setCustomizedState] = useState(startingCustomizedState);

  let targetResources = fhirJson.section
    ?.filter(section => section.title !== "Column Headers")
    ?.map(section => {
      let targetfoi = section.focus?.reference?.replace("EvidenceVariable/", "");
      let targetfli;
      if (!targetfoi && section.focus?.identifier?.system === "https://fevir.net/FLI" && section.focus.identifier.value) {
        targetfli = section.focus.identifier.value;
      }
      let targettitle = section.focus?.display;
      return {
        id: targetfoi,
        title: targettitle,
        fli: targetfli
      };
    });

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "status": fhirJson.status,
    "type": fhirJson.type || {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "SummaryOfFindings",
        display: "SummaryOfFindings"
      }],
      text: 'Summary of Findings'
    }, "category": fhirJson.category, "subject": fhirJson.subject, "encounter": fhirJson.encounter,
    "date": fhirJson.date, "useContext": fhirJson.useContext, "author": fhirJson.author,
    "name": fhirJson.name, "title": fhirJson.title, "note": fhirJson.note, "attester": fhirJson.attester,
    "custodian": fhirJson.custodian, "relatesTo": fhirJson.relatesTo, "event": fhirJson.event,
    "section": fhirJson.section, "newClassifications": null, "targetResources": targetResources
  });
  const [needToRefreshState, setNeedToRefreshState] = useState(false);
  const [sourceJsonState, setSourceJsonState] = useState({});
  let tableData = createCompositionTableDict(resourceState);

  useImperativeHandle(formInputsStateRef, () => ({
    compositionDocumentState: resourceState
  }), [resourceState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, setResourceState, null, null, customizedState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, []);

  /*
  useEffect(() => {
    if (sourceJsonState.loaded === true) {
      submitUpdatedFhirResource(null, "Composition", fhirJson.id, {summaryOfFindingsState: resourceState}, fhirEntryState, setFhirEntryState, 
      globalContext, history, changeFormState, false, undefined);
    }
  }, [resourceState.section]);
  */

  const refreshResourceJson = async () => {
    if (needToRefreshState) {
      let response = await getTargetResourceRatingDictionary(resourceState.targetResources, globalContext);
      if (response?.success && response.targetResourceRatingDictionary) {
        let ratingDictionary = response.targetResourceRatingDictionary;
        setResourceState(prevState => {
          let newSection = JSON.parse(JSON.stringify(prevState.section));
          let loadedSection = newSection.map(section => {
            if (section.focus?.reference) {
              let targetfoi = section.focus.reference.replace("EvidenceVariable/", "");
              if (!targetfoi) {
                let targetfli;
                if (section.focus?.identifier?.system === "https://fevir.net/FLI" && section.focus.identifier.value) {
                  targetfli = section.focus.identifier.value;
                }
                for (const foiKey in ratingDictionary) {
                  if (ratingDictionary[foiKey].fli == targetfli) {
                    targetfoi = foiKey;
                  }
                }
              }
              if (ratingDictionary[targetfoi]) {
                let entry = ratingDictionary[targetfoi];
                if (entry.exists === false) {
                  section.section[8] = {
                    "title": "Relative Importance",
                    "code": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/179423",
                          "code": "relative-importance",
                          "display": "Relative Importance"
                        }
                      ],
                      "text": "relative importance of outcome"
                    },
                    "emptyReason": {
                      "coding": [
                        {
                          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                          "code": "notstarted",
                          "display": "Not Started"
                        }
                      ]
                    }
                  }
                }
                if (entry.exists === true) {
                  section.section[8] = {
                    "title": "Relative Importance",
                    "code": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/179423",
                          "code": "relative-importance",
                          "display": "Relative Importance"
                        }
                      ],
                      "text": "relative importance of outcome"
                    },
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + entry.desirabilityDisplay + " " + entry.quantityValue.toString() + "%</div>"
                    },
                    "entry": [
                      {
                        "reference": "ArtifactAssessment/" + targetfoi, //TODO
                        "type": "ArtifactAssessment",
                        "display": entry.targettitle //TODO
                      }
                    ]
                  }
                }
              }
            }
            return section;
          });
          return {
            ...prevState,
            "section": loadedSection,
            "ratingDictionary": ratingDictionary
          };
        });
        setNeedToRefreshState(false);
      }
    }
  }

  useEffect(() => {
    refreshResourceJson();
  }, [needToRefreshState]);

  return <>{sourceJsonState.loaded ?
    <div>
      <div style={{ marginTop: "12px" }}>
        <h3 id="summary">Summary</h3>
        <div style={{ marginLeft: "24px" }}>
          <span><b>Title: </b> {fhirJson.title ? fhirJson.title : <>Not stated</>}</span>
          <br />
          {description &&
            <span>
              <b>Description: </b><DisplayFromFHIR markdown={description} /><br />
            </span>}
          {(Array.isArray(fhirJson.note) && fhirJson.note.length > 0) &&
            fhirJson.note.map((note, noteIndex) => {
              return <div key={noteIndex}><b>Note: </b><DisplayFromFHIR annotation={note} /></div>;
            })}
          {fhirJson.type &&
            <span>
              <b>Type: </b><DisplayFromFHIR codeableConcept={fhirJson.type} /><br />
            </span>}
          {(Array.isArray(fhirJson.category) && fhirJson.category.length > 0) &&
            fhirJson.category.map((category, categoryIndex) => {
              return <div key={categoryIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={category} /></div>;
            })}
          {fhirJson.subject && <span><b>Subject: </b>
            {fhirJson.subject.length > 0 ?
              <>{fhirJson.subject.map((subject, subjectIndex) => {
                return <div key={subjectIndex}><DisplayFromFHIR reference={subject} /></div>;
              })}
              </>
              :
              <>Not properly structured JSON or missing subject data</>
            }
          </span>}
          {fhirJson.encounter &&
            <span>
              <b>Encounter: </b><DisplayFromFHIR reference={fhirJson.encounter} /><br />
            </span>}
          <br />
        </div>
        {customizedState.customized === false && <>
          <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
            content="Customize this Summary of Findings" positive
            onClick={async () => {
              setCustomizedState({
                "customized": true,
                "userid": globalContext.userState.id,
                "username": globalContext.userState.name
              });
              let response = await createCustomizedSummaryOfFindings(fhirJson, globalContext);
              if (response?.success && response.newFOI) {
                let newUrl = "/resources/Composition/" + response.newFOI;
                history.push('/');
                history.push(newUrl);
              }
            }}
          />
          <br />
          <br />
        </>}
        {customizedState.customized === true && <>
          <RateRelativeImportance globalContext={globalContext}
            previousVersionLoaded={fhirEntryState.previousVersionLoaded}
            resourceState={resourceState} setResourceState={setResourceState}
            setNeedToRefreshState={setNeedToRefreshState} />
          <GenerateNetEffectReport globalContext={globalContext}
            resourceDictionary={sourceJsonState.resourceDictionary}
            resourceState={resourceState} previousVersionLoaded={fhirEntryState.previousVersionLoaded}
            history={history}
          />
        </>}
        <h3 id="table-view">Table View</h3>
        {(tableData?.Labels && tableData.Data && Object.keys(tableData.Labels).length > 0) ?
          <Table>
            <Table.Header>
              <Table.Row>
                {Object.keys(tableData.Labels).map((label, labelIndex) => {
                  return <Table.HeaderCell key={labelIndex}>
                    <DisplayFromFHIR xhtml={label} />
                  </Table.HeaderCell>
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.keys(tableData.Data).map((entry, entryIndex) => {
                return <Table.Row key={entryIndex}>{tableData.Data[entry]["REPLACEROW"] ?
                  <><Table.Cell colSpan={8}>
                    <DisplayFromFHIR xhtml={tableData.Data[entry]["REPLACEROW"]} />
                  </Table.Cell></>
                  :
                  Object.keys(tableData.Data[entry]).map((item, itemIndex) => {
                    return <Table.Cell key={itemIndex}><DisplayFromFHIR xhtml={tableData.Data[entry][item]} /></Table.Cell>
                  })
                }</Table.Row>
              })}
            </Table.Body>
            <>{netEffectPointEstimate &&
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell className="netEffectCell" textAlign='right'
                    colSpan={Object.keys(tableData.Labels).length}>
                    <b>Net Effect Point Estimate = <DisplayFromFHIR xhtml={netEffectPointEstimate} /></b>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            }</>
            <>{netEffectEstimate &&
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell className="netEffectCell" textAlign='right'
                    colSpan={Object.keys(tableData.Labels).length}>
                    <b>Net Effect Estimate = <DisplayFromFHIR xhtml={netEffectEstimate} /></b>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            }</>
          </Table>
          :
          <>No tabular data.</>
        }
        <h3 id="section-detail">Section Detail</h3>
        {(resourceState.section && resourceState.section.length > 0) ?
          <div style={{ marginLeft: "24px" }}>
            {resourceState.section.map((sect, sectIndex) => {
              return <span key={sectIndex}>
                <SectionDisplay sect={sect} sectIndex={sectIndex} />
              </span>;
            })}
          </div>
          :
          <>No section data.</>
        }
        <br />
        <h3 id="how-to-cite">How to Cite</h3>
        <div style={{ marginLeft: "24px" }}>
          {(howToCite) ?
            <div>
              <p><b>Citation Summary:</b></p>
              <DisplayFromFHIR markdown={howToCite} />
            </div>
            :
            <p>Can be added upon editing.</p>
          }
        </div>
        <h3 id="metadata">Metadata</h3>
        <div style={{ marginLeft: "24px" }}>
          <MetadataPatternDisplay fhirJson={fhirJson} />
        </div>
        <h3 id="associated-resources">Associated Resources</h3>
        <div style={{ marginLeft: "24px" }}>
          <AssociatedResourcesDisplay fhirJson={fhirJson} />
        </div>
        <h3 id="classifiers">Classifiers</h3>
        {classificationsLoadedState ?
          <DisplayClassifiers classificationsArray={classificationsArrayState} />
          :
          <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
        }
        <h3 id="json-outline">JSON Outline</h3>
        <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
        <br /><br />
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
}

export default SummaryOfFindingsViewerTool;