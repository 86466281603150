import React, { useState, useEffect, useImperativeHandle } from 'react';
import { ReorganizeSectionsModal, AssociatedResourcesDisplay, DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry, DisplayHowToCite } from './DataEntryFormFunctions';
import { DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceReportMetadataPatternEdit } from './EvidenceReportMetadataDataEntry';
import { TextField } from '@mui/material';
import { Button } from 'semantic-ui-react';
import submitToFevirServer from './SubmitToFevirServer';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import ProfilesByResourceType from './ProfilesByResourceType';

const RecommendationsDisplay = ({ recommendationDisplayValuesDictionary }) => {

  let recommendationKeys = [];
  if (recommendationDisplayValuesDictionary && typeof recommendationDisplayValuesDictionary === 'object' &&
    Object.keys(recommendationDisplayValuesDictionary).length > 0) {
    recommendationKeys = Object.keys(recommendationDisplayValuesDictionary);
  }
  let numberOfRecommendations = recommendationKeys.length || 0;

  if (numberOfRecommendations === 0) {
    return <div>
      <p>There are no recommendations yet. Create a Recommendation above to add one, or <a
        href='/createrecommendation' target='_blank'
        rel='noopener noreferrer'>Create Recommendation</a> then Add Content Entry above to associate it with this guideline.</p>
    </div>
  }

  return <div>
    <div style={{ marginLeft: "24px" }}>
      {numberOfRecommendations > 0 && recommendationKeys.map((key, keyIndex) => {
        const rec = recommendationDisplayValuesDictionary[key];
        return <div key={keyIndex} >
          <p><b>Recommendation {keyIndex + 1} of {numberOfRecommendations}</b></p>
          <div style={{ marginLeft: "24px" }}>
            {rec.title && <p><b>Title: </b>{rec.title}</p>}
            {rec.statement && <span><b>Recommendation Statement: </b><DisplayFromFHIR xhtml={rec.statement} /><br /></span>}
            {rec.ratings && <span><b>Strength/Level of Recommendation: </b><DisplayFromFHIR xhtml={rec.ratings} /><br /></span>}
            {rec.population && <span><b>Population: </b><DisplayFromFHIR xhtml={rec.population} /><br /></span>}
            {rec.action && <span><b>Action: </b><DisplayFromFHIR xhtml={rec.action} /><br /></span>}
            {rec.resourceReference && <span><b>Recommendation Resource: </b>
              <DisplayFromFHIR reference={rec.resourceReference} /></span>}
          </div>
        </div>
      })}
    </div>
  </div>
}

//createNewRecommendationResource(referenceState.display, globalContext);
const createNewRecommendationResource = async (title, globalContext) => {
  const newResource = {
    "resourceType": "Composition",
    "meta": { "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation"] },
    "title": title,
    "status": "final",
    "type": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "Recommendation",
        display: "Recommendation"
      }]
    },
    "section": ProfilesByResourceType.Composition.Recommendation.sectionStarter
  };
  const body = {
    'functionid': "submitfhirresource",
    'idToken': "",
    'fhirEntry': JSON.stringify(newResource),
    'title': title,
    'status': 'active',
  };
  let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  let resourceFOI;
  if (response.success) {
    resourceFOI = response.formstateid;
  }
  return resourceFOI;
};

const CreateRecommendationResource = ({ startingValue, setResourceState, globalContext }) => {
  let startingReference = { "reference": "", "type": "Composition", "display": "" };
  const [referenceState, setReferenceState] = useState(startingReference);
  const [showButtonState, setShowButtonState] = useState(false);

  useEffect((() => {
    if (referenceState.reference) {
      let newReference = JSON.parse(JSON.stringify(referenceState));
      let newReferenceArray = startingValue;
      newReferenceArray.push(newReference);
      setResourceState(prevState => { return { ...prevState, "entry": newReferenceArray } });
      setShowButtonState(false);
      setReferenceState(startingReference);
    }
  }), [referenceState]);

  return <div>
    <p><b>Enter a title to create a new Recommendation.</b></p>
    <TextField style={{ width: "100%", marginTop: "16px" }} multiline
      className="inputField" type='text' label="Title for new Recommendation"
      size="small" variant='outlined'
      onChange={(e) => {
        setReferenceState(prevState => { return { ...prevState, "display": e.target.value } });
        if (e.target.value && showButtonState === false) { setShowButtonState(true); }
      }} />
    {showButtonState && <Button className="formButton" style={{ color: "#000000" }}
      content={`Create this Recommendation Resource`}
      onClick={async () => {
        let resourceFOI = await createNewRecommendationResource(referenceState.display, globalContext);
        setReferenceState(prevState => {
          let reference = `Composition/${resourceFOI}`;
          return {
            ...prevState,
            reference: reference
          };
        });
        alert("New Recommendation created with FOI " + resourceFOI + ". Click Update to save before editing Recommendation entries.");
      }} />}
  </div>
}
/** 
const TopSectionDataEntry = ({ sectionCode, startingValue, resourceState, setResourceState, globalContext }) => {

  let startingTopSection = {
    "title": sectionCode + " Section",
    "code": { "text": sectionCode },
    "author": [],
    "focus": {},
    "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  if (startingValue) {
    if (startingValue.title) { startingTopSection.title = startingValue.title; }
    if (startingValue.author) { startingTopSection.author = startingValue.author; }
    if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
    if (startingValue.text) { startingTopSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingTopSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingTopSection.section = startingValue.section; }
  } else {
    startingTopSection = {
      "title": sectionCode + " Section",
      "code": { "text": sectionCode }
    };
  }

  const [topSectionState, setTopSectionState] = useState(startingTopSection);

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let entryReferencedResourceTypes = ['ActivityDefinition', 'ArtifactAssessment', 'Citation', 'Composition', 'Evidence', 'EvidenceVariable', 'Group', 'List', 'Organization', 'PlanDefinition', 'ResearchStudy'];
  let emptyReasonValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
  ];

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(resourceState));
    let newSection = JSON.parse(JSON.stringify(topSectionState));
    if (newSection.section?.length || newSection.entry?.length ||
      (newSection.text && newSection.text.status !== "empty")) {
      delete newSection.emptyReason;
    } else if (!newSection.emptyReason) {
      newSection.emptyReason = {
        "coding": [{
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted", "display": "Not Started"
        }]
      }
    }
    if (sectionCode === "Introduction") {
      newResource.section[0] = newSection;
    } else if (sectionCode === "Recommendations") {
      newResource.section[1] = newSection;
    } else if (sectionCode === "Text") {
      newResource.section[2] = newSection;
    } else if (sectionCode === "Methods") {
      newResource.section[3] = newSection;
    } else if (sectionCode === "Acknowledgements") {
      newResource.section[4] = newSection;
    } else if (sectionCode === "Appendices") {
      newResource.section[5] = newSection;
    }
    setResourceState(newResource);
  }, [topSectionState]);

  return <>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='title' fieldLabel={sectionCode + ' Section Title'}
        startingValue={topSectionState.title} setResourceState={setTopSectionState} />
      <DataEntry datatype='Narrative' elementName='text' fieldLabel={sectionCode + ' Section Summary'}
        startingValue={topSectionState.text} setResourceState={setTopSectionState} />
      <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
        startCollapsed startEmptyArrayClosed enableCreation={true}
        startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
        setResourceState={setTopSectionState} />
      <h3>{sectionCode} Section Content (as Resource Entries)</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
          startingValue={topSectionState.orderedBy} valueSet={orderedByValueSet}
          startCollapsed
          setResourceState={setTopSectionState} />
        {(sectionCode === "Recommendations") ?
          <>
            <CreateRecommendationResource startingValue={topSectionState.entry}
              setResourceState={setTopSectionState} globalContext={globalContext} />
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='Edit Recommendation References'
              startingValue={topSectionState.entry} referencedResourceTypes={['Composition']}
              startCollapsed startEmptyArrayClosed setResourceState={setTopSectionState} />
          </>
          :
          <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='Content Entry'
            startingValue={topSectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            setResourceState={setTopSectionState} />
        }
        <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
          startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
          setResourceState={setTopSectionState} />
      </div>
      <h3>Contained Sections (for text with subheadings)</h3>
      <GuidelineSectionDataEntry startingValue={topSectionState.section}
        setResourceState={setTopSectionState}
        topSection={sectionCode} />
    </div>
  </>
}
*/

const reorderSections = (sectionArray) => {
  let emptySummary = {
    "title": "Summary Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "summary",
        display: "Summary"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "introduction",
        display: "Introduction"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "discussion",
        display: "Discussion"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "methods",
        display: "Methods"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyReferences = {
    "title": "References Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "references",
        display: "References"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "competing-interests",
        display: "Competing Interests"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "acknowledgements",
        display: "Acknowledgements"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "appendices",
        display: "Appendices"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyRecommendations = {
    "title": "Recommendations Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "recommendations",
        display: "Recommendations"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let sectionDictionary = {
    summary: emptySummary,
    introduction: emptyIntroduction,
    discussion: emptyDiscussion,
    methods: emptyMethods,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    recommendations: emptyRecommendations,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "summary" || sectionCode === "Summary") {
      sectionDictionary.summary = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode === "recommendations" || sectionCode === "Recommendations") {
      sectionDictionary.recommendations = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.summary,
    sectionDictionary.introduction,
    sectionDictionary.methods,
    sectionDictionary.recommendations,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  return reorderedSectionArray;
};

const GuidelineAuthoringTool = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  classificationsArrayState, classificationsLoadedState, globalContext, previousVersionLoaded, update }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "status", "type", "category", "subject", "encounter",
      "date", "useContext", "author", "name", "title", "note", "attester", "custodian", "relatesTo",
      "event", "section"]
  */
  if (Array.isArray(fhirJson.author) && fhirJson.author.length === 1 && fhirJson.author[0].name) {
    fhirJson.author[0].display = fhirJson.author[0].name;
    delete fhirJson.author[0].name;
  }

  let emptyReasonValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
  ];

  let emptyReasonCodeableConceptSet = [
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" }]
    },
    {
      "coding":
        [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }]
    },
    {
      "coding":
        [{ "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }]
    }
  ];

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "status": fhirJson.status,
    "type": fhirJson.type || {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "Guideline",
        display: "Guideline"
      }]
    }, "category": fhirJson.category, "subject": fhirJson.subject, "encounter": fhirJson.encounter,
    "date": fhirJson.date, "useContext": fhirJson.useContext, "author": fhirJson.author,
    "name": fhirJson.name, "title": fhirJson.title, "note": fhirJson.note, "attester": fhirJson.attester,
    "custodian": fhirJson.custodian, "relatesTo": fhirJson.relatesTo, "event": fhirJson.event,
    "section": reorderSections(fhirJson.section), "newClassifications": null
  });

  const [reorganizeSectionsModalState, setReorganizeSectionsModalState] = useState({});

  useImperativeHandle(formInputsStateRef, () => ({
    compositionDocumentState: resourceState
  }), [resourceState]);

  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      <div>
        {resourceState.section ? <>
          {reorganizeSectionsModalState.modalOpen && <ReorganizeSectionsModal resourceState={resourceState}
            setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState}
            setReorganizeSectionsModalState={setReorganizeSectionsModalState} update={update} elementName={"section"} />}
          <Button style={{ color: "#000000", marginTop: "18px", marginLeft: "6px", fontSize: "18px", padding: "4px" }}
            className="formButton sectionReorganizeButton"
            content="Reorganize Sections"
            onClick={() => { setReorganizeSectionsModalState(prevState => { return { ...prevState, modalOpen: true } }); }} />
          <br />
          <h3 id="summary">Summary</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={0}
              fieldLabel="Summary" sectionCode="section:https://fevir.net/resources/CodeSystem/179423#summary"
              startingValue={resourceState.section[0] || null}
              fixedTitle={"Summary"} fixedCode={{
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "summary",
                    "display": "Summary"
                  }
                ]
              }} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
              noEntry={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonStartCollapsed={true}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              editSection={true} 
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title} />
          </div>
          <div>
            <EditIntroduction sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditMethods sectionIndex={2} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <h3 id="recommendations">Recommendations</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={3}
                startingValue={resourceState.section[3] || null}
                fieldLabel="Recommendations"
                entryReferencedResourceTypes={["Composition"]} entryResourceEntry={CreateRecommendationResource}
                editTitle={true} fixedCode={{
                  "coding": [
                    {
                      "system": "https://fevir.net/resources/CodeSystem/179423",
                      "code": "recommendations",
                      "display": "Recommendations"
                    }
                  ]
                }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
                entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
                entryStartCollapsed={true} entryStartEmptyArrayClosed={true}
                entryStartingResourceType={"Composition"} entrySetProfile={"Recommendation"}
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                editSection={true} 
                editableSectionCodes={true} addSectionAllowed={true} sectionEntryRecommendationOnly={true}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title} />
              <h3>To edit the Recommendations, click through to edit directly.</h3>
              <RecommendationsDisplay recommendationDisplayValuesDictionary={sourceJsonState.recommendationDisplayValuesDictionary} />
            </div>
            <br />
            <EditDiscussion sectionIndex={4} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditReferences sectionIndex={5} previousVersionLoaded={previousVersionLoaded}
              resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditCompetingInterests sectionIndex={6} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditAcknowledgements sectionIndex={7} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditAppendices sectionIndex={8} resourceState={resourceState} setResourceState={setResourceState} />
          </div>
          <br />
        </>
          :
          <>Section element is missing in the JSON.</>
        }
        <h3 id="how-to-cite">How to Cite</h3>
        <div style={{ marginLeft: "24px" }}>
          <DisplayHowToCite citationSummary={citationSummary}
            citationJson={citationJson} />
        </div>
        <h3 id="metadata">Metadata</h3>
        <div style={{ marginLeft: "24px" }}>
          <EvidenceReportMetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
        </div>
        <h3 id="associated-resources">Associated Resources</h3>
        <div style={{ marginLeft: "24px" }}>
          <AssociatedResourcesDisplay fhirJson={fhirJson} />
        </div>
        <h3 id="classifiers">Classifiers</h3>
        <div style={{ marginLeft: "24px" }}>
          <div>
            <p>Add Classifiers:</p>
            <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
              fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
          </div>
          {(classificationsArrayState) && <div>
            <p>Existing Classifiers:</p>
            {classificationsLoadedState ?
              <DisplayClassifiers classificationsArray={classificationsArrayState} />
              :
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
            }
          </div>}
        </div>
        <h3 id="json-outline">JSON Outline</h3>
        <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
        <br /><br />
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

export default GuidelineAuthoringTool;