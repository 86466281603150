import React, { useState, useImperativeHandle } from 'react';
import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const BundleEdit = ({ fhirJson, formInputsStateRef, classificationsArrayState, classificationsLoadedState }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language",
      "identifier", "type", "timestamp", "total", "link", "entry", "signature", "issues"]
  */
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language,
    "identifier": fhirJson.identifier, "type": fhirJson.type, "timestamp": fhirJson.timestamp,
    "total": fhirJson.total, "link": fhirJson.link, "entry": fhirJson.entry,
    "signature": fhirJson.signature, "issues": fhirJson.issues, "newClassifications": null
  });

  useImperativeHandle(formInputsStateRef, () => ({
    bundleState: resourceState
  }), [resourceState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='code' elementName='type' fieldLabel='Type'
          allowedValues={['document', 'message', 'transaction', 'transaction-response', 'batch', 'batch-response',
            'history', 'searchset', 'collection', 'subscription-notification']}
          startingValue={resourceState.type} setResourceState={setResourceState} />
        <DataEntry datatype='Identifier' elementName='identifier' fieldLabel='Identifier'
          startingValue={resourceState.identifier || null} startCollapsed
          setResourceState={setResourceState} />
      </div>
      <h3 id="bundle-entries">Bundle Entries</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>Use the JSON View for editing Bundle content.</p>
        {Array.isArray(fhirJson.entry) && fhirJson.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}>
            <p><b>Entry {entryIndex + 1}: </b></p>
            <div style={{ marginLeft: "24px" }}>
              {entry.fullUrl && <div><b>URL: </b><DisplayFromFHIR uri={entry.fullUrl} />
                <br /><br /></div>}
              {entry.resource && <div>
                {entry.resource.resourceType && <p><b>Resource Type: </b>{entry.resource.resourceType}</p>}
                {entry.resource.title && <p><b>Resource Title: </b>{entry.resource.title}</p>}
                {entry.resource.name && <p><b>Resource Name: </b>{entry.resource.name}</p>}
                {entry.resource.id && <p><b>Resource ID: </b>{entry.resource.id}</p>}
                {Array.isArray(entry.resource.identifier) && entry.resource.identifier.map((identifier, identifierIndex) => {
                    return <div key={identifierIndex}><div><b>Identifier: </b>{getStringFromFHIR.Identifier(identifier)}</div></div>
                  })}
              </div>}
            </div>
          </div>
        })}
        {(!fhirJson.entry || fhirJson.entry.length === 0) &&
          <p>No entries.</p>}
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default BundleEdit;
