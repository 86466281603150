import React, { useState, useEffect, useImperativeHandle } from 'react';
import { AssociatedResourcesDisplay, SimpleResourceFieldViewer, ReorganizeSectionsModal } from './ResourceFunctions';
import { DataEntry, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceReportMetadataPatternEdit } from './EvidenceReportMetadataDataEntry';
import { DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { generateRecommendationJustificationNarrative } from './RecommendationJustificationEdit';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { Button } from 'semantic-ui-react';
import { generateGroupSummary } from './GenerateNarrativeSummaryFunctions';

let emptyReasonValueSet = [
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" },
  { "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }
];

let emptyReasonCodeableConceptSet = [
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }]
  },
  {
    "coding":
      [{ "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }]
  }
];

const reorderSections = (sectionArray) => {
  let emptySummary = {
    "title": "Summary Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "summary",
        display: "Summary"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "introduction",
        display: "Introduction"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "discussion",
        display: "Discussion"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "methods",
        display: "Methods"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyReferences = {
    "title": "References Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "references",
        display: "References"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "competing-interests",
        display: "Competing Interests"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "acknowledgements",
        display: "Acknowledgements"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "appendices",
        display: "Appendices"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyRecommendation = {
    "title": "Recommendation Specification",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "recommendation-specification",
        display: "Recommendation Specification"
      }]
    },
    "section": [
      {
        "title": "Recommendation Statement",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "recommendation-statement",
            display: "Recommendation Statement"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Recommendation Ratings",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "ratings",
            display: "Ratings"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Population",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "population",
            display: "Population"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Action",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "action",
            display: "Action"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Opposite Action",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "opposite-action",
            display: "Opposite Action"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      }
    ]
  };
  let emptyEvidence = {
    "title": "Evidence",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "evidence",
        display: "Evidence"
      }]
    },
    "emptyReason": {
      "coding": [{
        "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
        "code": "notstarted",
        "display": "Not Started"
      }]
    }
  };
  let emptyJustification = {
    "title": "Justification",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "justification",
        display: "Justification"
      }]
    },
    "emptyReason": {
      "coding": [{
        "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
        "code": "notstarted",
        "display": "Not Started"
      }]
    }
  };
  let emptyConsiderations = {
    "title": "Considerations",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "considerations",
        display: "Considerations"
      }]
    },
    "section": [
      {
        "title": "Overall Considerations Summary",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "summary",
            display: "Summary"
          }],
          "text": "Considerations Summary"
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Subgroup Considerations Summary",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "subgroup-considerations",
            display: "Subgroup Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Implementation Considerations Summary",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "implementation-considerations",
            display: "Implementation Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Monitoring Considerations Summary",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "monitoring-considerations",
            display: "Monitoring Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      },
      {
        "title": "Research Priorities Summary",
        "code": {
          coding: [{
            system: "https://fevir.net/resources/CodeSystem/179423",
            code: "research-considerations",
            display: "Research Considerations"
          }]
        },
        "emptyReason": {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }]
        }
      }
    ]
  };
  let sectionDictionary = {
    summary: emptySummary,
    introduction: emptyIntroduction,
    discussion: emptyDiscussion,
    methods: emptyMethods,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    recommendation: emptyRecommendation,
    evidence: emptyEvidence,
    justification: emptyJustification,
    considerations: emptyConsiderations,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "summary" || sectionCode === "Summary") {
      sectionDictionary.summary = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode === "recommendation-specification" || sectionCode === "Recommendation Specification") {
      sectionDictionary.recommendation = section;
    } else if (sectionCode === "evidence" || sectionCode === "Evidence") {
      sectionDictionary.evidence = section;
    } else if (sectionCode === "justification" || sectionCode === "Justification" || sectionCode === "judgments" || sectionCode === "Judgments") {
      sectionDictionary.justification = section;
    } else if (sectionCode === "considerations" || sectionCode === "Considerations") {
      sectionDictionary.considerations = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.summary,
    sectionDictionary.introduction,
    sectionDictionary.recommendation,
    sectionDictionary.justification,
    sectionDictionary.considerations,
    sectionDictionary.methods,
    sectionDictionary.evidence,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  return reorderedSectionArray;
};

const RecommendationAuthoringTool = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  classificationsArrayState, classificationsLoadedState, globalContext, history, previousVersionLoaded,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState, update }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "status", "type", "category", "subject", "encounter",
      "date", "useContext", "author", "name", "title", "note", "attester", "custodian", "relatesTo",
      "event", "section"]
  */
  if (Array.isArray(fhirJson.author) && fhirJson.author.length === 1 && fhirJson.author[0].name) {
    fhirJson.author[0].display = fhirJson.author[0].name;
    delete fhirJson.author[0].name;
  }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "status": fhirJson.status,
    "type": fhirJson.type, "category": fhirJson.category, "subject": fhirJson.subject, "encounter": fhirJson.encounter,
    "date": fhirJson.date, "useContext": fhirJson.useContext, "author": fhirJson.author,
    "name": fhirJson.name, "title": fhirJson.title, "note": fhirJson.note, "attester": fhirJson.attester,
    "custodian": fhirJson.custodian, "relatesTo": fhirJson.relatesTo, "event": fhirJson.event,
    "section": reorderSections(fhirJson.section), "newClassifications": null
  });
  const [reorganizeSectionsModalState, setReorganizeSectionsModalState] = useState({});

  useImperativeHandle(formInputsStateRef, () => ({
    compositionDocumentState: resourceState
  }), [resourceState]);

  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadAdaptationStateFunction = () => {
    if (!adaptationReportState.adaptationDictionary && fhirJson.section?.length) {
      let startingAdaptationDictionary = {};
      let startingPathList = [];
      for (const section of fhirJson?.section) {
        if (section.code) {
          let sectionCode;
          if (section.code.text) {
            sectionCode = "section:" + section.code.text;
          } else if (section.code.coding?.[0]) {
            sectionCode = "section:" + section.code.coding[0].system + "#" + section.code.coding[0].code;
          } else {
            sectionCode = "section:" + JSON.stringify(section.code);
          }
          if (startingPathList.includes(sectionCode)) {
            alert("Duplicate sections wtih the same code: " + sectionCode + " at top section level");
          }
          startingPathList.push(sectionCode);
          startingPathList.push(sectionCode + ".title");
          startingPathList.push(sectionCode + ".author");
          startingPathList.push(sectionCode + ".orderedBy");
          startingPathList.push(sectionCode + ".entry");
          startingPathList.push(sectionCode + ".emptyReason");
          startingAdaptationDictionary[sectionCode] = {
            "initialValue": section.text?.div || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".title"] = {
            "initialValue": section.title || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".author"] = {
            "initialValue": section.author || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
            "initialValue": section.orderedBy || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".entry"] = {
            "initialValue": section.entry || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
            "initialValue": section.emptyReason || null,
            "itemChanged": false
          };
        }
        if (section.section?.length) {
          for (const subsection of section.section) {
            if (subsection.code) {
              let sectionCode;
              if (subsection.code.text) {
                sectionCode = "section:" + subsection.code.text;
              } else if (subsection.code.coding?.[0]) {
                sectionCode = "section:" + subsection.code.coding[0].system + "#" + subsection.code.coding[0].code;
              } else {
                sectionCode = "section:" + JSON.stringify(subsection.code);
              }
              if (startingPathList.includes(sectionCode)) {
                alert("Duplicate sections wtih the same code: " + sectionCode + " at section.section level");
              }
              startingPathList.push(sectionCode);
              startingPathList.push(sectionCode + ".title");
              startingPathList.push(sectionCode + ".author");
              startingPathList.push(sectionCode + ".orderedBy");
              startingPathList.push(sectionCode + ".entry");
              startingPathList.push(sectionCode + ".emptyReason");
              startingAdaptationDictionary[sectionCode] = {
                "initialValue": subsection.text?.div || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".title"] = {
                "initialValue": subsection.title || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".author"] = {
                "initialValue": subsection.author || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
                "initialValue": subsection.orderedBy || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".entry"] = {
                "initialValue": subsection.entry || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
                "initialValue": subsection.emptyReason || null,
                "itemChanged": false
              };
            }
            if (subsection.section?.length) {
              for (const subsubsection of subsection.section) {
                if (subsubsection.code) {
                  let sectionCode;
                  if (subsubsection.code.text) {
                    sectionCode = "section:" + subsubsection.code.text;
                  } else if (subsubsection.code.coding?.[0]) {
                    sectionCode = "section:" + subsubsection.code.coding[0].system + "#" + subsubsection.code.coding[0].code;
                  } else {
                    sectionCode = "section:" + JSON.stringify(subsubsection.code);
                  }
                  if (startingPathList.includes(sectionCode)) {
                    alert("Duplicate sections wtih the same code: " + sectionCode + " at section.section.section level");
                  }
                  startingPathList.push(sectionCode);
                  startingPathList.push(sectionCode + ".title");
                  startingPathList.push(sectionCode + ".author");
                  startingPathList.push(sectionCode + ".orderedBy");
                  startingPathList.push(sectionCode + ".entry");
                  startingPathList.push(sectionCode + ".emptyReason");
                  startingAdaptationDictionary[sectionCode] = {
                    "initialValue": subsubsection.text?.div || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".title"] = {
                    "initialValue": subsubsection.title || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".author"] = {
                    "initialValue": subsubsection.author || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
                    "initialValue": subsubsection.orderedBy || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".entry"] = {
                    "initialValue": subsubsection.entry || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
                    "initialValue": subsubsection.emptyReason || null,
                    "itemChanged": false
                  };
                }
              }
            }
          }
        }
      }
      setAdaptationReportState(prevState => {
        return {
          ...prevState,
          "adaptationDictionary": startingAdaptationDictionary,
          "pathList": startingPathList
        }
      })
    }
  }

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
      loadAdaptationStateFunction();
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      {resourceState.section ? <>
        {reorganizeSectionsModalState.modalOpen && <ReorganizeSectionsModal resourceState={resourceState}
            setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState}
            setReorganizeSectionsModalState={setReorganizeSectionsModalState} update={update} elementName={"section"} />}
          <Button style={{ color: "#000000", marginTop: "18px", marginLeft: "6px", fontSize: "18px", padding: "4px" }}
            className="formButton sectionReorganizeButton"
            content="Reorganize Sections"
            onClick={() => { setReorganizeSectionsModalState(prevState => { return { ...prevState, modalOpen: true } }); }} />
          <br />
           <h3 id="summary">Summary</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={0}
            fieldLabel="Summary" sectionCode="section:https://fevir.net/resources/CodeSystem/179423#summary"
            startingValue={resourceState.section[0] || null}
            fixedTitle={"Summary"} fixedCode={{
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "summary",
                  "display": "Summary"
                }
              ]
            }} startCollapsed editTextDiv={true} textDeletable={true}
            emptyTextDivValue="[No data.]"
            editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
            noEntry={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonStartCollapsed={true}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            editSection={true}
            editableSectionCodes={true} addSectionAllowed={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <EditIntroduction sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <h3 id="recommendation">Recommendation</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={2}
            startingValue={resourceState.section[2] || null}
            fieldLabel="Recommendation"
            fixedTitle={"Recommendation Specification"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "recommendation-specification",
                "display": "Recommendation Specification"
              }]
            }}
            startCollapsed editTextDiv={false} editAuthor={true}
            noEntry={true} editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true} 
            editableSectionCodes={false} addSectionAllowed={false}
            allowedSectionCodes={[
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "recommendation-statement",
                  "display": "Recommendation Statement"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "ratings",
                  "display": "Ratings"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "population",
                  "display": "Population"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "action",
                  "display": "Action"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "opposite-action",
                  "display": "Opposite Action"
                }]
              }
            ]}
            sectionDictionary={{
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"recommendation-statement","display":"Recommendation Statement"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Recommendation Statement",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"ratings","display":"Ratings"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Ratings (Strength/Level) of Recommendation",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ArtifactAssessment"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "ArtifactAssessment",
                'entrySetProfile': "RecommendationJustification",
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"population","display":"Population"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Population",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'generateTextDivFunction': generateGroupSummary,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                'entrySetProfile': "RecommendationEligibilityCriteria",
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"action","display":"Action"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Action",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ActivityDefinition", "EvidenceVariable"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"opposite-action","display":"Opposite Action"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Opposite Action",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ActivityDefinition", "EvidenceVariable"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'noSection': true
              }
            }}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState}
            globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
        </div>
        <h3 id="justification">Justification</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={3}
            startingValue={resourceState.section[3] || null}
            fieldLabel="Justification"
            fixedTitle={"Justification"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "justification",
                "display": "Justification"
              }]
            }}
            startCollapsed editTextDiv={true} textDeletable={true} editAuthor={true}
            generateNarrativeFromSingleEntryFunction={generateRecommendationJustificationNarrative}
            resourceDictionary={sourceJsonState.resourceDictionary}
            emptyTextDivValue="[No data.]"
            entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryReferencedResourceTypes={["ArtifactAssessment"]} entryStartCollapsed
            entryStartEmptyArrayClosed entryStartingResourceType="ArtifactAssessment"
            entrySetProfile="RecommendationJustification"
            editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true} 
            editableSectionCodes={true} addSectionAllowed={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <h3 id="considerations">Considerations</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={4}
            startingValue={resourceState.section[4] || null}
            fieldLabel="Considerations"
            fixedTitle={"Considerations"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "considerations",
                "display": "Considerations"
              }]
            }}
            startCollapsed editTextDiv={false} editAuthor={true}
            noEntry={true} editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true} 
            editableSectionCodes={true} addSectionAllowed={true}
            allowedSectionCodes={[
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "summary",
                  "display": "Summary"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "subgroup-considerations",
                  "display": "Subgroup Considerations"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "implementation-considerations",
                  "display": "Implementation Considerations"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "monitoring-considerations",
                  "display": "Monitoring Considerations"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "competing-interests",
                  "display": "Competing Interests"
                }]
              },
              {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "research-considerations",
                  "display": "Research Considerations"
                }]
              }
            ]}
            sectionDictionary={{
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"summary","display":"Summary"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Overall Considerations Summary",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"subgroup-considerations","display":"Subgroup Considerations"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Subgroup Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"implementation-considerations","display":"Implementation Considerations"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Implementation Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"monitoring-considerations","display":"Monitoring Considerations"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Monitoring Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"competing-interests","display":"Competing Interests"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Competing Interests",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                'entryReferencedResourceTypes': ["ArtifactAssessment", "Composition"], 'entryStartCollapsed': true,
                'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "ArtifactAssessment",
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              },
              '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"research-considerations","display":"Research Considerations"}]}': {
                'startCollapsed': false, 
                'fixedTitle': "Research Considerations",
                'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                'emptyTextDivValue': "[No data.]",
                'noEntry': true,
                'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
              }
            }}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <h3 id="evidence">Evidence</h3>
        <div style={{ marginLeft: "24px" }}>
          <DocumentSectionEntry sectionArrayIndex={6}
            startingValue={resourceState.section[6] || null}
            fieldLabel="Evidence"
            fixedTitle={"Evidence"} fixedCode={{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "evidence",
                "display": "Evidence"
              }]
            }}
            editTextDiv={true} textDeletable={true} editAuthor={true}
            emptyTextDivValue="[No data.]"
            entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryReferencedResourceTypes={["ArtifactAssessment", "Composition", "Evidence"]}
            entryStartCollapsed={true} entryStartEmptyArrayClosed={true}
            editEmptyReason={true}
            emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            emptyReasonStartCollapsed={true}
            editSection={true}
            editableSectionCodes={true} addSectionAllowed={true}
            setResourceState={setResourceState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
        <EditDiscussion sectionIndex={7} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditReferences sectionIndex={8} previousVersionLoaded={previousVersionLoaded}
          resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditCompetingInterests sectionIndex={9} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditAcknowledgements sectionIndex={10} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        <EditAppendices sectionIndex={11} resourceState={resourceState} setResourceState={setResourceState}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      </>
        :
        <>Section element is missing in the JSON.</>
      }
      <div>
        <h3 id="how-to-cite">How to Cite</h3>
        <div style={{ marginLeft: "24px" }}>
          <DisplayHowToCite citationSummary={citationSummary}
            citationJson={citationJson} />
        </div>
        <h3 id="metadata">Metadata</h3>
        <div style={{ marginLeft: "24px" }}>
          <EvidenceReportMetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
        </div>
        <h3 id="associated-resources">Associated Resources</h3>
        <div style={{ marginLeft: "24px" }}>
          <AssociatedResourcesDisplay fhirJson={fhirJson} />
        </div>
        <h3 id="classifiers">Classifiers</h3>
        <div style={{ marginLeft: "24px" }}>
          <div>
            <p>Add Classifiers:</p>
            <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
              fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
          </div>
          {(classificationsArrayState) && <div>
            <p>Existing Classifiers:</p>
            {classificationsLoadedState ?
              <DisplayClassifiers classificationsArray={classificationsArrayState} />
              :
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
            }
          </div>}
        </div>
        <h3 id="json-outline">JSON Outline</h3>
        <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
        <br /><br />
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

export { RecommendationAuthoringTool };