import React, { useContext } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceTableDisplay } from './CompositionDocumentBundleFunctions';
import { useHistory } from "react-router-dom";
import FevirContext from './FevirContext';
import { createSummaryOfFindingsReport } from './SummaryOfFindingsFunctions';

const CompositionMeasuresTableDisplay = ({ relatesTo, reportType }) => {
  if (Array.isArray(relatesTo)) {
    let rowArray = [];
    for (let item of relatesTo) {
      if (item.type === "depends-on" && item.label === reportType) {
        rowArray.push({ "name": item.display, "reference": item.resourceReference });
      }
    }
    return <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          <Table.HeaderCell>EvidenceVariable Resource</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rowArray.map((row, rowIndex) => {
          return <Table.Row key={rowIndex}>
            <Table.Cell>{row.name}</Table.Cell>
            <Table.Cell><DisplayFromFHIR reference={row.reference} /></Table.Cell>
          </Table.Row>
        })}
      </Table.Body>
    </Table>
  } else {
    return <p>[Expected JSON not found.]</p>
  }
}

const CompositionMeasureReportViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState, reportType }) => {

  let howToCite;
  let summaryOfFindingsStarter = {
    "derivedFrom": {
      "type": "derived-from",
      "label": "Derived from",
      "resourceReference": {
        "reference": "Composition/" + fhirJson.id,
        "type": "Composition",
        "display": fhirJson.title || fhirJson.name
      }
    },
    "outcomesRelatesTo": []
  };
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
      }
      if (relatedArtifactEntry.type === "depends-on") {
        if (relatedArtifactEntry.label === "Intervention Group") {
          summaryOfFindingsStarter.interventionGroup = relatedArtifactEntry;
        }
        if (relatedArtifactEntry.label === "Comparator Group") {
          summaryOfFindingsStarter.comparatorGroup = relatedArtifactEntry;
        }
        if (relatedArtifactEntry.label === "Outcome Measure") {
          summaryOfFindingsStarter.outcomesRelatesTo.push(relatedArtifactEntry);
        }
      }
    }
  }
  if (Array.isArray(fhirJson.subject)) {
    summaryOfFindingsStarter.subject = fhirJson.subject;
  }
  if (Array.isArray(fhirJson.section) && fhirJson.section.length > 0) {
    summaryOfFindingsStarter.outcomesSection = fhirJson.section;
  }

  const history = useHistory();
  const globalContext = useContext(FevirContext);

  return <div>
    <div style={{ marginTop: "12px" }}>
      {reportType === "Outcome Measure" && <>
        <Button className="formButton" style={{ color: "#000000" }}
          content={`Create a Summary of Findings Report`}
          onClick={async () => {
            let resourceFOI = await createSummaryOfFindingsReport(fhirJson.title, summaryOfFindingsStarter, globalContext);
            if (resourceFOI) {
              history.push("/");
              history.push(`/resources/Composition/${resourceFOI}`);
            }
          }} />
        <br />
      </>}
      <h3 id="groups">Groups</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.relatesTo) && fhirJson.relatesTo.map((item, itemIndex) => {
          if (item.type === "depends-on") {
            if (item.label === "Intervention Group") {
              return <div key={itemIndex} >
                <b>Intervention Group: </b>
                <DisplayFromFHIR reference={item.resourceReference} />
              </div>
            } else if (item.label === "Comparator Group") {
              return <div key={itemIndex} >
                <b>Comparator Group: </b>
                <DisplayFromFHIR reference={item.resourceReference} />
              </div>
            } else if (item.label === "Total Group") {
              return <div key={itemIndex} >
                <b>Total Group: </b>
                <DisplayFromFHIR reference={item.resourceReference} />
              </div>
            } else {
              return <div key={itemIndex} ></div>
            }
          } else {
            return <div key={itemIndex} ></div>
          }
        })}
      </div>
      <br />
      {reportType === "Baseline Measure" && <h3 id="baseline-measures">Baseline Measures</h3>}
      {reportType === "Participant Flow" && <h3 id="participant-flow-measures">Participant Flow Measures</h3>}
      {reportType === "Outcome Measure" && <h3 id="outcome-measures">Outcome Measures</h3>}
      {!(reportType === "Outcome Measure" || reportType === "Participant Flow" || reportType === "Baseline Measure") &&
        <h3 id="unclassified-measures">Unclassified Measures</h3>}
      <div style={{ marginLeft: "24px" }}>
        <CompositionMeasuresTableDisplay relatesTo={fhirJson.relatesTo} reportType={reportType} />
      </div>
      <h3 id="evidence">Evidence</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceTableDisplay relatesTo={fhirJson.relatesTo} section={fhirJson.section} reportType={reportType} />
      </div>
      <br />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.category) && fhirJson.category.length > 0 && <>
          {fhirJson.category.map((category, categoryIndex) => {
            return <span key={categoryIndex} >
              <b>Category: </b>
              <DisplayFromFHIR codeableConcept={category} />
            </span>
          })}
          <br /> <br />
        </>}
        {Array.isArray(fhirJson.subject) && fhirJson.subject.length > 0 && <>
          {fhirJson.subject.map((subject, subjectIndex) => {
            return <span key={subjectIndex} >
              <b>Subject: </b>
              <DisplayFromFHIR reference={subject} />
            </span>
          })}
          <br /> <br />
        </>}
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default CompositionMeasureReportViewerTool;