import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const MeasureDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {
  //It renders 4 times without the fhirJson even changing

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact)) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") ?
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
          : <p>[Narrative summary not generated.]</p>}
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.subtitle && <p><b>Subtitle: </b>{fhirJson.subtitle}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
      </div>
      <h3 id="measure-definition">Measure Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.subjectCodeableConcept && <span><b>Subject: </b><DisplayFromFHIR codeableConcept={fhirJson.subjectCodeableConcept} /></span>}
        {fhirJson.subjectReference && <p><b>Subject: </b><DisplayFromFHIR reference={fhirJson.subjectReference} /></p>}
        {fhirJson.basis && <p><b>Basis: </b>{fhirJson.basis}</p>}
        {Array.isArray(fhirJson.library) && fhirJson.library.map((library, libraryIndex) => {
          return <div key={libraryIndex}><b>Library: </b><DisplayFromFHIR uri={library} /><br /></div>
        })}
        {fhirJson.disclaimer && <div><b>Disclaimer: </b><DisplayFromFHIR markdown={fhirJson.disclaimer} /><br /><br /></div>}
        {fhirJson.scoring && <span><b>Scoring: </b><DisplayFromFHIR codeableConcept={fhirJson.scoring} /><br /><br /></span>}
        {fhirJson.scoringUnit && <span><b>Scoring Unit: </b><DisplayFromFHIR codeableConcept={fhirJson.scoringUnit} /><br /><br /></span>}
        {fhirJson.compositeScoring && <span><b>Composite Scoring: </b><DisplayFromFHIR codeableConcept={fhirJson.compositeScoring} /><br /><br /></span>}
        {Array.isArray(fhirJson.type) &&
          fhirJson.type.map((type, typeIndex) => {
            return <div key={typeIndex}><div><b>Measure Type: </b><DisplayFromFHIR codeableConcept={type} /></div></div>
          })}
        {fhirJson.riskAdjustment && <div><b>Risk Adjustment: </b><DisplayFromFHIR markdown={fhirJson.riskAdjustment} /><br /><br /></div>}
        {fhirJson.rateAggregation && <div><b>Rate Aggregation: </b><DisplayFromFHIR markdown={fhirJson.rateAggregation} /><br /><br /></div>}
        {fhirJson.rationale && <div><b>Rationale: </b><DisplayFromFHIR markdown={fhirJson.rationale} /><br /><br /></div>}
        {fhirJson.clinicalRecommendationStatement && <div><b>Clinical Recommendation Statement: </b><DisplayFromFHIR markdown={fhirJson.clinicalRecommendationStatement} /><br /><br /></div>}
        {fhirJson.improvementNotation && <span><b>Improvement Notation: </b><DisplayFromFHIR codeableConcept={fhirJson.improvementNotation} /><br /><br /></span>}
        {fhirJson.term?.length > 0 && <div>
          <p>{fhirJson.term.length === 1 ? <b>Term: </b> : <b>Terms: </b>}</p>
          <div style={{ marginLeft: "24px" }}>
            {fhirJson.term.map((term, termIndex) => {
              return <div key={termIndex}>
                <p><b>Term entry {termIndex + 1}</b></p>
                <div style={{ marginLeft: "24px" }}>
                  {term.code && <span><b>Term code: </b><DisplayFromFHIR codeableConcept={term.code} /></span>}
                  {term.definition && <span><b>Term definition: </b><DisplayFromFHIR markdown={term.definition} /></span>}
                </div>
              </div>
            })}
          </div>
        </div>
        }
        {fhirJson.guidance && <div><b>Guidance: </b><DisplayFromFHIR markdown={fhirJson.guidance} /><br /><br /></div>}
        {(Array.isArray(fhirJson.group) && fhirJson.group.length > 0) && <p>
          Placeholder -- Measure.group data will be displayed here
        </p>}
        {(Array.isArray(fhirJson.supplementalData) && fhirJson.supplementalData.length > 0) && <p>
          Placeholder -- Measure.supplementalData data will be displayed here
        </p>}
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default MeasureDisplay;