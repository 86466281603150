import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, TextArea, Tab } from 'semantic-ui-react';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import GenericToggleButtons from './GenericToggleButtons';
import { submitResource } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import FevirContext from './FevirContext';
import ProfilesByResourceType from './ProfilesByResourceType';

const profileClick = (resourceType, profileName, setNewResourceState) => {
  let meta;
  let section;
  let content;
  let profileUrl = "";
  let citedArtifactClassificationToAdd;
  let typeValue;
  let categoryToAdd;
  let useContextToAdd;
  let membershipValue;
  let combinationMethodValue;
  let characteristic;
  let codeValue;
  if (profileName) {
    const profileDictionary = ProfilesByResourceType[resourceType];
    if (profileDictionary) {
      for (const key in profileDictionary) {
        if (key === profileName) {
          profileUrl = profileDictionary[key].url;
          if (profileDictionary[key].citedArtifactClassificationToAdd) {
            citedArtifactClassificationToAdd = profileDictionary[key].citedArtifactClassificationToAdd;
          }
          if (profileDictionary[key].typeValue) {
            typeValue = profileDictionary[key].typeValue;
          }
          if (profileDictionary[key].categoryToAdd) {
            categoryToAdd = profileDictionary[key].categoryToAdd;
          }
          if (profileDictionary[key].useContextToAdd) {
            useContextToAdd = profileDictionary[key].useContextToAdd;
          }
          if (profileDictionary[key].membershipValue) {
            membershipValue = profileDictionary[key].membershipValue;
          }
          if (profileDictionary[key].combinationMethodValue) {
            combinationMethodValue = profileDictionary[key].combinationMethodValue;
          }
          if (profileDictionary[key].codeValue) {
            codeValue = profileDictionary[key].codeValue;
          }
          if (profileDictionary[key].sectionStarter) {
            section = profileDictionary[key].sectionStarter;
          }
          if (profileDictionary[key].contentStarter) {
            content = profileDictionary[key].contentStarter;
          }
          if (profileDictionary[key].characteristicStarter) {
            characteristic = profileDictionary[key].characteristicStarter;
          }
        }
      }
    }
    if (profileUrl) {
      meta = { "profile": [profileUrl] };
      setNewResourceState(prevState => {
        return {
          ...prevState,
          'profileSelected': profileName,
          'meta': meta,
          'citedArtifactClassificationToAdd': citedArtifactClassificationToAdd,
          'section': section,
          'content': content,
          'characteristic': characteristic,
          'typeValue': typeValue,
          'categoryToAdd': categoryToAdd,
          'useContextToAdd': useContextToAdd,
          'membershipValue': membershipValue,
          'combinationMethodValue': combinationMethodValue,
          'codeValue': codeValue
        }
      });
    }
  } else if (profileName === "") {
    setNewResourceState(prevState => {
      return {
        ...prevState,
        'profileSelected': "",
        'meta': undefined,
        'citedArtifactClassificationToAdd': undefined,
        'section': undefined,
        'content': undefined,
        'characteristic': undefined,
        'typeValue': undefined,
        'categoryToAdd': undefined,
        'useContextToAdd': undefined,
        'membershipValue': undefined,
        'combinationMethodValue': undefined,
        'codeValue': undefined
      }
    });
  }
}

const ResourceProfileRadioGroup = ({ newResourceState, setNewResourceState }) => {
  return <>{ProfilesByResourceType[newResourceState?.resourceType] && <div style={{ marginLeft: "24px" }}>
    <p style={{ marginBottom: "8px" }}><br />Select a Profile</p>
    <RadioGroup aria-label='profile-selection'
      name='profile-selection' value={newResourceState.profileSelected || ""} >
      {Object.keys(ProfilesByResourceType[newResourceState.resourceType]).map((profile, profileIndex) => {
        const description = ProfilesByResourceType[newResourceState.resourceType][profile].description;
        const htmlDescription = ProfilesByResourceType[newResourceState.resourceType][profile].htmlDescription;
        if (description) {
          return <p key={profileIndex} style={{ marginBottom: "0px" }} >
            <FormControlLabel
              key={profileIndex}
              value={profile}
              control={<Radio color="primary"
                onClick={(e) => {
                  if (newResourceState.profileSelected === e.target.value) {
                    profileClick(newResourceState.resourceType, "", setNewResourceState);
                  } else {
                    profileClick(newResourceState.resourceType, e.target.value, setNewResourceState);
                  }
                }} />}
              name="profile-selection-radio-button-control"
              color="default"
              inputprops={{ 'aria-label': profile }}
            /><span className="unselectable" style={{ cursor: "pointer" }} onClick={() => { profileClick(newResourceState.resourceType, profile, setNewResourceState); }}>{htmlDescription ? htmlDescription : description}</span></p>
        } else {
          return <p key={profileIndex} style={{ marginBottom: "8px" }} ><br /></p>
        }
      })}
    </RadioGroup></div>}</>
}

const NewResourceEntry = ({ newResourceState, setNewResourceState, resourceTypeList, titleOnly }) => {
  if (!resourceTypeList) {
    resourceTypeList = ['ActivityDefinition', 'ArtifactAssessment', 'Citation',
      'CodeSystem', 'Composition', 'Evidence', 'EvidenceVariable', 'Group', 'Library', 'List', 'Measure',
      'PlanDefinition', 'Project', 'ResearchStudy', 'ValueSet']
  }

  const [profileListState, setProfileListState] = useState([]);

  useEffect(() => {
    if (newResourceState.resourceType) {
      const resourceType = newResourceState.resourceType;
      const profileDictionary = ProfilesByResourceType[resourceType];
      if (profileDictionary) {
        const profileNames = Object.keys(profileDictionary);
        if (profileNames) {
          setProfileListState(profileNames);
        } else {
          setProfileListState([]);
        }
      }
    }
  }, [newResourceState.resourceType]);

  if (titleOnly) {
    let itemTypeDisplay = newResourceState.resourceType + " Resource";
    if (newResourceState.meta?.profile) {
      for (const profile of newResourceState.meta.profile) {
        const profileDictionary = ProfilesByResourceType[newResourceState.resourceType];
        for (const key in profileDictionary) {
          if (profileDictionary[key].url === profile) {
            itemTypeDisplay = key;
            break;
          }
        }
      }
    }
    return <div>
      <div style={{ marginLeft: "24px" }}>
        <p>Enter a title here and click Submit to create a new blank {itemTypeDisplay}.</p>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={newResourceState.title} setResourceState={setNewResourceState} />
      </div>
    </div>
  }

  return <div>
    <div style={{ marginLeft: "24px" }}>
      <p><b>Enter a title here, select a Resource type, optionally select a Profile, and click Submit to create a new blank Resource.</b></p>
      <p>Alternatively, you can paste and/or edit complete JSON in the JSON View tab. Use of data entry fields in the Text View will replace the JSON.</p>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={newResourceState.title} setResourceState={setNewResourceState} />
      <br /><br />
      <DataEntry datatype='code' elementName='resourceType' fieldLabel='Resource Type'
        allowedValues={resourceTypeList}
        startingValue={newResourceState.resourceType} setResourceState={setNewResourceState} />
      {newResourceState.resourceType && <div>
        {newResourceState.resourceType === 'ActivityDefinition' && <p>
          The ActivityDefinition Resource allows for the definition of some activity to be performed, independent of a particular patient, practitioner, or other performance context.
        </p>}
        {newResourceState.resourceType === 'ArtifactAssessment' && <div>
          <p>The ArtifactAssessment Resource provides one or more comments, classifiers or ratings about a Resource and supports attribution and rights management metadata for the added content.</p>
        </div>}
        {newResourceState.resourceType === 'Citation' && <div>
          <p>The Citation Resource enables reference to any knowledge artifact for purposes of identification and attribution.</p>
        </div>}
        {newResourceState.resourceType === 'CodeSystem' && <p>
          The CodeSystem Resource is used to declare the existence of and describe a code system (structured terminology) or code system supplement and its key properties, and optionally define a part or all of its content.
        </p>}
        {newResourceState.resourceType === 'Composition' && <div>
          <p>
            The Composition Resource provides a package of content and defines the structure and narrative content necessary for a document.
          </p>
        </div>}
        {newResourceState.resourceType === 'Evidence' && <div>
          <p>
            The Evidence Resource provides a machine-interpretable expression of an evidence concept including the evidence variables (e.g., population, exposures/interventions, comparators, outcomes, measured variables, confounding variables), the statistics, and the certainty of this evidence.
          </p>
        </div>}
        {newResourceState.resourceType === 'EvidenceVariable' && <div>
          <p>The EvidenceVariable Resource describes an element that knowledge (Evidence) is about.</p>
        </div>}
        {newResourceState.resourceType === 'Group' && <div>
          <p>
            The Group Resource represents a defined collection of entities that may be discussed or acted upon collectively but which are not expected to act collectively, and are not formally or legally recognized; i.e. a collection of entities that isn't an Organization.
          </p>
        </div>}
        {newResourceState.resourceType === 'Library' && <div>
          <p>
            The Library Resource is a container for a collection of knowledge assets.
          </p>
        </div>}
        {newResourceState.resourceType === 'List' && <div>
          <p>
            The List Resource represents a curated collection of resources.
          </p>
        </div>}
        {newResourceState.resourceType === 'Measure' && <p>
          The Measure Resource allows for the definition of a quality measure or performance measure.
        </p>}
        {newResourceState.resourceType === 'PlanDefinition' && <div>
          <p>
            The PlanDefinition Resource allows for the definition of various types of plans as a sharable, consumable, and executable artifact. The resource is general enough to support the description of a broad range of clinical and non-clinical artifacts such as clinical decision support rules, order sets, protocols, and drug quality specifications.
          </p>
        </div>}
        {newResourceState.resourceType === 'Project' && <p>
          The Project Resource is not a FHIR Resource. The Project Resource is used to store the data for Projects on the FEvIR Platform.
        </p>}
        {newResourceState.resourceType === 'ResearchStudy' && <div>
          <p>
            The ResearchStudy Resource allows for the definition of a scientific study.
          </p>
        </div>}
        {newResourceState.resourceType === 'ValueSet' && <p>
          The ValueSet Resource instance specifies a set of codes (terms) drawn from one or more code systems (structured terminologies), intended for use in a particular context.
        </p>}
        <ResourceProfileRadioGroup newResourceState={newResourceState} setNewResourceState={setNewResourceState} />
      </div>}
    </div>
  </div>
}

const CreateResourcePage = ({ useTitle, resourceType, resourceId, getResource, resourceStarter, jsonView }) => {

  let titleOnly = true;
  let pageTitle = "FEvIR Resource Entry";
  if (!resourceStarter) {
    titleOnly = false;
    pageTitle = "FEvIR Resource JSON Entry";
    resourceStarter = { "resourceType": "", "title": "" };
  }

  useTitle(pageTitle);
  const globalContext = useContext(FevirContext);
  const [fhirEntryState, setFhirEntryState] = useState({ "fhirEntry": "", "status": "active", "activeIndex": (resourceId || jsonView) ? 1 : 0 });

  const [newResourceState, setNewResourceState] = useState(resourceStarter);

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const createClonedResource = async () => {
    if (!globalContext.userState.loading) {
      if (resourceId) {
        let resourceEntry = await getResource(resourceId, resourceType, globalContext.userState.idToken);
        let fhirJsonString = resourceEntry["fhirResource"];
        let fhirJson = JSON.parse(fhirJsonString);
        if (globalContext.userState.id) {
          let originalTitle = "";
          if (fhirJson.title && typeof fhirJson.title === "string" && fhirJson.title.trim()) {
            originalTitle = fhirJson.title;
            fhirJson.title = fhirJson.title + " - " + globalContext.userState.name;
          } else if (fhirJson.name) {
            if (typeof fhirJson.name === "string" && fhirJson.name.trim()) {
              fhirJson.name = fhirJson.name + " - " + globalContext.userState.name;
              originalTitle = fhirJson.name;
            } else if (Array.isArray(fhirJson.name) && fhirJson.name.length > 0 && fhirJson.name[0].text && fhirJson.name[0].text.trim() !== "") {
              fhirJson.name.append = { "text": fhirJson.name[0].text + " - " + globalContext.userState.name };
              originalTitle = fhirJson.name[0].text;
            }
          }
          if (fhirJson.author) {
            delete fhirJson.author;
          }
          let identifiersToKeep = [];
          if (fhirJson.identifier) {
            for (let identifierIndex in fhirJson.identifier) {
              if (fhirJson.identifier[identifierIndex].system !== "https://fevir.net") {
                identifiersToKeep.push(fhirJson.identifier[identifierIndex]);
              }
            }
            delete fhirJson.identifier;
            if (identifiersToKeep.length > 0) {
              fhirJson.identifier = identifiersToKeep;
            }
          }
          let relatedArtifactEntry = {
            "type": "derived-from",
            "label": "Cloned from",
            "resourceReference": {
              "reference": resourceType + "/" + resourceId,
              "type": resourceType,
              "display": originalTitle
            }
          }
          if (resourceType === "Composition") {
            if (!fhirJson.relatesTo) {
              fhirJson.relatesTo = [];
            }
            fhirJson.relatesTo.push(relatedArtifactEntry);
          } else if (resourceType === "ArtifactAssessment" || resourceType === "Group" || resourceType === "List") {
            if (!fhirJson.extension) {
              fhirJson.extension = [];
            }
            fhirJson.extension.push({
              url: "http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact",
              valueRelatedArtifact: relatedArtifactEntry
            });
          } else {
            if (!fhirJson.relatedArtifact) {
              fhirJson.relatedArtifact = [];
            }
            fhirJson.relatedArtifact.push(relatedArtifactEntry);
          }
          fhirJsonString = JSON.stringify(fhirJson, null, 2);
          changeFhirEntryState(fhirJsonString, "fhirEntry");
          window.history.replaceState(null, "", "/create")
        }
      }
    }
  }

  useEffect(() => {
    createClonedResource();
  }, []);

  useEffect(() => {
    if (JSON.stringify(newResourceState) !== JSON.stringify({ "resourceType": "", "title": "" })) {
      let newJson = {
        "resourceType": newResourceState.resourceType,
        "meta": newResourceState.meta,
        "title": newResourceState.title
      };
      if (newResourceState.resourceType === "CodeSystem") {
        newJson.concept = [];
      }
      if (newResourceState.resourceType === "Citation" && newResourceState.citedArtifactClassificationToAdd) {
        newJson.citedArtifact = { classification: [newResourceState.citedArtifactClassificationToAdd] };
      }
      if (newResourceState.typeValue) {
        newJson.type = newResourceState.typeValue;
      }
      if (newResourceState.categoryToAdd) {
        newJson.category = [newResourceState.categoryToAdd];
      }
      if (newResourceState.useContextToAdd) {
        newJson.useContext = [newResourceState.useContextToAdd];
      }
      if (newResourceState.membershipValue) {
        newJson.membership = newResourceState.membershipValue;
      }
      if (newResourceState.combinationMethodValue) {
        newJson.combinationMethod = newResourceState.combinationMethodValue;
      }
      if (newResourceState.codeValue) {
        newJson.code = newResourceState.codeValue;
      }
      if (newResourceState.section) {
        newJson.section = newResourceState.section;
      } else if (newResourceState.resourceType === "Composition") {
        newJson.section = [];
      }
      if (newResourceState.content) {
        newJson.content = newResourceState.content;
      }
      if (newResourceState.characteristic) {
        newJson.characteristic = newResourceState.characteristic;
      }
      setFhirEntryState(prevState => {
        return {
          ...prevState,
          fhirEntry: JSON.stringify(newJson)
        };
      });
    }
  }, [newResourceState])

  const history = useHistory();


  let tabPanesLookup = { 'Text View': null, 'JSON View': null };

  let tabPanes = Object.keys(tabPanesLookup).map((tab, index) => {
    tabPanesLookup[tab] = index;
    let tabObj = {
      menuItem: tab
    };
    return tabObj;
  });


  const changeResourceViewTab = async (tabIndex, tabLabel, automated) => {
    let allowTabChange = true;

    if (allowTabChange) {

      if (tabLabel === "Text View") {

      } else if (tabLabel === "JSON View") {
        //await builderUpdateJson(resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext);
      }

      changeFhirEntryState(tabIndex, "activeIndex");
    }
  };

  const ViewTabPane = () => (<Tab menu={{ secondary: true, pointing: true }} panes={tabPanes} activeIndex={fhirEntryState.activeIndex}
    onTabChange={(e, data) => {
      changeResourceViewTab(data.activeIndex, tabPanes[data.activeIndex]["menuItem"], false);
    }}
  />);

  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <ViewTabPane />
      <br /><br />
      {globalContext.userState.firebaseuid ?
        <>
          {(tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Text View") ?
            <>
              <NewResourceEntry newResourceState={newResourceState} setNewResourceState={setNewResourceState}
                titleOnly={titleOnly} />
              <br /> <br />
              {titleOnly === false && <>
                {/* Move this into a module because it's used for both JsonEntryPage and ResourceDisplayPage */}
                <GenericToggleButtons thin={true} boxes={true} togglable={false}
                  values={["active", "draft"]} labels={["Publish (anyone can view)", "Draft (only editors can view)"]} fieldLabel={"status"} elementName={"status"}
                  startingValue={fhirEntryState.status} setter={setFhirEntryState}
                />
                <br />
                <br />
              </>}
              <Button className="formButton positive" content="Submit" compact
                onClick={() => { submitResource(globalContext, history, fhirEntryState, true); }} />
            </>
            :
            <>
              <TextArea placeholder='Paste your JSON here' style={{ minHeight: 100, width: "60%" }}
                value={fhirEntryState.fhirEntry}
                onChange={(e) => { changeFhirEntryState(e.target.value, "fhirEntry"); }} />
              <br /><br />
              {/* Move this into a module because it's used for both JsonEntryPage and ResourceDisplayPage */}
              <GenericToggleButtons thin={true} boxes={true} togglable={false}
                values={["active", "draft"]} labels={["Publish (anyone can view)", "Draft (only editors can view)"]} fieldLabel={"status"} elementName={"status"}
                startingValue={fhirEntryState.status} setter={setFhirEntryState}
              />
              <br />
              <br />
              <Button className="formButton positive" content="Submit" compact
                onClick={() => { submitResource(globalContext, history, fhirEntryState, true); }} />
              {titleOnly === false && <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button className="formButton" style={{ color: "#000000" }}
                  content="Use JSON Tree Writer for Evidence Resource" compact
                  onClick={() => { window.open('https://khalid-shahin.github.io/JSON-Tree-Writer/EBMonFHIR-Evidence.html'); }} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button className="formButton" style={{ color: "#000000" }}
                  content="Use JSON Tree Writer for EvidenceVariable Resource" compact
                  onClick={() => { window.open('https://khalid-shahin.github.io/JSON-Tree-Writer/EBMonFHIR-EvidenceVariable.html'); }} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button className="formButton" style={{ color: "#000000" }}
                  content="JSON Bulk Entry" compact
                  onClick={() => { history.push(`/jsonbulkentry`); }} />
              </>}
            </>
          }
        </>
        :
        <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
    </Segment>
  </div>
};

export default CreateResourcePage;