import React, { useState, useEffect, useImperativeHandle } from 'react';
import { SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const planDefinitionDotAsNeededCodeableConceptSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const planDefinitionDotSubjectReferenceResourceTypes = ['Group', 'MedicinalProductDefinition', 'SubstanceDefinition', 'AdministrableProductDefinition', 'ManufacturedItemDefinition', 'PackagedProductDefinition'];
const planDefinitionDotSubjectCodeableConceptValueSet = [
  { system: "http://hl7.org/fhir/fhir-types", code: "CareTeam", display: "CareTeam" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Device", display: "Device" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Group", display: "Group" },
  { system: "http://hl7.org/fhir/fhir-types", code: "HealthcareService", display: "HealthcareService" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Location", display: "Location" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Organization", display: "Organization" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Patient", display: "Patient" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Practitioner", display: "Practitioner" },
  { system: "http://hl7.org/fhir/fhir-types", code: "PractitionerRole", display: "PractitionerRole" },
  { system: "http://hl7.org/fhir/fhir-types", code: "RelatedPerson", display: "RelatedPerson" }
];
const planDefinitionDotSubjectAllowedDatatypes = ['CodeableConcept', 'Reference', 'canonical'];
const planDefinitionDotAsNeededAllowedDatatypes = ['boolean', 'CodeableConcept'];
const planDefinitionDotTypeValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "order-set", display: "Order Set" },
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "clinical-protocol", display: "Clinical Protocol" },
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "eca-rule", display: "ECA Rule" },
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "workflow-definition", display: "Workflow Definition" }
];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.subtitle && typeof resource.type === "string") {
      innerDiv += "<p><b>Subtitle: </b>" + resource.subtitle + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.subjectCodeableConcept) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.CodeableConcept(resource.subjectCodeableConcept) + "</p><br/>";
    }
    if (resource.subjectReference) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.Reference(resource.subjectReference) + "</p><br/>";
    }
    if (resource.subjectCanonical) {
      innerDiv += "<p><b>Subject: </b>" + resource.subjectCanonical + "</p><br/>";
    }
    if (resource.action?.length > 0) {
      innerDiv += resource.action.map((item) => {
        let title = item.title || "";
        if (item.prefix) {
          title = item.prefix + " " + title;
        }
        let description = item.description || "";
        let textEquivalent = item.textEquivalent || "";
        let code = getStringFromFHIR.CodeableConcept(item.code) || "";
        let type = getStringFromFHIR.CodeableConcept(item.type) || "";
        let definition = item.definitionCanonical || item.definitionUri || "";
        let timing = getStringFromFHIR.Timing(item.timingTiming) ||
          getStringFromFHIR.Quantity(item.timingAge) ||
          getStringFromFHIR.Range(item.timingRange) ||
          getStringFromFHIR.Quantity(item.timingDuration) || "";
        return "<p><b>Action (" + title + "): </b>" + textEquivalent + " " + description + " " + code + " " + type + " " + definition + " " + timing + "</p><br/>"
      }).join("");
    }
    if (resource.asNeededCodeableConcept) {
      innerDiv += "<p><b>As needed for: </b>" + getStringFromFHIR.CodeableConcept(resource.asNeededCodeableConcept) + "</p><br/>";
    }
    if (resource.asNeededBoolean !== undefined) {
      innerDiv += "<p><b>As needed: </b>" + getStringFromFHIR.boolean(resource.asNeededBoolean) + "</p><br/>";
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const PlanDefinitionEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", 
      "name", "title", "subtitle", "type", "status", "experimental", 
      "subjectCodeableConcept", "subjectReference", "subjectCanonical", 
      "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
      "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
      "library", "goal", "actor", "action", "asNeededBoolean", "asNeededCodeableConcept"]
  */
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "subtitle": fhirJson.subtitle, "type": fhirJson.type,
    "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "subjectCodeableConcept": fhirJson.subjectCodeableConcept, "subjectReference": fhirJson.subjectReference, "subjectCanonical": fhirJson.subjectCanonical, "library": fhirJson.library,
    "goal": fhirJson.goal, "actor": fhirJson.actor, "action": fhirJson.action, "asNeededBoolean": fhirJson.asNeededBoolean,
    "asNeededCodeableConcept": fhirJson.asNeededCodeableConcept, "newClassifications": null
  });

  let startingSubjectDatatype = 'none';
  if (fhirJson.subjectCodeableConcept) { startingSubjectDatatype = 'CodeableConcept'; }
  if (fhirJson.subjectReference) { startingSubjectDatatype = 'Reference'; }
  if (fhirJson.subjectCanonical) { startingSubjectDatatype = 'canonical'; }
  let startingAsNeededDatatype = 'none';
  if (fhirJson.asNeededBoolean !== undefined) { startingAsNeededDatatype = 'boolean'; }
  if (fhirJson.asNeededCodeableConcept) { startingAsNeededDatatype = 'CodeableConcept'; }
  const [subjectDatatypeState, setSubjectDatatypeState] = useState(startingSubjectDatatype);
  const [asNeededDatatypeState, setAsNeededDatatypeState] = useState(startingAsNeededDatatype);

  useImperativeHandle(formInputsStateRef, () => ({
    planDefinitionState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (subjectDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null, 'subjectCanonical': null } })
    }
    if (subjectDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'subjectCodeableConcept': null, 'subjectCanonical': null } })
    }
    if (subjectDatatypeState === 'canonical') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null, 'subjectCodeableConcept': null } })
    }
  }), [subjectDatatypeState]);

  useEffect((() => {
    if (asNeededDatatypeState === 'boolean') {
      setResourceState(prevState => { return { ...prevState, 'asNeededCodeableConcept': null } })
    }
    if (asNeededDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'asNeededBoolean': null } })
    }
  }), [asNeededDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='subtitle' fieldLabel='Subtitle'
          startingValue={resourceState.subtitle} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
      </div>
      <h3 id="goal">Goal</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='PlanDefinitionGoal' elementName='goal' startCollapsed
          fieldLabel='Goal' startingValue={resourceState.goal} setResourceState={setResourceState} />
      </div>
      <h3 id="actor">Actor</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='PlanDefinitionActor' elementName='actor' startCollapsed
          fieldLabel='Actor' startingValue={resourceState.actor} setResourceState={setResourceState} />
        <p><b>Subject:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='subject[x]' allowedDatatypes={planDefinitionDotSubjectAllowedDatatypes}
            datatypeState={subjectDatatypeState} setDatatypeState={setSubjectDatatypeState} />
          {(subjectDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='subjectCodeableConcept' startCollapsed
              fieldLabel='Subject (as CodeableConcept)' startingValue={resourceState.subjectCodeableConcept || null}
              valueSet={planDefinitionDotSubjectCodeableConceptValueSet} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='subjectReference' fieldLabel='Subject (as Reference)'
              referencedResourceTypes={planDefinitionDotSubjectReferenceResourceTypes} startCollapsed
              startingValue={resourceState.subjectReference || null} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'canonical') &&
            <DataEntry datatype='uri' elementName='subjectCanonical' fieldLabel='Subject (as Canonical URI)'
              startingValue={resourceState.subjectCanonical || null} setResourceState={setResourceState} />}
        </div>
      </div>
      <h3 id="action">Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='PlanDefinitionAction' elementName='action' startCollapsed
            fieldLabel='Action' startingValue={resourceState.action} setResourceState={setResourceState} />
          <p><b>As Needed:</b></p>
          <div style={{ marginLeft: "24px" }}>
            <DatatypeSelector elementXName='asNeeded[x]' allowedDatatypes={planDefinitionDotAsNeededAllowedDatatypes}
              datatypeState={asNeededDatatypeState} setDatatypeState={setAsNeededDatatypeState} />
            {(asNeededDatatypeState === 'boolean') &&
              <DataEntry datatype='boolean' elementName='asNeededBoolean' fieldLabel='As needed'
                startingValue={resourceState.asNeededBoolean ?? null} setResourceState={setResourceState}
                storeFalse={true} />}
            {(asNeededDatatypeState === 'CodeableConcept') &&
              <DataEntry datatype='CodeableConcept' elementName='asNeededCodeableConcept' startCollapsed
                fieldLabel='As needed (reason)' startingValue={resourceState.asNeededCodeableConcept || null}
                systemChoices={planDefinitionDotAsNeededCodeableConceptSystemChoices} systemChoicesOpen
                setResourceState={setResourceState} />}
          </div>
        </div>
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='type'
          fieldLabel='Type' startingValue={resourceState.type || null}
          valueSet={planDefinitionDotTypeValueSet} startCollapsed
          setResourceState={setResourceState} />
        <MetadataPatternEdit fhirJson={fhirJson} resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default PlanDefinitionEdit;
