import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, Dimmer, Loader, Input } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import TextareaAutosize from 'react-textarea-autosize';
import mammoth from 'mammoth';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
import { submitUpdatedFhirResource } from './ResourceFunctions';
import { useToasts } from 'react-toast-notifications';
import ProfilesByResourceType from './ProfilesByResourceType';
import { getResource } from './UserAccountFunctions';
import JsonEntryPage from './JsonEntryPage';

const submitDocx = async (globalContext, docxToHTML, setFhirOutputState) => {
  globalContext.setGlobalLoadingState(true);
  const body = {
    'functionid': "docxtojsonsectionsconverter",
    'docxToHTML': docxToHTML
  };
  let response = await submitToFevirServer(globalContext, 120000, body, true, false);
  if (response.success === false) {
    alert("We were unable to convert this DOCX file. Check the formatting, and let us know if you need us to investigate.");
  } else {
    setFhirOutputState(JSON.stringify(response.sectionsarraystring, null, 2));
  }
  globalContext.setGlobalLoadingState(false);
}

const addSectionsToComposition = async (globalContext, sectionArray, foiString) => {
  globalContext.setGlobalLoadingState(true);
  let jsonSectionArray = JSON.parse(sectionArray);
  if (foiString && typeof foiString === "string" && !isNaN(foiString) && jsonSectionArray.length > 0 && globalContext) {
    const body = {
      'functionid': "addsectionstocomposition",
      'idToken': "",
      'resourceid': foiString,
      'jsonSectionArray': jsonSectionArray
    };
    let response = await submitToFevirServer(globalContext, 120000, body, true, false);
    globalContext.setGlobalLoadingState(false);
    if (response?.success) {
      if (response.error) {
        alert(response.error);
        return "ERROR";
      }
      if (response.revisedCompositionJson) {
        return response.revisedCompositionJson;
      }
    } else {
      alert("Problem with adding sections to the Composition Resource " + foiString);
      return "ERROR";
    }
  } else {
    alert("Problem adding " + jsonSectionArray.length + " sections to Resource " + foiString);
    return "ERROR";
  }
}

const AddToCompositionResource = ({ globalContext, fhirOutputState, changeFormState, addToFoiState, setAddToFoiState }) => {

  const mimicResourceStateObject = (fhirJson) => {
    return {
      "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
      "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "status": fhirJson.status,
      "type": fhirJson.type, "category": fhirJson.category, "subject": fhirJson.subject, "encounter": fhirJson.encounter,
      "date": fhirJson.date, "useContext": fhirJson.useContext, "author": fhirJson.author,
      "name": fhirJson.name, "title": fhirJson.title, "note": fhirJson.note, "attester": fhirJson.attester,
      "custodian": fhirJson.custodian, "relatesTo": fhirJson.relatesTo, "event": fhirJson.event,
      "section": fhirJson.section, "newClassifications": null
    }
  }
  const [fhirEntryAddToSectionTempState, setFhirEntryAddToSectionTempState] = useState({
    "fhirEntry": {}, "fhirEntryString": "",
    "fhirEntryStringChange": false, "loadedFhirEntryString": "", "title": "", "loading": true, "found": null,
    "classification": false, "rating": false, "comment": false, "adaptation": false, "comparison": false,
    "riskOfBiasAssessmentToolResource": false, "recommendationJustification": false,
    "systematicReviewProfile": false,
    "recommendationProfile": false, "guidelineProfile": false, "summaryOfFindingsProfile": false,
    "comparativeEvidenceReportProfile": false, "baselineMeasureReportProfile": false,
    "participantFlowReportProfile": false, "outcomeMeasureReportProfile": false,
    "m11reportProfile": false, "evidenceReportPackageProfile": false,
    "characteristicDefinition": false, "editMode": false, "projectChangeMode": false,
    "readpermission": false, "editpermission": true, "loggedin": false, "status": "", "totalviews": null,
    "totalusersviewed": null, "totaledits": null, "totalusersedited": null, "projectsChecked": {},
    "projectsList": [], "projectsContain": [], "projectsContainIds": [], "projectResources": [],
    "startingStatus": "draft", "profileName": undefined, "newChildConcepts": [], "conceptCommentsExpanded": false,
    "conceptJsonExpanded": false, "comments": [], "startingVersionId": "0",
    "activeIndex": 1
  });

  const history = useHistory();
  const { addToast } = useToasts();

  return <>
    <Button className="formButton positive" content="Add to a Composition Resource" compact
      onClick={async () => {
        globalContext.setGlobalLoadingState(true);
        let revisedCompositionJson = await addSectionsToComposition(globalContext, fhirOutputState, addToFoiState);
        if (revisedCompositionJson && revisedCompositionJson !== "ERROR") {
          let recommendationProfile = false;
          let guidelineProfile = false;
          let evidenceReportPackageProfile = false;
          let m11reportProfile = false;
          let summaryOfFindingsProfile = false;
          let comparativeEvidenceReportProfile = false;
          let baselineMeasureReportProfile = false;
          let participantFlowReportProfile = false;
          let outcomeMeasureReportProfile = false;
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation")) {
            recommendationProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/guideline")) {
            guidelineProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package")) {
            evidenceReportPackageProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report")) {
            m11reportProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings")) {
            summaryOfFindingsProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report")) {
            comparativeEvidenceReportProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report")) {
            baselineMeasureReportProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report")) {
            participantFlowReportProfile = true;
          }
          if (revisedCompositionJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report") ||
            revisedCompositionJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report")) {
            outcomeMeasureReportProfile = true;
          }
          //next step is to display the Resource Builder in edit mode with the revised json
          await submitUpdatedFhirResource("active", "Composition", revisedCompositionJson.id,
            { current: { compositionDocumentState: mimicResourceStateObject(revisedCompositionJson) } },
            {
              "fhirEntry": revisedCompositionJson, "fhirEntryString": JSON.stringify(revisedCompositionJson),
              "fhirEntryStringChange": false, "loadedFhirEntryString": "", "title": "", "loading": true, "found": null,
              "classification": false, "rating": false, "comment": false, "adaptation": false, "comparison": false,
              "riskOfBiasAssessmentToolResource": false, "recommendationJustification": false,
              "systematicReviewProfile": false,
              "recommendationProfile": recommendationProfile, "guidelineProfile": guidelineProfile, "summaryOfFindingsProfile": summaryOfFindingsProfile,
              "comparativeEvidenceReportProfile": comparativeEvidenceReportProfile, "baselineMeasureReportProfile": baselineMeasureReportProfile,
              "participantFlowReportProfile": participantFlowReportProfile, "outcomeMeasureReportProfile": outcomeMeasureReportProfile,
              "m11reportProfile": m11reportProfile, "evidenceReportPackageProfile": evidenceReportPackageProfile,
              "characteristicDefinition": false, "editMode": false, "projectChangeMode": false,
              "readpermission": false, "editpermission": true, "loggedin": false, "status": "", "totalviews": null,
              "totalusersviewed": null, "totaledits": null, "totalusersedited": null, "projectsChecked": {},
              "projectsList": [], "projectsContain": [], "projectsContainIds": [], "projectResources": [],
              "startingStatus": "draft", "profileName": undefined, "newChildConcepts": [], "conceptCommentsExpanded": false,
              "conceptJsonExpanded": false, "comments": [], "startingVersionId": "0",
              "activeIndex": 1
            }, setFhirEntryAddToSectionTempState,
            globalContext, history, changeFormState, true, addToast);
        }
        globalContext.setGlobalLoadingState(false);
      }}
      disabled={fhirOutputState === "[]" || !addToFoiState} />
    &nbsp;&nbsp;&nbsp;
    <TextField style={{ width: "240px" }} className="inputField" type='text' size="small" variant='outlined'
      label='Composition Resource FOI' value={addToFoiState}
      onChange={(e) => { setAddToFoiState(e.target.value); }} />
  </>
}

const useTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    }
  })
};

const CreateNewGuideline = ({ fhirOutputState }) => {

  let resourceStarter = {
    "resourceType": "Composition",
    "meta": { "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline"] },
    "title": "",
    "type": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "Guideline",
        display: "Guideline"
      }]
    },
    "section": []
  };
  resourceStarter.section = ProfilesByResourceType.Composition.Guideline.sectionStarter;
  let jsonSectionArray = JSON.parse(fhirOutputState);
  let discussionSectionFound = false;
  if (resourceStarter.section?.length) {
    for (let section of resourceStarter.section) {
      if (section.code?.coding?.[0]?.code === "discussion") {
        discussionSectionFound = true;
        if (!section.section) {
          section.section = [];
        }
        section.section = section.section.concat(jsonSectionArray);
        break;
      }
    }
    if (!discussionSectionFound) {
      let discussonSection = {
        "title": "Discussion",
        "code": {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "discussion", "display": "Discussion"
          }]
        },
        "section": jsonSectionArray
      };
      resourceStarter.section.push(discussonSection);
    }
  }
  return <>
    <h2 style={{
      zIndex: "-1", margin: "0px", width: "100%", textAlign: "center",
      top: "8px", position: "fixed", color: "#AC3B61"
    }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Create Guideline</h2>
    <JsonEntryPage useTitle={useTitle} resourceType={"Composition"} resourceId={""}
      getResource={getResource}
      resourceStarter={resourceStarter} />
  </>
}

const CreateNewRecommendation = ({ fhirOutputState }) => {

  let resourceStarter = {
    "resourceType": "Composition",
    "meta": { "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation"] },
    "title": "",
    "type": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "Recommendation",
        display: "Recommendation"
      }]
    },
    "section": []
  };
  resourceStarter.section = ProfilesByResourceType.Composition.Recommendation.sectionStarter;
  let jsonSectionArray = JSON.parse(fhirOutputState);
  let discussionSectionFound = false;
  if (resourceStarter.section?.length) {
    for (let section of resourceStarter.section) {
      if (section.code?.coding?.[0]?.code === "discussion") {
        discussionSectionFound = true;
        if (!section.section) {
          section.section = [];
        }
        section.section = section.section.concat(jsonSectionArray);
        break;
      }
    }
    if (!discussionSectionFound) {
      let discussonSection = {
        "title": "Discussion",
        "code": {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "discussion", "display": "Discussion"
          }]
        },
        "section": jsonSectionArray
      };
      resourceStarter.section.push(discussonSection);
    }
  }
  return <>
    <h2 style={{
      zIndex: "-1", margin: "0px", width: "100%", textAlign: "center",
      top: "8px", position: "fixed", color: "#AC3B61"
    }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: Create Recommendation</h2>
    <JsonEntryPage useTitle={useTitle} resourceType={"Composition"} resourceId={""}
      getResource={getResource}
      resourceStarter={resourceStarter} />
  </>
}

const AddSectionsToComposition = ({ globalContext, fhirOutputState, changeFormState, addToFoiState, setAddToFoiState }) => {

  const [createNewGuidelineState, setCreateNewGuidelineState] = useState(false);
  const [createNewRecommendationState, setCreateNewRecommendationState] = useState(false);

  return <>
    <AddToCompositionResource globalContext={globalContext} fhirOutputState={fhirOutputState}
      changeFormState={changeFormState} addToFoiState={addToFoiState} setAddToFoiState={setAddToFoiState} />
    &nbsp;&nbsp;&nbsp;
    <Button className="formButton positive" content="Create New Guideline" compact
      onClick={() => {
        setCreateNewGuidelineState(true);
      }}
      disabled={fhirOutputState === "[]"} />
    {createNewGuidelineState && <CreateNewGuideline fhirOutputState={fhirOutputState} />}
    &nbsp;&nbsp;&nbsp;
    <Button className="formButton positive" content="Create New Recommendation" compact
      onClick={() => {
        setCreateNewRecommendationState(true);
      }}
      disabled={fhirOutputState === "[]"} />
    {createNewRecommendationState && <CreateNewRecommendation fhirOutputState={fhirOutputState} />}
  </>
}

const DocxToJsonSectionsConverterPage = ({ useTitle, changeFormState }) => {

  useTitle("FEvIR DOCX-to-JSON-Sections Converter");

  const [docxEntryState, setDocxEntryState] = useState({ "inputChanged": true, "codings": [{ "systemName": "", "systemUri": "", "code": "", "display": "", "qualifiers": [], "preset": "" }], text: "", docxFile: "" });
  const [fhirOutputState, setFhirOutputState] = useState("");
  const [addToFoiState, setAddToFoiState] = useState("");
  const globalContext = useContext(FevirContext);

  return (
    <div>
      {globalContext.globalLoadingState &&
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      }
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: DOCX-to-JSON-Sections Converter</h2>
        <br />
        <div>
          <h3>.DOCX file</h3>
          <Input id="inputDocxFile"
            type="file"
            accept=".docx"
            onChange={async (e, data) => {
              globalContext.setGlobalLoadingState(true);
              try {
                if (e.target.files.length > 0) {
                  let file = e.target.files[0];
                  await file.arrayBuffer().then((x) => {
                    mammoth.convertToHtml({ arrayBuffer: x })
                      .then((result) => {
                        let html = result.value; // The generated HTML
                        let messages = result.messages; // Any messages, such as warnings during conversion
                        //console.log(messages);
                        setDocxEntryState(prevState => { return { ...prevState, inputChanged: true, text: "", docxToHTML: html }; });
                        //console.log(str)
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                  });
                }
              } catch (e) {
                alert("File could not be processed.");
              }
              globalContext.setGlobalLoadingState(false);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          Mark any section headers as Heading style from the Home menu before submission so they will be recognized as section headers. Make sure the DOCX file is not open.
        </div>
        {/* <TextareaAutosize style={{width: "98%", color: fhirOutputValidState}} spellCheck="false" value={separateRisConverterOutput(fhirOutputState)} /> */}
        <br />
        <Button className="formButton positive" content="Convert DOCX to JSON Sections" compact
          onClick={() => {
            submitDocx(globalContext, docxEntryState.docxToHTML, setFhirOutputState);
          }}
          disabled={!docxEntryState.docxToHTML} />
        <br /><br />
        {fhirOutputState &&
          <>
            <h3>JSON Output</h3>
            <br />
            <AddSectionsToComposition globalContext={globalContext} fhirOutputState={fhirOutputState}
              changeFormState={changeFormState} addToFoiState={addToFoiState} setAddToFoiState={setAddToFoiState} />
            <br />
            <TextareaAutosize style={{ width: "98%", color: "#000000" }}
              spellCheck="false"
              value={fhirOutputState} />
            <br />
            <br />
            <AddSectionsToComposition globalContext={globalContext} fhirOutputState={fhirOutputState}
              changeFormState={changeFormState} addToFoiState={addToFoiState} setAddToFoiState={setAddToFoiState} />
          </>
        }
        <br />
      </Segment>
    </div>
  );
};

export default DocxToJsonSectionsConverterPage;