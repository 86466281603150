import React, { useState, useEffect, useImperativeHandle } from 'react';
import { SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import EvidenceVariableCriteriaDisplay from './EvidenceVariableCriteriaDisplay';
import { Button } from 'semantic-ui-react';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const assignLinkIdValues = (characteristic, starterValue) => {
  if (!starterValue) {
    starterValue = "item";
  }
  for (const i in characteristic) {
    characteristic[i].linkId = starterValue + i;
    if (characteristic[i].definitionByCombination &&
      Array.isArray(characteristic[i].definitionByCombination.characteristic)) {
      characteristic[i].definitionByCombination.characteristic = assignLinkIdValues(characteristic[i].definitionByCombination.characteristic, characteristic[i].linkId + "..")
    }
  }
  return characteristic;
}

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.shortTitle) {
      innerDiv += "<p><b>Short title: </b>" + resource.shortTitle + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.note?.length > 0) {
      innerDiv += resource.note.map((note) => {
        return "<p><b>Note: </b>" + note.text + "</p><br/>"
      }).join("");
    }
    if (resource.definition?.concept) {
      innerDiv += "<p><b>Definition: </b>" + getStringFromFHIR.CodeableConcept(resource.definition.concept) + "</p><br/>";
    }
    if (resource.definition?.reference) {
      innerDiv += "<p><b>Definition: </b>" + getStringFromFHIR.Reference(resource.definition.reference) + "</p><br/>";
    }
    if (resource.handling) {
      innerDiv += "<p><b>Handling: </b>" + resource.handling + "</p><br/>";
    }
    if (resource.category?.length > 0) {
      innerDiv += resource.category.map((item) => {
        let categoryName = item.name || "";
        let categoryValue = "";
        if (item.valueCodeableConcept) {
          categoryValue = getStringFromFHIR.CodeableConcept(item.valueCodeableConcept);
        } else if (item.valueQuantity) {
          categoryValue = getStringFromFHIR.Quantity(item.valueQuantity);
        } else if (item.valueRange) {
          categoryValue = getStringFromFHIR.Range(item.valueRange);
        } else if (item.valueReference) {
          categoryValue = getStringFromFHIR.Reference(item.valueReference);
        }
        if (categoryName && categoryValue) {
          return "<p><b>Category " + categoryName + ": </b>" + categoryValue + "</p><br/>"
        } else if (categoryName) {
          return "<p><b>Category: </b>" + categoryName + "</p><br/>"
        } else if (categoryValue) {
          return "<p><b>Category: </b>" + categoryValue + "</p><br/>"
        }
      }).join("");
    }
  } catch { 
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const evidenceVariableDotDefinitionResourceTypes = ['Group'];
const evidenceVariableDotHandlingValues = ['continuous', 'dichotomous', 'ordinal', 'polychotomous'];
const evidenceVariableDefinitionConceptSystemChoices = [
  { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' },
  { 'uri': 'http://hl7.org/fhir/sid/icd-10', 'display': 'ICD-10' },
  { 'uri': 'http://loinc.org', 'display': 'LOINC' },
  { 'uri': 'http://www.nlm.nih.gov/research/umls/rxnorm', 'display': 'RxNorm' },
  { 'uri': 'https://www.whocc.no/atc_ddd_index/', 'display': 'ATC' }
];
const EvidenceVariableEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, 
  classificationsArrayState, classificationsLoadedState, globalContext }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "shortTitle",
      "status", "experimental", "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
      "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
      "actual", "definition", "characteristic", "characteristicDefinition", "handling", "category"]
  */
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "shortTitle": fhirJson.shortTitle, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "actual": fhirJson.actual, "definition": fhirJson.definition, "characteristic": fhirJson.characteristic, "characteristicDefinition": fhirJson.characteristicDefinition,
    "handling": fhirJson.handling, "category": fhirJson.category, "newClassifications": null
  });
  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  useImperativeHandle(formInputsStateRef, () => ({
    evidenceVariableState: resourceState
  }), [resourceState]);

  return <div style={{ marginTop: "12px" }}>
    <h3 id="summary">Summary</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={resourceState.title} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='shortTitle' fieldLabel='Short title'
        startingValue={resourceState.shortTitle} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={resourceState.name} setResourceState={setResourceState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
      startCollapsed
        startingValue={resourceState.description} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.note} setResourceState={setResourceState} />
      <DataEntry datatype='boolean' elementName='actual' fieldLabel='Actual'
        startingValue={resourceState.actual} setResourceState={setResourceState} />
      <h4>Narrative Summary</h4>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
    </div>
    <h3 id="definition">Definition</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableReference' elementName='definition' fieldLabel='Variable Definition'
        referencedResourceTypes={evidenceVariableDotDefinitionResourceTypes} startCollapsed
        enableCreation={true} systemChoices={evidenceVariableDefinitionConceptSystemChoices} systemChoicesOpen={true}
        fullResourceState={resourceState} setSourceJsonState={setSourceJsonState}
        startingValue={resourceState.definition} setResourceState={setResourceState} />
    </div>
    {fhirJson.characteristic && <><br />
      <Button style={{ color: "#000000" }} className="formButton" content="DELETE characteristic ELEMENT"
        onClick={() => {
          setResourceState(prevState => { return { ...prevState, "characteristic": "" }; });
          alert("The characteristic element will be deleted once you click Update.");
        }} />
      <br />
      <Button style={{ color: "#000000" }} className="formButton" content="Re-index Characteristics"
        onClick={() => {
          let newCharacteristic = assignLinkIdValues(resourceState.characteristic, "item");
          setResourceState(prevState => { return { ...prevState, "characteristic": newCharacteristic }; });
        }} />
      <EvidenceVariableCriteriaDisplay fhirJson={fhirJson} />
    </>}
    <h3 id="handling">Handling</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='code' elementName='handling' fieldLabel='Handling'
        startingValue={resourceState.handling} setResourceState={setResourceState}
        allowedValues={evidenceVariableDotHandlingValues} />
      <DataEntry asArray={true} datatype='EvidenceVariableCategory' elementName='category'
        fieldLabel='Category' startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.category} setResourceState={setResourceState} />
    </div>
    <h3 id="how-to-cite">How to Cite</h3>
    <div style={{ marginLeft: "24px" }}>
      <DisplayHowToCite citationSummary={citationSummary}
        citationJson={citationJson} />
    </div>
    <h3 id="metadata">Metadata</h3>
    <div style={{ marginLeft: "24px" }}>
      <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
    </div>
    <h3 id="classifiers">Classifiers</h3>
    <div style={{ marginLeft: "24px" }}>
      <div>
        <p>Add Classifiers:</p>
        <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
          fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
      </div>
      {(classificationsArrayState) && <div>
        <p>Existing Classifiers:</p>
        {classificationsLoadedState ?
          <DisplayClassifiers classificationsArray={classificationsArrayState} />
          :
          <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
        }
      </div>}
    </div>
    <h3 id="json-outline">JSON Outline</h3>
    <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
    <br /><br />
  </div>
}

export default EvidenceVariableEdit;
