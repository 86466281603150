import React from 'react';
import { Button } from 'semantic-ui-react';
import submitToFevirServer from './SubmitToFevirServer';

const generateNetEffectReport = async (globalContext, resourceState, history, resourceDictionary) => {
    let body = {
        functionid: 'generateneteffectreportfromsummaryoffindings',
        compositionsection: resourceState.section,
        compositiontitle: resourceState.title,
        compositionsubject: resourceState.subject,
        sourcefoi: resourceState.resourceJson.id,
        resourceDictionary: resourceDictionary
    };
    let response = await submitToFevirServer(globalContext, 40000, body, false, false);
    if (response?.success && response.formstateid) {
        let newUrl = "/resources/Composition/" + response.formstateid;
        history.push('/');
        history.push(newUrl);
    } else {
        alert("There was a problem generating the Summary of Net Effect Report.");
    }
}

const GenerateNetEffectReport = ({ globalContext, resourceState, previousVersionLoaded, history, 
    resourceDictionary }) => {

    return <>
        <br />
        <span>
            <Button className="formButton" style={{ color: "#000000", width: "220px" }}
                content="Generate Net Effect Report"
                onClick={() => {
                    if (globalContext.userState.id) {
                        generateNetEffectReport(globalContext, resourceState, history, resourceDictionary);
                    } else {
                        alert("Please login to use this function.");
                    }
                }}
                disabled={previousVersionLoaded}
            />
            &nbsp;&nbsp;&nbsp;
            <>
                Generate a Summary of Net Effect Report with an individualized summary of benefits and harms.
            </>
        </span>
    </>
}

export default GenerateNetEffectReport;