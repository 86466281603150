import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const ListDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.extension)) {
    for (let extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact') {
        if (extension.valueRelatedArtifact.type === "cite-as") {
          howToCite = extension.valueRelatedArtifact.citation;
        }
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") ?
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
          : <p>[Narrative summary not generated.]</p>}
      </div>
      <h3 id="description">Description</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.status && <p><b>Status: </b>{fhirJson.status}</p>}
        {fhirJson.mode && <p><b>Mode: </b>{fhirJson.mode}</p>}
        {fhirJson.code && <div><b>Purpose: </b><DisplayFromFHIR codeableConcept={fhirJson.code} />
          <br /><br /></div>}
        {(fhirJson.subject?.length === 1) && <p><b>Subject: </b><DisplayFromFHIR reference={fhirJson.subject[0]} /></p>}
        {(fhirJson.subject?.length > 1) && fhirJson.subject.map((subject, subjectIndex) => {
          return <div key={subjectIndex}><div><b>Subject: </b><DisplayFromFHIR reference={subject} /></div></div>
        })}
        {fhirJson.encounter && <p><b>Encounter: </b><DisplayFromFHIR reference={fhirJson.encounter} /></p>}
        {fhirJson.date && <p><b>Date: </b>{getStringFromFHIR.dateTime(fhirJson.date)}</p>}
        {fhirJson.source && <p><b>Source (Author): </b><DisplayFromFHIR reference={fhirJson.source} /></p>}
        {fhirJson.orderedBy && <div><b>Ordered by: </b><DisplayFromFHIR codeableConcept={fhirJson.orderedBy} />
          <br /><br /></div>}
        {(fhirJson.note?.length === 1) && <p><b>Note: </b><DisplayFromFHIR annotation={fhirJson.note[0]} /></p>}
        {(fhirJson.note?.length > 1) && fhirJson.note.map((note, noteIndex) => {
          return <div key={noteIndex}><div><b>Note: </b><DisplayFromFHIR annotation={note} /></div></div>
        })}
      </div>
      <h3 id="list-entries">List Entries</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.entry) && fhirJson.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}>
            <p><b>Entry {entryIndex + 1}: </b></p>
            <div style={{ marginLeft: "24px" }}>
              {entry.flag && <div><b>Flag: </b><DisplayFromFHIR codeableConcept={entry.flag} />
                <br /><br /></div>}
              {entry.item && <p><DisplayFromFHIR reference={entry.item} /></p>}
              {(typeof entry.deleted === "boolean") && <p><b>Deleted: </b>{getStringFromFHIR.boolean(entry.deleted)}</p>}
              {entry.date && <p><b>Date added: </b>{getStringFromFHIR.dateTime(entry.date)}</p>}
            </div>
          </div>
        })}
        {((!fhirJson.entry || fhirJson.entry.length === 0) && fhirJson.emptyReason) &&
          <span><b>Why Empty: </b><DisplayFromFHIR codeableConcept={fhirJson.emptyReason} /></span>}
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ListDisplay;