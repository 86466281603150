import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const ActivityDefinitionDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") ?
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
          : <p>[Narrative summary not generated.]</p>}
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.subtitle && <p><b>Subtitle: </b>{fhirJson.subtitle}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
      </div>
      <h3 id="activity-definition">Activity Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.subjectCodeableConcept && <span><b>Subject: </b><DisplayFromFHIR codeableConcept={fhirJson.subjectCodeableConcept} /></span>}
        {fhirJson.subjectReference && <p><b>Subject: </b><DisplayFromFHIR reference={fhirJson.subjectReference} /></p>}
        {fhirJson.subjectCanonical && <p><b>Subject: </b><DisplayFromFHIR uri={fhirJson.subjectCanonical} /></p>}
        {Array.isArray(fhirJson.library) && fhirJson.library.map((library, libraryIndex) => {
          return <div key={libraryIndex}><b>Library: </b><DisplayFromFHIR uri={library} /></div>
        })}
        {fhirJson.kind && <p><b>Kind of Resource: </b>{fhirJson.kind}</p>}
        {fhirJson.profile && <p><b>Profile: </b><DisplayFromFHIR uri={fhirJson.profile} /></p>}
        {fhirJson.code && <span><b>Code: </b><DisplayFromFHIR codeableConcept={fhirJson.code} /></span>}
        {fhirJson.intent && <p><b>Intent: </b>{fhirJson.intent}</p>}
        {fhirJson.priority && <p><b>Priority: </b>{fhirJson.priority}</p>}
        {fhirJson.doNotPerform && <p><b>Negation: DO NOT PEFORM</b></p>}
        {fhirJson.timingTiming && <p><b>Timing: </b>{getStringFromFHIR.Timing(fhirJson.timingTiming)}</p>}
        {fhirJson.timingAge && <p><b>Timing: </b>{getStringFromFHIR.Quantity(fhirJson.timingAge)}</p>}
        {fhirJson.timingRange && <p><b>Timing: </b>{getStringFromFHIR.Range(fhirJson.timingRange)}</p>}
        {fhirJson.timingDuration && <p><b>Timing: </b>{getStringFromFHIR.Quantity(fhirJson.timingDuration)}</p>}
        {fhirJson.asNeededBoolean !== undefined && <p><b>As needed: </b>{getStringFromFHIR.boolean(fhirJson.asNeededBoolean)}</p>}
        {fhirJson.asNeededCodeableConcept && <span><b>As needed for: </b><DisplayFromFHIR codeableConcept={fhirJson.asNeededCodeableConcept} /></span>}
        {fhirJson.location && <p><b>Location: </b><DisplayFromFHIR codeableReference={fhirJson.location} /></p>}
        {fhirJson.participant?.length > 0 && <div>
          <p>{fhirJson.participant.length === 1 ? <b>Participant: </b> : <b>Participants: </b>}</p>
          <div style={{ marginLeft: "24px" }}>
            {fhirJson.participant.map((participant, participantIndex) => {
              return <div key={participantIndex}>
                <p><b>Participant entry {participantIndex + 1}</b></p>
                <div style={{ marginLeft: "24px" }}>
                  {participant.type && <p><b>Participant type: </b>{participant.type}</p>}
                  {participant.typeCanonical && <p><b>Participant type canonical: </b><DisplayFromFHIR uri={participant.typeCanonical} /></p>}
                  {participant.typeReference && <p><b>Participant type reference: </b><DisplayFromFHIR reference={participant.typeReference} /></p>}
                  {participant.role && <span><b>Participant role: </b><DisplayFromFHIR codeableConcept={participant.role} /></span>}
                  {participant.function && <span><b>Participant function: </b><DisplayFromFHIR codeableConcept={participant.function} /></span>}
                </div>
              </div>
            })}
          </div>
        </div>
        }
        {fhirJson.productReference && <p><b>Product: </b><DisplayFromFHIR reference={fhirJson.productReference} /></p>}
        {fhirJson.productCodeableConcept && <span><b>Product: </b><DisplayFromFHIR codeableConcept={fhirJson.productCodeableConcept} /></span>}
        {fhirJson.quantity && <p><b>Quantity: </b>{getStringFromFHIR.Quantity(fhirJson.quantity)}</p>}
        {(fhirJson.dosage?.length === 1) && <p><b>Dosage: </b>{getStringFromFHIR.Dosage(fhirJson.dosage[0])}</p>}
        {(fhirJson.dosage?.length > 1) && fhirJson.dosage.map((dosage, dosageIndex) => {
          return <div key={dosageIndex}><div><b>Dosage: </b>{getStringFromFHIR.Dosage(dosage)}</div></div>
        })}
        {(fhirJson.bodySite?.length === 1) && <span><b>Body Site: </b><DisplayFromFHIR codeableConcept={fhirJson.bodySite[0]} /></span>}
        {(fhirJson.bodySite?.length > 1) && fhirJson.bodySite.map((bodySite, bodySiteIndex) => {
          return <div key={bodySiteIndex}><div><b>Body Site: </b><DisplayFromFHIR codeableConcept={bodySite} /></div></div>
        })}
        {Array.isArray(fhirJson.specimenRequirement) && fhirJson.specimenRequirement.map((specimenRequirement, index) => {
          return <div key={index}><b>Specimen Requirement: </b><DisplayFromFHIR uri={specimenRequirement} /></div>
        })}
        {Array.isArray(fhirJson.observationRequirement) && fhirJson.observationRequirement.map((observationRequirement, index) => {
          return <div key={index}><b>Observation Requirement: </b><DisplayFromFHIR uri={observationRequirement} /></div>
        })}
        {Array.isArray(fhirJson.observationResultRequirement) && fhirJson.observationResultRequirement.map((observationResultRequirement, index) => {
          return <div key={index}><b>Observation Result Requirement: </b><DisplayFromFHIR uri={observationResultRequirement} /></div>
        })}
        {fhirJson.transform && <p><b>Transform: </b><DisplayFromFHIR uri={fhirJson.transform} /></p>}
        {fhirJson.dynamicValue?.length > 0 && <div>
          <p>{fhirJson.dynamicValue.length === 1 ? <b>Dynamic Value: </b> : <b>Dynamic Values: </b>}</p>
          <div style={{ marginLeft: "24px" }}>
            {fhirJson.dynamicValue.map((dynamicValue, dynamicValueIndex) => {
              return <div key={dynamicValueIndex}>
                {dynamicValue.path && <p><b>Path: </b>{dynamicValue.path}</p>}
                {dynamicValue.expression && <div><b>Expression: </b><DisplayFromFHIR expression={dynamicValue.expression} /></div>}
                <br />
              </div>
            })}
          </div>
        </div>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ActivityDefinitionDisplay;