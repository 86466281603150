import React, { useState, useImperativeHandle, useEffect } from 'react';
import { DisplayFromFHIR, SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const groupDotTypeValues = ['person', 'animal', 'practitioner', 'device', 'careteam', 'healthcareservice', 'location', 'organization', 'relatedperson', 'specimen'];
const groupDotMembershipValues = ['conceptual', 'definitional', 'enumerated'];
const groupDotManagingEntityResourceTypes = ['Organization', 'RelatedPerson', 'Practitioner', 'PractitionerRole'];
const groupDotCombinationMethodValues = ['all-of', 'any-of', 'at-least', 'at-most', 'except-subset'];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.type && typeof resource.type === "string") {
      innerDiv += "<p><b>Type: </b>" + resource.type + "</p><br/>";
    }
    if (resource.code) {
      innerDiv += "<p><b>Kind: </b>" + getStringFromFHIR.CodeableConcept(resource.code) + "</p><br/>";
    }
    if (resource.membership) {
      innerDiv += "<p><b>Membership/Definitional: </b>" + resource.membership + "</p><br/>";
    }
    if (resource.quantity) {
      innerDiv += "<p><b>Quantity: </b>" + resource.quantity + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.managingEntity) {
      innerDiv += "<p><b>Managing Entity: </b>" + getStringFromFHIR.Reference(resource.managingEntity) + "</p><br/>";
    }
    let characteristicExpression;
    if (Array.isArray(resource.extension)) {
      for (let extension of resource.extension) {
        if (extension.url === "http://hl7.org/fhir/StructureDefinition/characteristicExpression") {
          characteristicExpression = extension.valueExpression;
        }
      }
    }
    if (characteristicExpression) {
      status = "extensions";
      if (characteristicExpression.name) {
        innerDiv += "<p><b>Defined by Expression Named: </b>" + characteristicExpression.name + "</p><br/>";
      }
      if (characteristicExpression.description) {
        innerDiv += "<p><b>Defined by Expression: </b>" + characteristicExpression.description + "</p><br/>";
      }
      if (characteristicExpression.language) {
        innerDiv += "<p><b>Defined by Expression in Language: </b>" + characteristicExpression.language + "</p><br/>";
      }
      if (characteristicExpression.expression) {
        innerDiv += "<p><b>Defined by Expression: </b>" + characteristicExpression.expression + "</p><br/>";
      }
      if (characteristicExpression.reference) {
        innerDiv += "<p><b>Defined by Expression at URL: </b>" + characteristicExpression.reference + "</p><br/>";
      }
    }
    if (resource.combinationMethod) {
      innerDiv += "<p><b>Combination Method: </b>" + resource.combinationMethod + "</p><br/>";
    }
    if (resource.combinationThreshold) {
      innerDiv += "<p><b>Number of characteristics: </b>" + resource.combinationThreshold + "</p><br/>";
    }
    if (resource.characteristic?.length > 0) {
      innerDiv += resource.characteristic.map((item) => {
        let description = item.description || "";
        let code = getStringFromFHIR.CodeableConcept(item.code) || "";
        let categoryValue = "";
        if (item.valueCodeableConcept) {
          categoryValue = getStringFromFHIR.CodeableConcept(item.valueCodeableConcept);
        } else if (item.valueQuantity) {
          categoryValue = getStringFromFHIR.Quantity(item.valueQuantity);
        } else if (item.valueRange) {
          categoryValue = getStringFromFHIR.Range(item.valueRange);
        } else if (item.valueReference) {
          categoryValue = getStringFromFHIR.Reference(item.valueReference);
        } else if (item.valueBoolean) {
          categoryValue = getStringFromFHIR.boolean(item.valueBoolean);
        } else if (item.valueUri) {
          categoryValue = item.valueUri;
        } else if (item.valueExpression) {
          categoryValue = "[Defined by Expression]";
        }
        if (description) {
          return "<p><b>Characteristic: </b>" + description + "; <b>Defined as: </b>" + code + " matching value of " + categoryValue + "</p><br/>"
        } else {
          return "<p><b>Characteristic: </b>" + code + " matching value of " + categoryValue + "</p><br/>"
        }
      }).join("");
    }
    if (resource.member?.length > 0) {
      innerDiv += resource.member.map((member) => {
        return "<p><b>Member: </b>" + getStringFromFHIR.Reference(member.entity) + "</p><br/>"
      }).join("");
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const GroupEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  classificationsArrayState, classificationsLoadedState, globalContext }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title",
      "status", "experimental", "date", "publisher", "contact", "description", "useContext", "purpose", "copyright", "copyrightLabel",
      "type", "membership", "code", "quantity", "managingEntity",
      "combinationMethod", "combinationThreshold", "characteristic", "member"]
  */
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date,
    "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext,
    "purpose": fhirJson.purpose, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "type": fhirJson.type, "membership": fhirJson.membership, "code": fhirJson.code, "quantity": fhirJson.quantity,
    "managingEntity": fhirJson.managingEntity, "combinationMethod": fhirJson.combinationMethod, "combinationThreshold": fhirJson.combinationThreshold,
    "characteristic": fhirJson.characteristic, "member": fhirJson.member,
    "newClassifications": null
  });
  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  let starterCharacteristicExpression = null;
  if (Array.isArray(fhirJson.extension)) {
    for (let extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/StructureDefinition/characteristicExpression") {
        starterCharacteristicExpression = extension.valueExpression;
        break;
      }
    }
  }

  const [characteristicExpressionState, setCharacteristicExpressionState] = useState({ "valueExpression": starterCharacteristicExpression });

  useImperativeHandle(formInputsStateRef, () => ({
    groupState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (characteristicExpressionState.valueExpression) {
      setResourceState(prevState => {
        let newExtension = prevState.extension;
        if (!newExtension) {
          newExtension = [];
        }
        let expressionExtensionFound = false;
        for (let extension of newExtension) {
          if (extension.url === "http://hl7.org/fhir/StructureDefinition/characteristicExpression") {
            expressionExtensionFound = true;
            extension.valueExpression = characteristicExpressionState.valueExpression;
          }
        }
        if (!expressionExtensionFound) {
          newExtension.push({
            "url": "http://hl7.org/fhir/StructureDefinition/characteristicExpression",
            "valueExpression": characteristicExpressionState.valueExpression
          })
        }
        return { ...prevState, "extension": newExtension };
      });
    }
  }), [characteristicExpressionState]);

  let noMemberText = "Members list not provided";
  if (fhirJson.member?.length > 0) {
    noMemberText = "";
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startCollapsed
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <p>Select a Membership code for this Group. Choose 'enumerated' if you will report a quantity for the Group or if you will report or reference the Group members. Choose 'definitional' if you will report the characteristics defining membership in the Group and will select a Type code specifying the type of Group members in a FHIR context. Choose 'conceptual' if you are not reporting an enumerated or definitional Group, or if you are reporting a Group without a Type code.</p>
        <DataEntry datatype='code' elementName='membership' fieldLabel='Membership'
          allowedValues={groupDotMembershipValues}
          startingValue={resourceState.membership} setResourceState={setResourceState} />
        <DataEntry datatype='unsignedInt' elementName='quantity' fieldLabel='Quantity'
          startingValue={resourceState.quantity} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='type' fieldLabel='Type'
          allowedValues={groupDotTypeValues}
          startingValue={resourceState.type} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Kind'
        startCollapsed
          startingValue={resourceState.code} setResourceState={setResourceState} />
        <DataEntry datatype="Expression" elementName="valueExpression"
          fieldLabel="Characteristics Defined By Expression"
          startingValue={characteristicExpressionState.valueExpression}
          startCollapsed
          setResourceState={setCharacteristicExpressionState} />
        <h4>Narrative Summary</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
            generatedNarrative={generateNarrative(resourceState)} startCollapsed
            startingValue={resourceState.text} setResourceState={setResourceState} />
        </div>
      </div>
      <h3 id="characteristic-combination">Characteristic Combination</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='code' elementName='combinationMethod' fieldLabel='Combination Method'
          allowedValues={groupDotCombinationMethodValues}
          startingValue={resourceState.combinationMethod} setResourceState={setResourceState} />
        {(resourceState.combinationMethod === "at-least" || resourceState.combinationMethod === "at-most" || 
        resourceState.combinationThreshold) && <DataEntry datatype='positiveInt' 
        elementName='combinationThreshold' fieldLabel='Number of characteristics'
          startingValue={resourceState.combinationThreshold} setResourceState={setResourceState} />}
      </div>
      <br />
      <ManageInclusionExclusionEnhancedCharacteristicTables editMode={true}
        setSourceJsonState={setSourceJsonState}
        globalContext={globalContext}
        fhirJson={fhirJson} setResourceState={setResourceState} />
      <h3 id="members">Members</h3>
      <div style={{ marginLeft: "24px" }}>
        {resourceState.membership === "enumerated" ?
          <>{noMemberText ?
            <p>{noMemberText}</p>
            :
            <>{fhirJson.member.map((member, memberIndex) => {
              if (member.entity === undefined) {
                return <p key={memberIndex}></p>
              }
              return <div key={memberIndex}>
                <DisplayFromFHIR reference={member.entity} />
              </div>
            })}</>
          }</>
          :
          <p>This is a descriptive (definitional) Group. Listing members does not apply.</p>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Reference' elementName='managingEntity' fieldLabel='Managing Entity'
          referencedResourceTypes={groupDotManagingEntityResourceTypes} startCollapsed
          startingValue={resourceState.managingEntity} setResourceState={setResourceState} />
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default GroupEdit;
