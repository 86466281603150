import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import submitToFevirServer from './SubmitToFevirServer';

const createSummaryOfFindingsReport = async (sourceTitle, summaryOfFindingsStarter, globalContext) => {
  let titleStarter = sourceTitle || "[Untitled Comprative Evidence Report]";
  let title = "Summary of Findings from " + titleStarter;
  let newSummaryofFindings = {
    "resourceType": "Composition",
    "meta": {
      "profile": [
        "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings"
      ]
    },
    "status": "final",
    "type": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "SummaryOfFindings",
        display: "SummaryOfFindings"
      }],
      text: 'Summary of Findings'
    },
    "subject": summaryOfFindingsStarter.subject || "",
    "author": [
      {
        "display": "Computable Publishing®: Summary of Findings Report Authoring Tool"
      },
      {
        "display": globalContext.userState.name
      }
    ],
    "title": title,
    "custodian": {
      "reference": "Organization/118079",
      "type": "Organization",
      "display": "Computable Publishing LLC"
    },
    "relatesTo": [
      summaryOfFindingsStarter.derivedFrom
    ],
    "section": [
      {
        "title": "Column Headers",
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "column-headers",
              "display": "Column Headers"
            }
          ]
        },
        "section": [
          {
            "title": "Outcome",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "outcome-measure",
                  "display": "Outcome Measure"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Outcome</div>"
            }
          },
          {
            "title": "Sample Size",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "sample-size",
                  "display": "Sample Size"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sample size: # studies, # participants, # counted, # events</div>"
            }
          },
          {
            "title": "Result Without Treatment",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-comparator-alone",
                  "display": "Result with comparator alone"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result Without Treatment</div>"
            }
          },
          {
            "title": "Result With Treatment--Observed",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-intervention-alone",
                  "display": "Result with intervention alone"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Observed)</div>"
            }
          },
          {
            "title": "Result With Treatment--Derived",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-intervention-alone-calculated",
                  "display": "Result with intervention alone (calculated)"
                }
              ],
              "text": "result with intervention with calculated value derived from the control group and effect estimate"
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Calculated)</div>"
            }
          },
          {
            "title": "Effect Estimate",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-intervention-vs-comparator",
                  "display": "Result with intervention vs. comparator"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Effect Estimate (Relative effect)</div>"
            }
          },
          {
            "title": "Certainty of Evidence",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "certainty-of-evidence",
                  "display": "Certainty of Evidence"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Certainty of finding (Quality of evidence)</div>"
            }
          },
          {
            "title": "Assertion",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "assertion",
                  "display": "Assertion"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">What this means</div>"
            }
          }
        ]
      }
    ]
  };
  if (summaryOfFindingsStarter.interventionGroup) {
    newSummaryofFindings.relatesTo.push(summaryOfFindingsStarter.interventionGroup)
  }
  if (summaryOfFindingsStarter.comparatorGroup) {
    newSummaryofFindings.relatesTo.push(summaryOfFindingsStarter.comparatorGroup)
  }
  if (summaryOfFindingsStarter.outcomesRelatesTo) {
    newSummaryofFindings.relatesTo = newSummaryofFindings.relatesTo.concat(summaryOfFindingsStarter.outcomesRelatesTo);
  }
  if (Array.isArray(summaryOfFindingsStarter.outcomesSection) && summaryOfFindingsStarter.outcomesSection.length > 0) {
    for (const section of summaryOfFindingsStarter.outcomesSection) {
      let outcomeFocus = section.focus || "";
      let outcomeTitle = section.title || outcomeFocus?.display || outcomeFocus?.reference || "Outcome Name Missing";
      let comparativeEvidenceReference;
      let comparatorOnlyEvidenceReference;
      let interventionOnlyEvidenceReference;
      if (section.section?.length > 0) {
        for (const subsection of section.section) {
          let subsectionCode = subsection?.code?.text || subsection.code?.coding?.[0]?.code;
          if (subsectionCode === "Outcome with intervention vs. comparator" || subsectionCode === "evidence-with-intervention-vs-comparator") {
            comparativeEvidenceReference = subsection.entry;
          }
          if (subsectionCode === "Outcome with intervention alone" || subsectionCode === "evidence-with-intervention-alone") {
            interventionOnlyEvidenceReference = subsection.entry;
          }
          if (subsectionCode === "Outcome with comparator alone" || subsectionCode === "evidence-with-comparator-alone") {
            comparatorOnlyEvidenceReference = subsection.entry;
          }
        }
      }
      let combinedReference;
      if (Array.isArray(comparativeEvidenceReference)) {
        combinedReference = [];
        for (const entry of comparativeEvidenceReference) {
          combinedReference.push(entry);
        }
      }
      if (Array.isArray(comparatorOnlyEvidenceReference)) {
        if (!combinedReference) {
          combinedReference = [];
        }
        for (const entry of comparatorOnlyEvidenceReference) {
          combinedReference.push(entry);
        }
      }

      let nextSection = {
        "title": outcomeTitle,
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "summary-of-findings-entry-for-a-single-outcome",
              "display": "Summary of findings entry for a single outcome"
            }
          ]
        },
        "focus": outcomeFocus,
        "section": [
          {
            "title": outcomeTitle,
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "outcome-measure",
                  "display": "Outcome Measure"
                }
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + outcomeTitle + "</div>"
            },
            "entry": [outcomeFocus]
          },
          {
            "title": "Sample Size",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "sample-size",
                  "display": "Sample Size"
                }
              ]
            },
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sample size: # studies, # participants, # counted, # events</div>"
            },
            "entry": comparativeEvidenceReference
          },
          {
            "title": "Result Without Treatment",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-comparator-alone",
                  "display": "Result with comparator alone"
                }
              ]
            },
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result Without Treatment</div>"
            },
            "entry": comparatorOnlyEvidenceReference
          },
          {
            "title": "Result With Treatment--Observed",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-intervention-alone",
                  "display": "Result with intervention alone"
                }
              ]
            },
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Observed)</div>"
            },
            "entry": interventionOnlyEvidenceReference
          },
          {
            "title": "Result With Treatment--Derived",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-intervention-alone-calculated",
                  "display": "Result with intervention alone (calculated)"
                }
              ],
              "text": "result with intervention with calculated value derived from the control group and effect estimate"
            },
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Calculated)</div>"
            },
            "entry": combinedReference
          },
          {
            "title": "Effect Estimate",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "result-with-intervention-vs-comparator",
                  "display": "Result with intervention vs. comparator"
                }
              ]
            },
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Effect Estimate (Relative effect)</div>"
            },
            "entry": comparativeEvidenceReference
          },
          {
            "title": "Certainty of Evidence",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "certainty-of-evidence",
                  "display": "Certainty of Evidence"
                }
              ]
            },
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Certainty of finding (Quality of evidence)</div>"
            },
            "entry": comparativeEvidenceReference
          },
          {
            "title": "Assertion",
            "code": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "assertion",
                  "display": "Assertion"
                }
              ]
            },
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">What this means</div>"
            },
            "entry": comparativeEvidenceReference
          }
        ]
      }
      newSummaryofFindings.section.push(nextSection);
    }
  }

  let fhirEntryString = JSON.stringify(newSummaryofFindings, null, 2);

  const body = {
    'functionid': "submitfhirresource",
    'idToken': "",
    'fhirEntry': fhirEntryString,
    'title': newSummaryofFindings.title,
    'status': 'active',
  };

  let response = await submitToFevirServer(globalContext, 5000, body, true, false);

  if (response.success) {
    return response.formstateid;
  }
};

const addFoisFromFlis = async (globalContext, targetResources) => {
  let fliList = [];
  for (let resource of targetResources) {
    if (!resource?.id && resource?.fli) {
      fliList.push(resource.fli);
    }
  }

  if (fliList.length > 0) {
    const body = {
      'functionid': 'getfoilistfromflilist',
      'fliList': fliList,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 100000, body, true, false);
    if (response?.success && response.foilist?.length && response.flitofoi) {
      for (let resource of targetResources) {
        if (!resource?.id && resource?.fli && response.flitofoi[resource.fli]) {
          resource.id = response.flitofoi[resource.fli];
        }
      }
    }
  }


  return targetResources;
}

const getTargetResourceRatingDictionary = async (targetResources, globalContext) => {
  let getBody = {
    'functionid': 'gettargetresourceratingdictionary',
    'idToken': '',
    'targetResources': targetResources,
    'userid': globalContext.userState.id, //TODO probably DELETE THIS, why is this here, this should be deleted
    'tool': 'SummaryOfFindingsAuthoringTool'
  };
  let response = await submitToFevirServer(globalContext, 50000, getBody, false, false);
  return response;
}

const submitResource = async (globalContext, fhirJson) => {
  let fhirEntryString = JSON.stringify(fhirJson, null, 2);
  const body = {
    'functionid': "submitfhirresource",
    'idToken': "",
    'fhirEntry': fhirEntryString,
    'title': fhirJson.title,
    'status': "active",
  };
  let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  if (response?.success) {
    return response.formstateid;
  }
};
/** 
const summaryOfFindingsTextViewChangesToJson = (fhirJson, summaryOfFindingsState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.resourceType = "Composition";
  fhirJson.meta = summaryOfFindingsState.meta;
  fhirJson.language = summaryOfFindingsState.language;
  fhirJson.text = summaryOfFindingsState.text;
  fhirJson.contained = summaryOfFindingsState.contained || "DELETEME";
  fhirJson.extension = summaryOfFindingsState.extension || "DELETEME";
  fhirJson.modifierExtension = summaryOfFindingsState.modifierExtension || "DELETEME";
  fhirJson.url = summaryOfFindingsState.url || "DELETEME";
  fhirJson.identifier = summaryOfFindingsState.identifier || "DELETEME";
  fhirJson.version = summaryOfFindingsState.version || "DELETEME";
  fhirJson.status = summaryOfFindingsState.status || "unknown";
  fhirJson.type = summaryOfFindingsState.type || {
    coding: [{
      system: "https://fevir.net/resources/CodeSystem/179423",
      code: "SummaryOfFindings",
      display: "SummaryOfFindings"
    }],
    text: 'Summary of Findings'
  };
  fhirJson.category = summaryOfFindingsState.category || "DELETEME";
  fhirJson.subject = summaryOfFindingsState.subject || "DELETEME";
  fhirJson.encounter = summaryOfFindingsState.encounter || "DELETEME";
  fhirJson.date = summaryOfFindingsState.date || "DELETEME";
  fhirJson.useContext = summaryOfFindingsState.useContext || "DELETEME";
  fhirJson.author = summaryOfFindingsState.author || "DELETEME";
  fhirJson.name = summaryOfFindingsState.name || "DELETEME";
  fhirJson.title = summaryOfFindingsState.title || "DELETEME";
  fhirJson.note = summaryOfFindingsState.note || "DELETEME";
  fhirJson.attester = summaryOfFindingsState.attester || "DELETEME";
  fhirJson.custodian = summaryOfFindingsState.custodian || {
    "reference": "Organization/118079",
    "type": "Organization",
    "display": "Computable Publishing LLC"
  };
  fhirJson.relatesTo = summaryOfFindingsState.relatesTo || "DELETEME";
  fhirJson.event = summaryOfFindingsState.event || "DELETEME";
  fhirJson.section = summaryOfFindingsState.section || "DELETEME";

  deleteEmptyElementsInObjectRecursive(fhirJson);
  let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
  fhirJson = fhirJsonWithCiteAs;

  return fhirJson;
}

const createNewClassificationJson = (summaryOfFindingsState) => {
  let contentArray = summaryOfFindingsState.newClassifications;
  if (!contentArray) {
    return "";
  }
  let classifiedResourceType = "Composition";
  let classifiedFoi = summaryOfFindingsState.id;
  let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
  let classifedTitle = summaryOfFindingsState.title || summaryOfFindingsState.name || "[Untitled.]";
  let classificationJson = {
    "resourceType": "ArtifactAssessment",
    "artifactReference": {
      "reference": classifiedReference,
      "type": classifiedResourceType,
      "display": classifedTitle
    },
    "content": contentArray,
    "meta": {
      "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
    }
  };
  return classificationJson;
}

const builderUpdateSummaryOfFindingsJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let summaryOfFindingsState = formInputsStateRef.current.summaryOfFindingsState;

  let fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  if (fhirEntryState.activeIndex == 0) {
    fhirJson = summaryOfFindingsTextViewChangesToJson(fhirJson, summaryOfFindingsState);
    fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  let newClassificationJson = createNewClassificationJson(summaryOfFindingsState);

  return [fhirJson, newClassificationJson];
}
*/
export {
  addFoisFromFlis, createSummaryOfFindingsReport,
  getTargetResourceRatingDictionary, submitResource
}