import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const ResearchStudyDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let searchStrategy = [];
  let systematicReviewEligibilityCriteria;
  let numberOfStudiesIdentified;
  let numberOfStudiesIncluded;
  let includedStudy = [];
  let excludedStudy = [];
  let resultAsComposition = [];
  let confidentialityStatement;
  let saeReportingMethod;
  let studyAmendment;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length) {
    for (const ext of fhirJson.extension) {
      if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-search-strategy" &&
        ext.valueReference) {
        searchStrategy.push(ext.valueReference);
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-systematic-review-eligibility-criteria" &&
        ext.valueReference) {
        systematicReviewEligibilityCriteria = ext.valueReference;
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-identified" &&
        !isNaN(ext.valueUnsignedInt)) {
        numberOfStudiesIdentified = ext.valueUnsignedInt;
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-number-of-studies-included" &&
        !isNaN(ext.valueUnsignedInt)) {
        numberOfStudiesIncluded = ext.valueUnsignedInt;
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-included-study" &&
        ext.valueReference) {
        includedStudy.push(ext.valueReference);
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-excluded-study" &&
        ext.valueReference) {
        excludedStudy.push(ext.valueReference);
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-result-as-composition" &&
        ext.valueReference) {
        resultAsComposition.push(ext.valueReference);
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sponsor-confidentiality-statement" &&
        ext.valueString) {
        confidentialityStatement = ext.valueString;
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-sae-reporting-method" &&
        ext.valueString) {
        saeReportingMethod = ext.valueString;
      } else if (ext.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-study-amendment" &&
        ext.extension) {
        studyAmendment = {};
        for (const extension of ext.extension) {
          if (extension.url === "details") {
            studyAmendment.details = extension.valueString;
          } else if (extension.url === "scope") {
            studyAmendment.scope = extension.valueString;
          } else if (extension.url === "description") {
            studyAmendment.description = extension.valueString;
          } else if (extension.url === "rationale") {
            studyAmendment.rationale = extension.valueString;
          } else if (extension.url === "substantialImpactSafety") {
            studyAmendment.substantialImpactSafety = extension.valueBoolean;
          } else if (extension.url === "substantialImpactSafetyComment") {
            studyAmendment.substantialImpactSafetyComment = extension.valueString;
          } else if (extension.url === "substantialImpactReliability") {
            studyAmendment.substantialImpactReliability = extension.valueBoolean;
          } else if (extension.url === "substantialImpactReliabilityComment") {
            studyAmendment.substantialImpactReliabilityComment = extension.valueString;
          } else if (extension.url === "primaryReason") {
            studyAmendment.primaryReason = extension.valueCodeableConcept;
          }
        }
      }
    }
  }

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact)) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  let nctid;
  if (fhirJson.identifier) {
    for (let identifierIndex in fhirJson.identifier) {
      let identifier = fhirJson.identifier[identifierIndex];
      if (identifier.system === "https://clinicaltrials.gov" && identifier.value) {
        nctid = identifier.value;
        break;
      }
    }
  }
  return <div>
    {nctid && <div style={{ width: "100%", textAlign: "center" }}>
      <b>VIEW ON ClinicalTrials.gov: <a href={"https://clinicaltrials.gov/study/" + nctid}
        target="_blank" rel="noopener noreferrer">{nctid}</a></b>
    </div>}
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") ?
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
          : <p>[Narrative summary not generated.]</p>}
      </div>
      <h3 id="identification">Identification</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
        {Array.isArray(fhirJson.label) &&
          fhirJson.label.map((label, labelIndex) => {
            let labelType = "Label";
            if (label.type) {
              labelType = getStringFromFHIR.CodeableConcept(label.type);
            }
            return <p key={labelIndex}><b>{labelType}: </b>{label.value}</p>
          })}
        {Array.isArray(fhirJson.identifier) &&
          fhirJson.identifier.map((identifier, identifierIndex) => {
            return <div key={identifierIndex}><b>Identifier: </b>{getStringFromFHIR.Identifier(identifier)}</div>
          })}
        {fhirJson.version && <p><b>Version: </b>{fhirJson.version}</p>}
        {Array.isArray(fhirJson.partOf) &&
          fhirJson.partOf.map((partOf, partOfIndex) => {
            return <div key={partOfIndex}><b>Part of: </b><DisplayFromFHIR reference={partOf} /></div>
          })}
      </div>
      <h3 id="status">Status</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.period && <p><b>Study start and end: </b>{getStringFromFHIR.Period(fhirJson.period)}</p>}
        {Array.isArray(fhirJson.progressStatus) &&
          fhirJson.progressStatus.map((progress, progressIndex) => {
            return <div key={progressIndex}><b>Progress Status: </b>
              <div style={{ marginLeft: "24px" }}>
                {progress.state && <span><b>State: </b><DisplayFromFHIR codeableConcept={progress.state} /><br /></span>}
                {progress.actual === true && <p><b>Actual</b></p>}
                {progress.actual === false && <p><b>Anticipated</b></p>}
                {progress.period && <p><b>Period: </b>{getStringFromFHIR.Period(progress.period)}</p>}
              </div>
            </div>
          })}
        {fhirJson.whyStopped && <p><b>Why stopped: </b><DisplayFromFHIR codeableConcept={fhirJson.whyStopped} /></p>}
      </div>
      <h3 id="subject">Subject</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.descriptionSummary && <div><b>Brief Description: </b><DisplayFromFHIR markdown={fhirJson.descriptionSummary} /><br /><br /></div>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {fhirJson.primaryPurposeType && <span><b>Purpose Type: </b><DisplayFromFHIR codeableConcept={fhirJson.primaryPurposeType} /><br /></span>}
        {Array.isArray(fhirJson.focus) &&
          fhirJson.focus.map((focus, focusIndex) => {
            return <div key={focusIndex}><b>Focus: </b><DisplayFromFHIR codeableReference={focus} /></div>
          })}
        {Array.isArray(fhirJson.condition) &&
          fhirJson.condition.map((condition, conditionIndex) => {
            return <div key={conditionIndex}><b>Condition: </b><DisplayFromFHIR codeableConcept={condition} /></div>
          })}
        {Array.isArray(fhirJson.keyword) &&
          fhirJson.keyword.map((keyword, keywordIndex) => {
            return <div key={keywordIndex}><b>Keyword: </b><DisplayFromFHIR codeableConcept={keyword} /></div>
          })}
        {Array.isArray(fhirJson.classifier) &&
          fhirJson.classifier.map((classifier, classifierIndex) => {
            return <div key={classifierIndex}><b>Classifier: </b><DisplayFromFHIR codeableConcept={classifier} /></div>
          })}
        {Array.isArray(fhirJson.region) &&
          fhirJson.region.map((region, regionIndex) => {
            return <div key={regionIndex}><b>Region: </b><DisplayFromFHIR codeableConcept={region} /></div>
          })}
      </div>
      <h3 id="protocol">Protocol</h3>
      <div style={{ marginLeft: "24px" }}>
        {studyAmendment && <div>
          <h4>Study Amendment</h4>
          <div style={{ marginLeft: "24px" }}>
            {studyAmendment.details && <p><b>Details: </b>{studyAmendment.details}</p>}
            {studyAmendment.scope && <p><b>Scope: </b>{studyAmendment.scope}</p>}
            {studyAmendment.description && <p><b>Description: </b>{studyAmendment.description}</p>}
            {studyAmendment.rationale && <p><b>Rationale: </b>{studyAmendment.rationale}</p>}
            {studyAmendment.substantialImpactSafety === true && <p><b>Substantial Impact on Safety: </b>YES</p>}
            {studyAmendment.substantialImpactSafety === false && <p><b>Substantial Impact on Safety: </b>No</p>}
            {studyAmendment.substantialImpactSafetyComment && <p><b>Substantial Impact on Safety Comment: </b>{studyAmendment.substantialImpactSafetyComment}</p>}
            {studyAmendment.substantialImpactReliability === true && <p><b>Substantial Impact on Reliability: </b>YES</p>}
            {studyAmendment.substantialImpactReliability === false && <p><b>Substantial Impact on Reliability: </b>No</p>}
            {studyAmendment.substantialImpactReliabilityComment && <p><b>Substantial Impact on Reliability Comment: </b>{studyAmendment.substantialImpactReliabilityComment}</p>}
            {studyAmendment.primaryReason && <p><b>Primary Reason: </b>{getStringFromFHIR.CodeableConcept(studyAmendment.primaryReason)}</p>}
          </div>
        </div>}
        {systematicReviewEligibilityCriteria && <div>
          <b>Systematic Review Eligibility Criteria: </b><DisplayFromFHIR reference={systematicReviewEligibilityCriteria} /></div>}
        {Array.isArray(searchStrategy) &&
          searchStrategy.map((protocol, protocolIndex) => {
            return <div key={protocolIndex}><b>Search Strategy: </b><DisplayFromFHIR reference={protocol} /></div>
          })}
        {fhirJson.phase && <span><b>Phase: </b><DisplayFromFHIR codeableConcept={fhirJson.phase} /><br /></span>}
        {Array.isArray(fhirJson.studyDesign) &&
          fhirJson.studyDesign.map((studyDesign, studyDesignIndex) => {
            return <div key={studyDesignIndex}><b>Study Design: </b><DisplayFromFHIR codeableConcept={studyDesign} /></div>
          })}
        {Array.isArray(fhirJson.protocol) &&
          fhirJson.protocol.map((protocol, protocolIndex) => {
            return <div key={protocolIndex}><b>Protocol: </b><DisplayFromFHIR reference={protocol} /></div>
          })}
        {saeReportingMethod && <p><b>Serious Adverse Event Reporting Method: </b>{saeReportingMethod}</p>}
        {Array.isArray(fhirJson.relatedArtifact) &&
          fhirJson.relatedArtifact.map((relatedArtifact, relatedArtifactIndex) => {
            return <div key={relatedArtifactIndex}><b>Related Item: </b><DisplayFromFHIR relatedArtifact={relatedArtifact} /></div>
          })}
        {Array.isArray(fhirJson.note) &&
          fhirJson.note.map((note, noteIndex) => {
            return <div key={noteIndex}><b>Note: </b><DisplayFromFHIR annotation={note} /></div>
          })}
      </div>
      <h3 id="organizations">Organizations</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.associatedParty) &&
          fhirJson.associatedParty.map((party, partyIndex) => {
            return <div key={partyIndex}><b>Associated Party: </b>
              <div style={{ marginLeft: "24px" }}>
                {party.name && <p><b>Name: </b>{party.name}</p>}
                {party.role && <span><b>Role: </b><DisplayFromFHIR codeableConcept={party.role} /><br /></span>}
                {Array.isArray(party.period) &&
                  party.period.map((period, periodIndex) => {
                    return <div key={periodIndex}><b>Period: </b>{getStringFromFHIR.Period(period)}</div>
                  })}
                {Array.isArray(party.classifier) &&
                  party.classifier.map((classifier, classifierIndex) => {
                    return <div key={classifierIndex}><b>Classifier: </b><DisplayFromFHIR codeableConcept={classifier} /></div>
                  })}
                {party.party && <div><b>Party: </b><DisplayFromFHIR reference={party.party} /><br /></div>}
              </div>
            </div>
          })}
        {Array.isArray(fhirJson.site) &&
          fhirJson.site.map((site, siteIndex) => {
            return <div key={siteIndex}><b>Site: </b><DisplayFromFHIR reference={site} /></div>
          })}
      </div>
      <h3 id="recruitment">Recruitment</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.recruitment?.targetNumber && <p><b>Target number of participants: </b>{fhirJson.recruitment.targetNumber}</p>}
        {fhirJson.recruitment?.actualNumber && <p><b>Actual number of participants: </b>{fhirJson.recruitment.actualNumber}</p>}
        {fhirJson.recruitment?.eligibility && <div><b>Eligibility Criteria: </b><DisplayFromFHIR reference={fhirJson.recruitment.eligibility} /><br /></div>}
        {fhirJson.recruitment?.actualGroup && <div><b>Enrollment Group: </b><DisplayFromFHIR reference={fhirJson.recruitment.actualGroup} /><br /></div>}
        {!fhirJson.recruitment && <p>No recruitment data.</p>}
      </div>
      <h3 id="variables">Variables</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.comparisonGroup) &&
          fhirJson.comparisonGroup.map((exposure, exposureIndex) => {
            return <div key={exposureIndex}><b>Comparison Group: </b>
              <div style={{ marginLeft: "24px" }}>
                {exposure.linkId && <p><b>Id: </b>{exposure.linkId}</p>}
                {exposure.name && <p><b>Name: </b>{exposure.name}</p>}
                {exposure.type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={exposure.type} /><br /></span>}
                {exposure.description && <div><b>Description: </b><DisplayFromFHIR markdown={exposure.description} /><br /><br /></div>}
                {Array.isArray(exposure.intendedExposure) &&
                  exposure.intendedExposure.map((intended, intendedIndex) => {
                    return <div key={intendedIndex}><b>Exposure: </b><DisplayFromFHIR reference={intended} /></div>
                  })}
                {exposure.observedGroup && <span><b>Observed Group: </b><DisplayFromFHIR reference={exposure.observedGroup} /><br /></span>}
              </div>
            </div>
          })}
        {Array.isArray(fhirJson.objective) &&
          fhirJson.objective.map((objective, objectiveIndex) => {
            return <div key={objectiveIndex}><b>Objective: </b>
              <div style={{ marginLeft: "24px" }}>
                {objective.name && <p><b>Name: </b>{objective.name}</p>}
                {objective.type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={objective.type} /><br /></span>}
                {objective.description && <div><b>Description: </b><DisplayFromFHIR markdown={objective.description} /><br /><br /></div>}
                {Array.isArray(objective.extension) &&
                  objective.extension.map((extension, extensionIndex) => {
                    if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-estimand") {
                      let label;
                      let type
                      let endpoint;
                      let population;
                      let intervention;
                      let comparator;
                      let summaryMeasure;
                      let eventHandling = [];
                      for (const ext of extension.extension) {
                        if (ext.url === "label") {
                          label = ext.valueString;
                        } else if (ext.url === "type") {
                          type = ext.valueCodeableConcept;
                        } else if (ext.url === "endpoint") {
                          endpoint = ext.valueReference;
                        } else if (ext.url === "population") {
                          population = ext.valueReference;
                        } else if (ext.url === "interventionGroup" || ext.url === "intervention") {
                          intervention = ext.valueId;
                        } else if (ext.url === "comparatorGroup" || ext.url === "comparator") {
                          comparator = ext.valueId;
                        } else if (ext.url === "summaryMeasure") {
                          summaryMeasure = ext.valueCodeableConcept;
                        } else if (ext.url === "eventHandling") {
                          eventHandling.push(ext);
                        }
                      }
                      return <div key={extensionIndex} >
                        <h4>Estimand {extensionIndex + 1}</h4>
                        <div style={{ marginLeft: "24px" }}>
                          {label && <p><b>Label: </b>{label}</p>}
                          {type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={type} /><br /></span>}
                          {endpoint && <span><b>Endpoint: </b><DisplayFromFHIR reference={endpoint} /><br /></span>}
                          {population && <span><b>Population: </b><DisplayFromFHIR reference={population} /><br /></span>}
                          {intervention && <p><b>Intervention group: </b>Comparison group {intervention}</p>}
                          {comparator && <p><b>Comparator group: </b>Comparison group {comparator}</p>}
                          {summaryMeasure && <span><b>Summary measure: </b><DisplayFromFHIR codeableConcept={summaryMeasure} /><br /></span>}
                          {Array.isArray(eventHandling) &&
                            eventHandling.map((ice, iceIndex) => {
                              let event;
                              let group;
                              let handling;
                              let description;
                              for (const extension of ice.extension) {
                                if (extension.url === "event") {
                                  event = extension.valueCodeableConcept;
                                } else if (extension.url === "group") {
                                  group = extension.valueCodeableConcept;
                                } else if (extension.url === "handling") {
                                  handling = extension.valueCodeableConcept;
                                } else if (extension.url === "description") {
                                  description = extension.valueMarkdown;
                                }
                              }
                              return <div key={iceIndex}>
                                <h4>Handling of Intercurrent Event {ice.id || (iceIndex + 1)}</h4>
                                <div style={{ marginLeft: "24px" }}>
                                  {event && <span><b>Event: </b><DisplayFromFHIR codeableConcept={event} /><br /></span>}
                                  {group && <span><b>Group: </b><DisplayFromFHIR codeableConcept={group} /><br /></span>}
                                  {handling && <span><b>Handling: </b><DisplayFromFHIR codeableConcept={handling} /><br /></span>}
                                  {description && <span><b>Description: </b><DisplayFromFHIR markdown={description} /><br /></span>}
                                </div>
                              </div>
                            })}
                        </div>
                      </div>
                    } else {
                      return <></>;
                    }
                  })}
              </div>
            </div>
          })}
        {Array.isArray(fhirJson.outcomeMeasure) &&
          fhirJson.outcomeMeasure.map((outcome, outcomeIndex) => {
            return <div key={outcomeIndex}><b>Outcome Measure: </b>
              <div style={{ marginLeft: "24px" }}>
                {outcome.name && <p><b>Name: </b>{outcome.name}</p>}
                {Array.isArray(outcome.type) &&
                  outcome.type.map((type, typeIndex) => {
                    return <div key={typeIndex}><b>Type: </b><DisplayFromFHIR codeableConcept={type} /></div>
                  })}
                {outcome.description && <div><b>Description: </b><DisplayFromFHIR markdown={outcome.description} /><br /><br /></div>}
                {outcome.reference && <span><b>Outcome Definition: </b><DisplayFromFHIR reference={outcome.reference} /><br /></span>}
              </div>
            </div>
          })}
      </div>
      <h3 id="results">Results</h3>
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(fhirJson.result) || !isNaN(numberOfStudiesIdentified) ||
          !isNaN(numberOfStudiesIncluded) || includedStudy.length || excludedStudy.length ||
          resultAsComposition.length) ? <>
          {!isNaN(numberOfStudiesIdentified) &&
            <p><b>Number of studies identified: </b>{numberOfStudiesIdentified}</p>}
          {!isNaN(numberOfStudiesIncluded) &&
            <p><b>Number of studies included: </b>{numberOfStudiesIncluded}</p>}
          {Array.isArray(includedStudy) &&
            includedStudy.map((result, resultIndex) => {
              return <div key={resultIndex}><b>Included study: </b><DisplayFromFHIR reference={result} /></div>
            })}
          {Array.isArray(excludedStudy) &&
            excludedStudy.map((result, resultIndex) => {
              return <div key={resultIndex}><b>Excluded study: </b><DisplayFromFHIR reference={result} /></div>
            })}
          {Array.isArray(resultAsComposition) &&
            resultAsComposition.map((result, resultIndex) => {
              return <div key={resultIndex}><b>Result: </b><DisplayFromFHIR reference={result} /></div>
            })}
          {Array.isArray(fhirJson.result) &&
            fhirJson.result.map((result, resultIndex) => {
              return <div key={resultIndex}><b>Result: </b><DisplayFromFHIR reference={result} /></div>
            })}
        </> : <p>No results</p>}
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {confidentialityStatement && <p><b>Sponsor Confidentiality Statement: </b>{confidentialityStatement}</p>}
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ResearchStudyDisplay;