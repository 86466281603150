import React, { useState, useEffect, useImperativeHandle } from 'react';
import { AssociatedResourcesDisplay, DisplayFromFHIR, SimpleResourceFieldViewer, submitUpdatedFhirResource } from './ResourceFunctions';
import { DataEntry, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import CompositionSectionDataEntry from './CompositionSectionDataEntry';
import createCompositionTableDict from './createCompositionTableDict';
import { Button, Table, Modal } from 'semantic-ui-react';
import { EvidenceReportMetadataPatternEdit } from './EvidenceReportMetadataDataEntry';

const AddTableRowModal = ({ startingValue, setResourceState }) => {

  let failOut = false;

  let columnTitles = [];
  let columnHeaders = {};
  let columnCodes = [];
  if (Array.isArray(startingValue) && Array.isArray(startingValue[0]?.section) &&
    startingValue[0]?.code?.coding?.[0]?.code.toLowerCase() === "column-headers") {
    columnTitles = startingValue[0].section.map(section => section.title);
    columnCodes = startingValue[0].section.map(section => section.code);
    for (const section of startingValue[0].section) {
      columnHeaders[section.title] = section.text.div;
    }
  } else {
    alert("The Composition does not have an initial section coded for column headers.");
    failOut = true;
  }

  let tableRowSectionCode;
  if (Array.isArray(startingValue) && startingValue[1]?.code) {
    tableRowSectionCode = startingValue[1].code;
  } else {
    alert("The Composition does not have a content section with a code for the row.");
    failOut = true;
  }

  let startingNewRowSections = [];
  for (let i in columnTitles) {
    let tableCellSection = {
      "title": columnTitles[i],
      "code": columnCodes[i],
      "text": {
        "status": "additional",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
      },
      "entry": []
    }
    startingNewRowSections.push(tableCellSection);
  }

  const [addTableRowModalState, setAddTableRowModalState] = useState({
    "modalOpen": true,
    "newRowTitle": "",
    "newRowFocus": "",
    "newRowSections": startingNewRowSections
  });

  const submitNewRowChange = () => {
    if (addTableRowModalState.newRowTitle) {
      let newRow = {
        "title": addTableRowModalState.newRowTitle,
        "code": tableRowSectionCode,
        "focus": addTableRowModalState.newRowFocus || { "display": "[No data]" },
        "section": addTableRowModalState.newRowSections
      }
      let newSection = JSON.parse(JSON.stringify(startingValue));
      newSection.push(newRow);
      setResourceState(prevState => { return { ...prevState, "section": newSection, "sectionChanged": true }; });
      setAddTableRowModalState(prevState => { return { ...prevState, modalOpen: false }; });
    }
  }

  if (failOut) {
    return <></>
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setAddTableRowModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Define the Table Row</h3>
    <br />
    <DataEntry datatype='string' elementName='newRowTitle' fieldLabel='Title'
      startingValue={addTableRowModalState.newRowTitle} setResourceState={setAddTableRowModalState} />
    <br /><br />
    <h3>Set the Resource Reference for the Focus</h3>
    <br />
    <DataEntry datatype='Reference' elementName='newRowFocus'
      fieldLabel='Focus (Resource Reference)' startCollapsed
      startingValue={addTableRowModalState.newRowFocus} setResourceState={setAddTableRowModalState} />
    <br /><br />
    <h3>Add Table Cell Content</h3>
    <br />
    <DataEntry asArray={true} datatype='CompositionTableCell' elementName='newRowSections'
      columnHeaders={columnHeaders}
      startingValue={addTableRowModalState.newRowSections} setResourceState={setAddTableRowModalState} />
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Changes" positive
      onClick={submitNewRowChange}
    />
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={addTableRowModalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
}

const EditTableCellModal = ({ item, itemIndex, entryIndex, startingValue, setResourceState }) => {
  let startingEditModalState = {
    "modalOpen": true, "textStatus": "", "textDiv": "", "entryList": []
  };

  if (startingValue && startingValue[entryIndex + 1] &&
    startingValue[entryIndex + 1]["section"] &&
    startingValue[entryIndex + 1]["section"][itemIndex]) {
    let startingSubsection = startingValue[entryIndex + 1]["section"][itemIndex];
    startingEditModalState.textStatus = startingSubsection.text?.status;
    startingEditModalState.textDiv = startingSubsection.text?.div || "";
    startingEditModalState.entryList = startingSubsection.entry ?? [];
  }

  const [editModalState, setEditModalState] = useState(startingEditModalState);

  const submitChange = () => {
    let newSubsection = {};
    try {
      newSubsection = startingValue[entryIndex + 1]["section"][itemIndex];
    } catch { }
    newSubsection.text = {
      "status": "additional",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + editModalState.textDiv + "</div>"
    };
    if (Array.isArray(editModalState.entryList) && editModalState.entryList.length > 0) {
      newSubsection.entry = editModalState.entryList;
    } else {
      delete newSubsection.entry;
    }
    let newSection = JSON.parse(JSON.stringify(startingValue));
    newSection[entryIndex + 1]["section"][itemIndex] = newSubsection;
    setResourceState(prevState => { return { ...prevState, "section": newSection, "sectionChanged": true }; });
    setEditModalState(prevState => { return { ...prevState, modalOpen: false }; });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setEditModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Edit Table Cell Content</h3>
    <br />
    <DataEntry datatype='string' elementName='textDiv' fieldLabel='Narrative Summary'
      startingValue={editModalState.textDiv} setResourceState={setEditModalState} />
    <br /><br />
    <h3>Change Resource References for the Section</h3>
    <br />
    <DataEntry asArray={true} datatype='Reference' elementName='entryList'
      fieldLabel='Resource References' startCollapsed
      startingValue={editModalState.entryList} setResourceState={setEditModalState} />
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Changes" positive
      onClick={submitChange}
    />
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={editModalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
}

const CompositionEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  classificationsArrayState, classificationsLoadedState,
  fhirEntryState, setFhirEntryState, globalContext, history, changeFormState }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "status", "type", "category", "subject", "encounter",
      "date", "useContext", "author", "name", "title", "note", "attester", "custodian", "relatesTo",
      "event", "section"]
  */
  if (Array.isArray(fhirJson.author) && fhirJson.author.length === 1 && fhirJson.author[0].name) {
    fhirJson.author[0].display = fhirJson.author[0].name;
    delete fhirJson.author[0].name;
  }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "status": fhirJson.status,
    "type": fhirJson.type, "category": fhirJson.category, "subject": fhirJson.subject, "encounter": fhirJson.encounter,
    "date": fhirJson.date, "useContext": fhirJson.useContext, "author": fhirJson.author,
    "name": fhirJson.name, "title": fhirJson.title, "note": fhirJson.note, "attester": fhirJson.attester,
    "custodian": fhirJson.custodian, "relatesTo": fhirJson.relatesTo, "event": fhirJson.event,
    "section": fhirJson.section, "newClassifications": null
  });

  const [openEditModalState, setOpenEditModalState] = useState({ "openModal": false });
  const [openAddTableRowModalState, setOpenAddTableRowModalState] = useState({ "openModal": false });

  useImperativeHandle(formInputsStateRef, () => ({
    compositionState: resourceState
  }), [resourceState]);

  useEffect(() => {
    if (resourceState.sectionChanged === true) {
      submitUpdatedFhirResource(null, "Composition", fhirJson.id, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
    }
  }, [resourceState.section]);

  let tableData = createCompositionTableDict(fhirJson);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportMetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="table-view">Table View</h3>
      {openEditModalState.openModal &&
        <EditTableCellModal item={openEditModalState.item}
          itemIndex={openEditModalState.itemIndex}
          entryIndex={openEditModalState.entryIndex}
          startingValue={resourceState.section}
          setResourceState={setResourceState} />}
      {openAddTableRowModalState.openModal &&
        <AddTableRowModal startingValue={resourceState.section}
          setResourceState={setResourceState} />}
      {(tableData?.Labels && tableData.Data && Object.keys(tableData.Labels).length > 0) ?
        <Table>
          <Table.Header>
            <Table.Row>
              {Object.keys(tableData.Labels).map((label, labelIndex) => {
                return <Table.HeaderCell key={labelIndex}>
                  <DisplayFromFHIR xhtml={label} />
                </Table.HeaderCell>
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(tableData.Data).map((entry, entryIndex) => {
              return <Table.Row key={entryIndex}>{
                Object.keys(tableData.Data[entry]).map((item, itemIndex) => {
                  return <Table.Cell key={itemIndex}>
                    <div>
                      <span className={"unselectable"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpenEditModalState({ openModal: true, item: item, itemIndex: itemIndex, entryIndex: entryIndex });
                        }}>✎ Edit</span>
                      <br />
                      <span>
                        <DisplayFromFHIR xhtml={tableData.Data[entry][item]} />
                      </span>
                    </div>
                  </Table.Cell>
                })
              }</Table.Row>
            })}
            <Table.Row>
              <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
                content="+ Add Table Row" positive
                onClick={() => {
                  setOpenAddTableRowModalState({ openModal: true });
                }}
              />
            </Table.Row>
          </Table.Body>
        </Table>
        :
        <>No tabular data.</>
      }
      <h3 id="section-definition">Section Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        {resourceState.meta.profile
          ?
          <CompositionSectionDataEntry startingValue={resourceState.section} setResourceState={setResourceState}
            profile={resourceState.meta.profile} />
          :
          <p>Placeholder for future development.</p>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default CompositionEdit;
