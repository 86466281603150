import React, { useState } from 'react';
import "firebase/compat/auth";
import scrollTo from './ScrollTo';
import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';

let separatorCharacter = ": ";

const TitleComponentDisplay = ({ component }) => {
  return <div>
    <br />
    <p><b>Title of this Adaptation Resource: </b>{component.summary}</p>
  </div>
};

const OriginalResourceContentComponentDisplay = ({ component, datatypeHandling }) => {
  return <div>
    <br />
    <p><b>{component.type.coding ? (component.type.coding[0].display || component.type.coding[0].code) : component.type.text}:</b></p>
    {(component.summary && datatypeHandling !== 'manageAsCoding') && <DisplayFromFHIR markdown={component.summary} />}
    {component.component && <div>
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} datatypeHandling={datatypeHandling} />
      }
    </div>}
  </div>
};

const ComparedResourceContentComponentDisplay = ({ component, datatypeHandling }) => {
  return <div>
    <br />
    <p><b>{component.type.coding ? (component.type.coding[0].display || component.type.coding[0].code) : component.type.text}:</b></p>
    {component.summary && <DisplayFromFHIR markdown={component.summary} />}
    {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
      <div style={{ marginLeft: "24px" }}>
        <p><b>Datatype: </b>
          {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
          {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
        </p>
      </div>}
    {component.component && <div>
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} datatypeHandling={datatypeHandling} />
      }
    </div>}
  </div>
};

const ComparisonOfResourcesComponentDisplay = ({ component }) => {
  if (!component.summary && !component.classifier?.length > 0) {
    return <></>
  }
  return <div>
    <br />
    <p><b>{component.type.coding ? (component.type.coding[0].display || component.type.coding[0].code) : component.type.text}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Comparison Rating: </b>
            {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
            {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {component.summary && <><br />
        <DisplayFromFHIR markdown={component.summary} />
      </>}
      <br />
      {(Array.isArray(component.relatedArtifact) && component.relatedArtifact.length > 0) &&
        component.relatedArtifact.map((relatedItem, relatedItemIndex) => {
          return <div key={relatedItemIndex}>
            <p><b>Related Item {relatedItemIndex + 1}: </b></p>
            <div style={{ marginLeft: "24px" }}>
              <DisplayFromFHIR relatedArtifact={relatedItem} />
            </div>
          </div>
        })
      }
      {component.component && <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(component.component) && component.component.length > 0) &&
          <ContentDisplay contentArray={component.component} />
        }
      </div>}
    </div>
  </div>
};

const RationaleForChangeComponentDisplay = ({ component }) => {
  if (!component.summary && !(component.classifier?.length > 0)) {
    return <></>
  }
  return <div>
    <br />
    <p><b>{component.type.coding ? (component.type.coding[0].display || component.type.coding[0].code) : component.type.text}:</b></p>
    <DisplayFromFHIR markdown={component.summary} />
    <br /><br />
    <div style={{ marginLeft: "24px" }}>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Is change suggested? </b>
            {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
            {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {(Array.isArray(component.relatedArtifact) && component.relatedArtifact.length > 0) &&
        component.relatedArtifact.map((relatedItem, relatedItemIndex) => {
          return <div key={relatedItemIndex}>
            <br />
            <p><b>Related Item {relatedItemIndex + 1}: </b></p>
            <div style={{ marginLeft: "24px" }}>
              <DisplayFromFHIR relatedArtifact={relatedItem} />
            </div>
          </div>
        })
      }
      {component.component && <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(component.component) && component.component.length > 0) &&
          <ContentDisplay contentArray={component.component} />
        }
      </div>}
    </div>
  </div>
};

const SuggestedChangeComponentDisplay = ({ component, datatypeHandling }) => {
  if (!component.summary) {
    return <></>
  }
  return <div>
    <br />
    <p><b>{component.type.coding ?
      (component.type.coding[0].display || component.type.coding[0].code)
      :
      component.type.text}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(component.summary && typeof (component.summary) === 'string') &&
        <DisplayFromFHIR markdown={component.summary} />}
      {(component.summary && typeof (component.summary) === 'object') &&
        <>
          {datatypeHandling === 'manageAsCoding' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((coding) => { return getStringFromFHIR.Coding(coding) }).join('; ')
              :
              <DisplayFromFHIR coding={component.summary} />
            }
          </>}
          {datatypeHandling === 'manageAsCodeableConcept' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((codeableConcept) => { return getStringFromFHIR.CodeableConcept(codeableConcept) }).join('; ')
              :
              <DisplayFromFHIR codeableConcept={component.summary} />
            }
          </>}
          {datatypeHandling === 'manageAsIdentifier' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((identifier) => { return getStringFromFHIR.Identifier(identifier) }).join('; ')
              :
              <>{getStringFromFHIR.Identifier(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsQuantity' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Quantity(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Quantity(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsReference' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((reference) => { return getStringFromFHIR.Reference(reference) }).join('; ')
              :
              <DisplayFromFHIR reference={component.summary} />
            }
          </>}
          {datatypeHandling === 'manageAsRelatedArtifact' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((object, objectIndex) => {
                return <span key={objectIndex}><DisplayFromFHIR relatedArtifact={object} /></span>
              })
              :
              <DisplayFromFHIR relatedArtifact={component.summary} />
            }
          </>}
          {datatypeHandling === 'manageAsAddress' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Address(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Address(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsAnnotation' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Annotation(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Annotation(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsContactDetail' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((object, objectIndex) => {
                return <span key={objectIndex}><DisplayFromFHIR contactDetail={object} /></span>
              })
              :
              <DisplayFromFHIR contactDetail={component.summary} />
            }
          </>}
          {datatypeHandling === 'manageAsDosage' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Dosage(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Dosage(component.summary)}</>}
          </>}
          {datatypeHandling === 'manageAsExpression' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Expression(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Expression(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsHumanName' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.HumanName(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.HumanName(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsPeriod' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Period(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Period(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsRange' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Range(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Range(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsRatio' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Ratio(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Ratio(component.summary)}</>
            }
          </>}
          {datatypeHandling === 'manageAsTiming' && <>
            {Array.isArray(component.summary) ?
              component.summary.map((quantity) => { return getStringFromFHIR.Timing(quantity) }).join('; ')
              :
              <>{getStringFromFHIR.Timing(component.summary)}</>
            }
          </>}
          <br />
          <div style={{ marginLeft: "24px" }}>
            <br />
            <p><b>JSON: </b></p>
            {JSON.stringify(component.summary, null, 2)}
          </div>
        </>
      }
      <br /><br />
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
          <div>
            <p><b>Accept or reject? </b>
              {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
              {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
            </p>
          </div>}
      </div>
    </div>
  </div>
};

const PrimitiveComponentDisplay = ({ component }) => {
  return <div>
    <br />
    <div>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Datatype: </b>
            {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
            {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} />
      }
    </div>
  </div>
};

const CodingComponentDisplay = ({ component, datatypeHandling }) => {
  return <div>
    <br />
    <div>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Coding Object or Array? </b>
            {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
            {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} datatypeHandling={datatypeHandling} />
      }
    </div>
  </div>
};

const ComplexComponentDisplay = ({ component, datatypeHandling }) => {
  if (Array.isArray(component.classifier) && component.classifier.length > 0 && component.classifier[0] &&
    Array.isArray(component.classifier[0].coding) && component.classifier[0].coding.length > 0) {
    datatypeHandling = component.classifier[0].coding[0].code;
  }
  return <div>
    <br />
    <div>
      {(Array.isArray(component.classifier) && component.classifier.length > 0) &&
        <div>
          <p><b>Datatype: </b>
            {component.classifier.length === 1 && <DisplayFromFHIR codeableConcept={component.classifier[0]} />}
            {component.classifier.length > 1 && component.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
          </p>
        </div>}
      {(Array.isArray(component.component) && component.component.length > 0) &&
        <ContentDisplay contentArray={component.component} datatypeHandling={datatypeHandling} />
      }
    </div>
  </div>
};

const ComponentDisplay = ({ content, contentIndex, datatypeHandling }) => {

  const [originalConceptJsonExpandedState, setOriginalConceptJsonExpandedState] = useState(false);

  if (!content || typeof (content) !== "object") {
    return <></>
  }

  let contentType;
  if (content.type) {
    if (Array.isArray(content.type.coding) && content.type.coding.length > 0) {
      contentType = content.type.coding[0].display || content.type.coding[0].code;
    } else if (content.type.text) {
      contentType = content.type.text;
    }
  }

  if (contentType === "Title of Adaptation Resource" || contentType === "ResourceTitle" || contentType === "Title for Adaptation Resource") {
    return <TitleComponentDisplay component={content} />
  }
  if (contentType === "Primitive Element" || contentType === "PrimitiveElement" || contentType === "Editable Resource Content Element") {
    return <PrimitiveComponentDisplay component={content} />
  }
  if (contentType === "Coding Element" || contentType === "CodingElement" || contentType === "Editable Resource Content Coding") {
    return <CodingComponentDisplay component={content} datatypeHandling='manageAsCoding' />
  }
  if (contentType === "Array Element" || contentType === "ArrayElement" || contentType === "Object Element" || contentType === "ObjectElement" || contentType === "Complex Resource Content Element") {
    return <ComplexComponentDisplay component={content} datatypeHandling={datatypeHandling} />
  }
  if (contentType === "Original Resource Content" || contentType === "OriginalResourceContent") {
    return <OriginalResourceContentComponentDisplay component={content} datatypeHandling={datatypeHandling} />
  }
  if (contentType === "Compared Resource Content" || contentType === "ComparedResourceContent") {
    return <ComparedResourceContentComponentDisplay component={content} datatypeHandling={datatypeHandling} />
  }
  if (contentType === "Comparison of Resources Content" || contentType === "ComparisonOfResources") {
    return <ComparisonOfResourcesComponentDisplay component={content} />
  }
  if (contentType === "Rationale for Change to Resource Content" || contentType === "RationaleForChange") {
    return <RationaleForChangeComponentDisplay component={content} />
  }
  if (contentType === "Suggested Change to Resource Content" || contentType === "SuggestedChange") {
    return <SuggestedChangeComponentDisplay component={content} datatypeHandling={datatypeHandling} />
  }

  if (contentType === "Original Resource Content JSON" || contentType === "OriginalResourceContentJSON") {
    return <div style={{ marginLeft: "24px" }}>
      {(!!content.summary && datatypeHandling === 'manageAsCoding') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Coding(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Coding(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsIdentifier') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Identifier(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Identifier(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsReference') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Reference(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Reference(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsQuantity') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Quantity(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Quantity(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsAddress') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Address(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Address(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsAnnotation') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Annotation(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Annotation(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsContactDetail') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}>
              <DisplayFromFHIR contactDetail={object} />
              <br />
            </span>
          })
          :
          <DisplayFromFHIR contactDetail={JSON.parse(content.summary)} />
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsDosage') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Dosage(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Dosage(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsExpression') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Expression(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Expression(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsHumanName') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.HumanName(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.HumanName(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsPeriod') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Period(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Period(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsRange') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Range(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Range(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsRatio') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Ratio(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Ratio(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsTiming') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.Timing(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.Timing(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsCodeableConcept') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><p>{getStringFromFHIR.CodeableConcept(object)}</p></span>
          })
          :
          <p>{getStringFromFHIR.CodeableConcept(JSON.parse(content.summary))}</p>
        }
      </>}
      {(!!content.summary && datatypeHandling === 'manageAsRelatedArtifact') && <>
        <br />
        {Array.isArray(JSON.parse(content.summary))
          ?
          JSON.parse(content.summary).map((object, objectIndex) => {
            return <span key={objectIndex}><DisplayFromFHIR relatedArtifact={object} /></span>
          })
          :
          <DisplayFromFHIR relatedArtifact={JSON.parse(content.summary)} />
        }
      </>}
      {((datatypeHandling === 'manageAsBackboneElement' || !datatypeHandling) && !!content.summary &&
        Array.isArray(JSON.parse(content.summary))) && <>{
          JSON.parse(content.summary).length === 1 ?
            <p>[1 entry]</p>
            :
            <p>[{JSON.parse(content.summary).length} entries]</p>
        }</>
      }
      <br />
      <span>
        <b>Original Resource Content JSON:</b>
        {originalConceptJsonExpandedState
          ?
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => {
              setOriginalConceptJsonExpandedState(!originalConceptJsonExpandedState);
            }}
          >
            &nbsp;&nbsp;&nbsp;  ▼ collapse
          </span>
          :
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => {
              setOriginalConceptJsonExpandedState(!originalConceptJsonExpandedState);
            }}
          >
            &nbsp;&nbsp;&nbsp;  ► expand
          </span>
        }
      </span>
      <br /><br />
      {(!!content.summary && originalConceptJsonExpandedState) && <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
        {JSON.stringify(JSON.parse(content.summary), null, 2)}
      </div>}
      <br />
    </div>
  }

  if (contentType === "Contained Element Adaptation" || contentType === "ContainedElementAdaptation") {
    if (!content.component) {
      return <div>
        <br /><br />
        Not expanded for Contained Element Adaptation
      </div>
    }
    return <div>
      <br /><br />
      Use the Detail Navigation (on the left) to view Contained Element Adaptation
    </div>
  }
  return <div>
    <p><b>Adaptation Component {contentIndex + 1}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      {content.path && <><p><b>Specific to: </b>{content.path.toString()}</p></>}
      {content.type && <><br /><span><b>Type: </b><DisplayFromFHIR codeableConcept={content.type} /></span><br /></>}
      {content.summary && <DisplayFromFHIR markdown={content.summary} />}
      {Array.isArray(content.classifier) &&
        content.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ')}
      {content.author && <><p><b>Adaptation Author: </b>{getStringFromFHIR.Reference(content.author)}</p></>}
      {content.freeToShare === true && <p>Free to share.</p>}
      {content.freeToShare === false && <p>NOT free to share.</p>}
      {Array.isArray(content.relatedArtifact) &&
        content.relatedArtifact.map((relatedItem, relatedItemIndex) => {
          return <div key={relatedItemIndex}>
            <p><b>Related Item {relatedItemIndex + 1}: </b></p>
            <div style={{ marginLeft: "24px" }}>
              <DisplayFromFHIR relatedArtifact={relatedItem} />
            </div>
          </div>
        })
      }
      {Array.isArray(content.component) &&
        content.component.map((content, contentIndex) => {
          return <div key={contentIndex}>
            <p><b>{content.path?.toString()} Adaptation Component {contentIndex + 1}: </b></p>
            <ComponentDisplay content={content} />
          </div>
        })}
    </div></div>
};

const ContentDisplay = ({ contentArray, datatypeHandling }) => {
  if (Array.isArray(contentArray) && contentArray.length > 0) {
    return <div>{contentArray.map((content, contentIndex) => {
      return <div key={contentIndex}>
        <ComponentDisplay content={content} contentIndex={contentIndex} datatypeHandling={datatypeHandling} />
      </div>
    })}</div>
  } else {
    return <></>
  }
};

const ComparisonDetailDisplay = ({ resourceId, fhirEntryState, setFhirEntryState, history }) => {

  let componentJson = fhirEntryState.characteristicSelected;

  let conceptPath = fhirEntryState.conceptPath;
  let filteredConceptPath = [];
  if (conceptPath) {
    for (const entry of conceptPath) {
      if (!filteredConceptPath.includes(entry.toString())) {
        filteredConceptPath.push(entry.toString());
      }
    }
  }
  let conceptPathIndexes = fhirEntryState.conceptPathIndexes;
  let conceptPathString = fhirEntryState.conceptPathString;

  let aDdatatypeHandling;
  if (componentJson?.classifier?.[0]?.coding?.length > 0) {
    aDdatatypeHandling = componentJson.classifier[0].coding[0].code;
  }

  let separator = "";
  if (conceptPathString) {
    separator = separatorCharacter;
  }

  const [conceptJsonExpandedState, setConceptJsonExpandedState] = useState(false);

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          <h2 style={{ margin: "0px" }}>{fhirEntryState.conceptName}</h2>
          <ComponentDisplay content={componentJson} contentIndex={null}
            datatypeHandling={aDdatatypeHandling} />
          <br />
          <span style={{ whiteSpace: "pre-wrap" }}>
            <br /><br />
            <>
              <span>
                <a onClick={() => {
                  setFhirEntryState(prevState => {
                    return {
                      ...prevState, conceptName: "", conceptCode: "", conceptDefinition: undefined,
                      conceptPath: undefined, conceptPathIndexes: undefined, conceptPathString: undefined,
                      characteristicSelected: undefined, newChildConcepts: []
                    }
                  });
                  scrollTo("navigationCharacteristicdetailSegment", "", true);
                  history.push(`/resources/ArtifactAssessment/${resourceId}`);
                }}>
                  TOP
                </a>
              </span>
              <br />
              <span style={{ paddingLeft: "8px" }}>
                {filteredConceptPath?.map((parentConceptDisplay, parentConceptIndex) => {
                  let parentConceptPath = filteredConceptPath.slice(0, parentConceptIndex + 1);
                  let parentConceptPathIndexes = conceptPathIndexes.slice(0, parentConceptIndex + 1);
                  let parentConceptPathString = filteredConceptPath.join(separator);
                  return <span key={parentConceptIndex} style={{ paddingLeft: parentConceptIndex * 24 + "px" }}>
                    <span>
                      {parentConceptIndex + 1 === filteredConceptPath.length ?
                        <b>{parentConceptDisplay}</b>
                        :
                        <a onClick={() => {
                          if (fhirEntryState.termChanged !== true) {
                            let scrollToId = "characteristicdetail-navigation-code-" + componentJson?.path[0];
                            if (parentConceptPathIndexes.length === 1 && parseInt(parentConceptPathIndexes[0]) == 0) {
                              scrollToId = "";
                            }
                            scrollTo("navigationCharacteristicDetailSegment", scrollToId, true); //TODO to do
                            scrollTo("resourceTextViewSegment", "", true);
                          }

                          setFhirEntryState(prevState => {
                            return {
                              ...prevState,
                              conceptName: parentConceptDisplay,
                              conceptPath: parentConceptPath,
                              conceptPathIndexes: parentConceptPathIndexes,
                              conceptPathString: parentConceptPathString,
                              characteristicSelected: componentJson,
                              newChildConcepts: []
                            };
                          })
                        }} >
                          {parentConceptDisplay}
                        </a>
                      }
                    </span>
                    <br />
                  </span>
                })}
              </span>
            </>
            <br />
          </span>
          <br />
          <span>
            <b>JSON</b>
            <span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => { setConceptJsonExpandedState(!conceptJsonExpandedState) }}>
              {conceptJsonExpandedState ? <>▼</> : <>►</>}
            </span>
          </span>
          <br />
          {conceptJsonExpandedState &&
            <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
              {JSON.stringify(componentJson, null, 2)}
            </div>
          }
        </>
        :
        <>
          <span>Please select a component</span>
          <br />
          <br />
        </>
      }
    </div>
  </div>
}

export { ComparisonDetailDisplay, ComponentDisplay, ContentDisplay };