import { clearDivWrapper } from './ResourceFunctions';

/***************************************************************************
This function creates a Table View defined by Composition which contains
section.code.coding.code = 'column-headers'.
**************************************************************************/
/*
NOTE THE text.div CONTENT IS MISSING <div> SURROUNDING ELEMENTS IN THIS EXAMPLE
const Composition = {
    "resourceType": "Composition",
    "id": "52356",
    "meta": {
        "versionId": "22"
    },
    "url": "https://fevir.net/resources/Composition/52356",
    "status": "active",
    "type": {
        coding: [{
          system: "https://fevir.net/resources/CodeSystem/179423",
          code: "SummaryOfFindings",
          display: "SummaryOfFindings"
        }],
        text: 'Summary of Findings'
      },
    "subject": {
        "reference": "EvidenceVariable/50137",
        "type": "EvidenceVariable",
        "display": "EvidenceReportSubject: Clinical Outcomes Therapeutic vs Prophylactic Anticoagulation Hospitalized COVID-19 Non-critically ill from RCTs"
    },
    "date": "2022-08-11T14:29:22.297Z",
    "useContext": [
        {
            "code": {
                "system": "http://terminology.hl7.org/CodeSystem/usage-context-type",
                "code": "program",
                "display": "Program"
            },
            "valueCodeableConcept": {
                "text": "Post-publication Review of Anticoagulation for non-critically ill patients hospitalized for COVID-19"
            }
        }
    ],
    "title": "Anticoagulation for Non-critically ill COVID-19 Summary of Findings from RCTs Table EvidenceReport - CONVERTED TO COMPOSITION RESOURCE without TABLE CODING",
    "note": [
        {
            "text": "An evidence report summarizing selected clinical outcomes from a synthesis of randomized trials of therapeutic-dose vs. prophylactic-dose heparin in treatment of COVID-19"
        }
    ],
    "custodian": {
        "display": "Computable Publishing LLC"
    },
    "relatesTo": [
        {
            "type": "supported-with",
            "label": "Copyright",
            "document": {
                "url": "https://creativecommons.org/licenses/by-nc-sa/4.0/"
            }
        },
        {
            "type": "replaces",
            "resourceReference": {
                "reference": "EvidenceReport/18881",
                "type": "EvidenceReport",
                "display": "Anticoagulation for Non-critically ill COVID-19 Summary of Findings from RCTs Table EvidenceReport"
            }
        },
        {
            "type": "derived-from",
            "resourceReference": {
                "reference": "EvidenceReport/18831",
                "type": "EvidenceReport",
                "display": "Anticoagulation for Non-critically ill COVID-19 Individual RCTs Table EvidenceReport"
            }
        },
        {
            "type": "derived-from",
            "resourceReference": {
                "reference": "Evidence/18868",
                "type": "Evidence",
                "display": "Critically appraised summary of organ support-free days alive in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
            }
        },
        {
            "type": "derived-from",
            "resourceReference": {
                "reference": "Evidence/18812",
                "type": "Evidence",
                "display": "Critically appraised summary of all-cause mortality in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
            }
        },
        {
            "type": "cite-as",
            "resourceReference": {
                "reference": "Citation/50138",
                "type": "Citation",
                "display": "Anticoagulation for Non-critically ill COVID-19 Summary of Findings from RCTs Table (an Evidence Report) [FHIR Resource]. Contributors: Brian S Alper [Author]. In: Fast Evidence Interoperability Resources (FEvIR) Platform, FOI 50136. Published June 09, 2022. Created June 09, 2022. Revised June 09, 2022. Available at: https://fevir.net/resources/Composition/50136. Computable resource at: https://fevir.net/resources/Composition/50136."
            }
        }
    ],
    "section": [
        {
            "title": "Composition Definition",
            "code": {
                "coding": [
                    {
                        "system": "TBD",
                        "code": "section-generation-specification",
                        "display": "Section Generation Specification"
                    }
                ],
                "text": "Denotes a section specifying section meaning and content generation rules for a composition"
            },
            "section": [
                {
                    "title": "Outcome",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "EvidenceVariable-outcome",
                                "display": "Evidence Variable in variable role Outcome",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Outcome"
                    },
                    "entry": [
                        {
                            "display": "Label for content: Outcome"
                        },
                        {
                            "display": "Content generation rule: EvidenceVariable.title"
                        }
                    ]
                },
                {
                    "title": "Sample Size",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "SampleSize",
                                "display": "Sample size",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Sample size: # trials, # participants, # counted, # events"
                    },
                    "entry": [
                        {
                            "display": "Label for content: Sample size (# trials, # participants, # counted, # events)"
                        },
                        {
                            "display": "Content generation rule: Evidence.statistic[0].sampleSize.numberOfStudies + ' trials, ' + Evidence.statistic[0].sampleSize.numberOfParticipants + ' participants, ' + Evidence.statistic[0].sampleSize.knownDataCount + ' counted, ' + Evidence.statistic[0].numberAffected||numberOfEvents + ' events'"
                        }
                    ]
                },
                {
                    "title": "Result Without Treatment",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Control-group-alone-Evidence",
                                "display": "Evidence Results for the control exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Result with prophylactic anticoagulation"
                    },
                    "entry": [
                        {
                            "display": "Label for content: Result with prophylactic anticoagulation"
                        },
                        {
                            "display": "Content generation rule: Evidence.statistic[0].numberAffected||numberOfEvents + ' out of ' + Evidence.statistic[0].sampleSize.knownDataCount + ' observed with percentage of: ' + Evidence.statistic[statisticType.coding.code='C44256'].quantity.value*100 +'%'"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Observed",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-group-alone-Evidence",
                                "display": "Evidence Results for the intervention exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Result with therapeutic anticoagulation"
                    },
                    "entry": [
                        {
                            "display": "Label for content: Result with therapeutic anticoagulation"
                        },
                        {
                            "display": "Content generation rule: Evidence.statistic[0].numberAffected||numberOfEvents + ' out of ' + Evidence.statistic[0].sampleSize.knownDataCount + ' observed with percentage of: ' + Evidence.statistic[statisticType.coding.code='C44256'].quantity.value*100 +'%'"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Derived",
                    "code": {
                        "text": "calculated value derived from the control group and effect estimate"
                    },
                    "text": {
                        "status": "generated",
                        "div": "Result with therapeutic anticoagulation (calculated)"
                    },
                    "entry": [
                        {
                            "display": "Label for content: Result with therapeutic anticoagulation (calculated)"
                        },
                        {
                            "display": "Content generation rule: let controlEventRate = Evidence[ControlGroup].statistic[statisticType.coding.code='C44256'].quantity.value; let riskDifference = Evidence[InterventionVsControl].statistic[statisticType.coding.code='0000424'].quantity.value; let percentageResult = controlEventRate + riskDifference; percentageResult*100 +'%'"
                        }
                    ]
                },
                {
                    "title": "Effect Estimate",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-vs-Control-Evidence",
                                "display": "Evidence Results for comparison of Intervention and Control",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Effect estimate (therapeutic dose effect)"
                    },
                    "entry": [
                        {
                            "display": "Label for content: Effect estimate (therapeutic dose effect)"
                        },
                        {
                            "display": "Content generation rule: Evidence.statistic[0].statisticType.coding.display + ' ' + Evidence.statistic[0].quantity.value + ' (95% ' + Evidence.statistic[0].attributeEstimate[0].type.coding.display + ' ' Evidence.statistic[0].attributeEstimate[0].range.low.value + ' to ' + Evidence.statistic[0].attributeEstimate[0].range.high.value + ')'"
                        }
                    ]
                },
                {
                    "title": "Certainty of Evidence",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Certainty-of-Evidence",
                                "display": "Certainty of evidence",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Certainty of finding (Quality of evidence)'"
                    },
                    "entry": [
                        {
                            "display": "Label for content: Certainty of finding (Quality of evidence)"
                        },
                        {
                            "display": "Content generation rule: Evidence.certainty[type.coding.code='Overall'].rating.coding.display"
                        }
                    ]
                },
                {
                    "title": "Assertion",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Assertion",
                                "display": "Assertion",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "What this means"
                    },
                    "entry": [
                        {
                            "display": "Label for content: What this means"
                        },
                        {
                            "display": "Content generation rule: Evidence.assertion"
                        }
                    ]
                }
            ]
        },
        {
            "title": "Organ support-free days",
            "code": {
                "text": "Summary of findings entry for a single outcome"
            },
            "focus": {
                "reference": "EvidenceVariable/7753",
                "type": "EvidenceVariable",
                "display": "Organ support-free days"
            },
            "section": [
                {
                    "title": "Outcome",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "EvidenceVariable-outcome",
                                "display": "Evidence Variable in variable role Outcome",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Organ support-free days"
                    },
                    "entry": [
                        {
                            "reference": "EvidenceVariable/7753",
                            "type": "EvidenceVariable",
                            "display": "Organ support-free days"
                        }
                    ]
                },
                {
                    "title": "Sample Size",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "SampleSize",
                                "display": "Sample size",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "2 trials, 2684 participants"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18868",
                            "type": "Evidence",
                            "display": "Critically appraised summary of organ support-free days alive in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Result Without Treatment",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Control-group-alone-Evidence",
                                "display": "Evidence Results for the control exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "empty",
                        "div": "Not reported"
                    }
                },
                {
                    "title": "Result With Treatment--Observed",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-group-alone-Evidence",
                                "display": "Evidence Results for the intervention exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "empty",
                        "div": "Not reported"
                    }
                },
                {
                    "title": "Result With Treatment--Derived",
                    "code": {
                        "text": "calculated value derived from the control group and effect estimate"
                    },
                    "text": {
                        "status": "empty",
                        "div": "Not reported"
                    }
                },
                {
                    "title": "Effect Estimate",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-vs-Control-Evidence",
                                "display": "Evidence Results for comparison of Intervention and Control",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "odds ratio 1.31 (95% confidence interval 1.08 to 1.60)"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18868",
                            "type": "Evidence",
                            "display": "Critically appraised summary of organ support-free days alive in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Certainty of Evidence",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Certainty-of-Evidence",
                                "display": "Certainty of evidence",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Very low quality"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18868",
                            "type": "Evidence",
                            "display": "Critically appraised summary of organ support-free days alive in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Assertion",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Assertion",
                                "display": "Assertion",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "It is uncertain whether therapeutic-dose anticoagulation with heparin affects the likelihood of organ support-free days alive at 21 days in patients hospitalized for COVID-19 who are not critically ill."
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18868",
                            "type": "Evidence",
                            "display": "Critically appraised summary of organ support-free days alive in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                }
            ]
        },
        {
            "title": "Mortality at 28 days",
            "code": {
                "text": "Summary of findings entry for a single outcome"
            },
            "focus": {
                "reference": "EvidenceVariable/230",
                "type": "EvidenceVariable",
                "display": "Mortality at 28 days"
            },
            "section": [
                {
                    "title": "Outcome",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "EvidenceVariable-outcome",
                                "display": "Evidence Variable in variable role Outcome",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Mortality at 28 days"
                    },
                    "entry": [
                        {
                            "reference": "EvidenceVariable/230",
                            "type": "EvidenceVariable",
                            "display": "Mortality at 28 days"
                        }
                    ]
                },
                {
                    "title": "Sample Size",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "SampleSize",
                                "display": "Sample size",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "2 trials, 2,684 participants, 2,684 counted,194 events"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18812",
                            "type": "Evidence",
                            "display": "Critically appraised summary of all-cause mortality in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Result Without Treatment",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Control-group-alone-Evidence",
                                "display": "Evidence Results for the control exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "104 out of 1,285 observed with percentage of: 8.1%"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/103607",
                            "type": "Evidence",
                            "display": "104 events in 1,285 participants (8.1%) in pooled control group"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Observed",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-group-alone-Evidence",
                                "display": "Evidence Results for the intervention exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "90 out of 1,399 observed with percentage of: 6.4%"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/103608",
                            "type": "Evidence",
                            "display": "90 events in 1,399 participants (6.4%) in pooled control group"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Derived",
                    "code": {
                        "text": "calculated value derived from the control group and effect estimate"
                    },
                    "text": {
                        "status": "generated",
                        "div": "6.1%"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/103607",
                            "type": "Evidence",
                            "display": "104 events in 1,285 participants (8.1%) in pooled control group"
                        },
                        {
                            "reference": "Evidence/18812",
                            "type": "Evidence",
                            "display": "Critically appraised summary of all-cause mortality in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Effect Estimate",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-vs-Control-Evidence",
                                "display": "Evidence Results for comparison of Intervention and Control",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Risk difference -2% (95% CI -3.6% to 0.1%)"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18812",
                            "type": "Evidence",
                            "display": "Critically appraised summary of all-cause mortality in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Certainty of Evidence",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Certainty-of-Evidence",
                                "display": "Certainty of evidence",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Very low quality"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18812",
                            "type": "Evidence",
                            "display": "Critically appraised summary of all-cause mortality in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Assertion",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Assertion",
                                "display": "Assertion",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "It is uncertain whether therapeutic-dose anticoagulation with heparin affects the likelihood of any death at 28 days in patients hospitalized for COVID-19 who are not critically ill."
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18812",
                            "type": "Evidence",
                            "display": "Critically appraised summary of all-cause mortality in meta-analysis of RCTs of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                }
            ]
        },
        {
            "title": "Survival to hospital discharge without cardiovascular or respiratory organ support for the first 21 days",
            "code": {
                "text": "Summary of findings entry for a single outcome"
            },
            "focus": {
                "reference": "EvidenceVariable/7754",
                "type": "EvidenceVariable",
                "display": "Survival to hospital discharge without cardiovascular or respiratory organ support for the first 21 days"
            },
            "section": [
                {
                    "title": "Outcome",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "EvidenceVariable-outcome",
                                "display": "Evidence Variable in variable role Outcome",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Survival to hospital discharge without cardiovascular or respiratory organ support for the first 21 days"
                    },
                    "entry": [
                        {
                            "reference": "EvidenceVariable/7754",
                            "type": "EvidenceVariable",
                            "display": "Survival to hospital discharge without cardiovascular or respiratory organ support for the first 21 days"
                        }
                    ]
                },
                {
                    "title": "Sample Size",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "SampleSize",
                                "display": "Sample size",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "1 trial, 2,244 participants, 2,219 counted, 1,740 events"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/7639",
                            "type": "Evidence",
                            "display": "Critically appraised summary of transformed primary outcome of multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Result Without Treatment",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Control-group-alone-Evidence",
                                "display": "Evidence Results for the control exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "801 out of 1,048 observed with percentage of: 76.4%"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18814",
                            "type": "Evidence",
                            "display": "Transformed primary outcome with prophylactic-dose anticoagulation in multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Observed",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-group-alone-Evidence",
                                "display": "Evidence Results for the intervention exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "939 out of 1,171 observed with percentage of: 80.2%"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18813",
                            "type": "Evidence",
                            "display": "Transformed primary outcome with therapeutic-dose anticoagulation in multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Derived",
                    "code": {
                        "text": "calculated value derived from the control group and effect estimate"
                    },
                    "text": {
                        "status": "additional",
                        "div": "80.4%'"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18814",
                            "type": "Evidence",
                            "display": "Transformed primary outcome with prophylactic-dose anticoagulation in multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        },
                        {
                            "reference": "Evidence/7639",
                            "type": "Evidence",
                            "display": "Critically appraised summary of transformed primary outcome of multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Effect Estimate",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-vs-Control-Evidence",
                                "display": "Evidence Results for comparison of Intervention and Control",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Risk difference 4% (95% credible interval 0.5% to 7.2%)"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/7639",
                            "type": "Evidence",
                            "display": "Critically appraised summary of transformed primary outcome of multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Certainty of Evidence",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Certainty-of-Evidence",
                                "display": "Certainty of evidence",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Very low quality"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/7639",
                            "type": "Evidence",
                            "display": "Critically appraised summary of transformed primary outcome of multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Assertion",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Assertion",
                                "display": "Assertion",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "It is uncertain whether therapeutic-dose anticoagulation with heparin affects the likelihood of any death or organ support in patients hospitalized for COVID-19 who are not critically ill."
                    },
                    "entry": [
                        {
                            "reference": "Evidence/7639",
                            "type": "Evidence",
                            "display": "Critically appraised summary of transformed primary outcome of multi-platform RCT of anticoagulation for non-critically ill patients with COVID-19"
                        }
                    ]
                }
            ]
        },
        {
            "title": "Death, invasive mechanical ventilation, non-invasive mechanical ventilation, or ICU admission up to 28 days",
            "code": {
                "text": "Summary of findings entry for a single outcome"
            },
            "focus": {
                "reference": "EvidenceVariable/18134",
                "type": "EvidenceVariable",
                "display": "Death, invasive mechanical ventilation, non-invasive mechanical ventilation, or ICU admission up to 28 days"
            },
            "section": [
                {
                    "title": "Outcome",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "EvidenceVariable-outcome",
                                "display": "Evidence Variable in variable role Outcome",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Death, invasive mechanical ventilation, non-invasive mechanical ventilation, or ICU admission up to 28 days"
                    },
                    "entry": [
                        {
                            "reference": "EvidenceVariable/18134",
                            "type": "EvidenceVariable",
                            "display": "Death, invasive mechanical ventilation, non-invasive mechanical ventilation, or ICU admission up to 28 days"
                        }
                    ]
                },
                {
                    "title": "Sample Size",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "SampleSize",
                                "display": "Sample size",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "1 trial, 465 participants, 465 counted, 89 events"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18098",
                            "type": "Evidence",
                            "display": "Critically appraised summary of primary composite outcome of RAPID trial RCT of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Result Without Treatment",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Control-group-alone-Evidence",
                                "display": "Evidence Results for the control exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "52 out of 237 observed with percentage of: 21.9%"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/103609",
                            "type": "Evidence",
                            "display": "Primary composite outcome of RAPID trial RCT of heparin for moderately ill patients with COVID-19 in Usual Care group"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Observed",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-group-alone-Evidence",
                                "display": "Evidence Results for the intervention exposure only",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "37 out of 228 observed with percentage of: 16.2%"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/103610",
                            "type": "Evidence",
                            "display": "37 out of 228 (16.2%)"
                        }
                    ]
                },
                {
                    "title": "Result With Treatment--Derived",
                    "code": {
                        "text": "calculated value derived from the control group and effect estimate"
                    },
                    "text": {
                        "status": "additional",
                        "div": "16.2%'"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/103609",
                            "type": "Evidence",
                            "display": "Primary composite outcome of RAPID trial RCT of heparin for moderately ill patients with COVID-19 in Usual Care group"
                        },
                        {
                            "reference": "Evidence/18098",
                            "type": "Evidence",
                            "display": "Critically appraised summary of primary composite outcome of RAPID trial RCT of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Effect Estimate",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Intervention-vs-Control-Evidence",
                                "display": "Evidence Results for comparison of Intervention and Control",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Risk difference -5.7% (95% CI -11.4% to 1.7%)"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18098",
                            "type": "Evidence",
                            "display": "Critically appraised summary of primary composite outcome of RAPID trial RCT of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Certainty of Evidence",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Certainty-of-Evidence",
                                "display": "Certainty of evidence",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "Low quality"
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18098",
                            "type": "Evidence",
                            "display": "Critically appraised summary of primary composite outcome of RAPID trial RCT of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                },
                {
                    "title": "Assertion",
                    "code": {
                        "coding": [
                            {
                                "system": "http://hl7.org/fhir/evidence-report-section",
                                "code": "Assertion",
                                "display": "Assertion",
                                "userSelected": true
                            }
                        ]
                    },
                    "text": {
                        "status": "generated",
                        "div": "It is uncertain whether therapeutic-dose anticoagulation with heparin affects the rate of death or critical care in patients hospitalized for COVID-19 who are not critically ill."
                    },
                    "entry": [
                        {
                            "reference": "Evidence/18098",
                            "type": "Evidence",
                            "display": "Critically appraised summary of primary composite outcome of RAPID trial RCT of heparin for moderately ill patients with COVID-19"
                        }
                    ]
                }
            ]
        }
    ],
    "identifier": [
        {
            "type": {
                "text": "FEvIR Object Identifier"
            },
            "system": "https://fevir.net",
            "value": "52356",
            "assigner": {
                "display": "Computable Publishing LLC"
            }
        }
    ],
    "author": [
        {
            "name": "Brian S. Alper"
        }
    ]
}
*/

const createCompositionTableDict = (compositionJson) => {
    const sections = compositionJson.section || [];
    let compositionTableDict = { "Labels": {}, "Data": {} }
    let tableIndex = {};
    for (const section of sections) {
        if (section.code?.coding && section.code?.coding[0].code && section.code?.coding[0].code.toLowerCase() === "column-headers") {
            for (const subsection of section.section) {
                let label;
                if (subsection.entry) {
                    for (const entry of subsection.entry) {
                        if (entry.display.startsWith('Label for content: ')) {
                            label = entry.display.replace('Label for content: ', '');
                        }
                    }
                } else {
                    label = subsection.text?.div;
                }
                label = label;
                compositionTableDict["Labels"][label] = label;
                tableIndex[label] = JSON.stringify(subsection.code);
            }
        } else if ((section.code) && ((section.code.coding &&
            (section.code.coding[0].code === "table-row-entry" ||
                section.code.coding[0].code === "summary-of-findings-entry-for-a-single-outcome" ||
                section.code.coding[0].code === "summary-of-net-effect-contributions-entry-for-a-single-outcome")) ||
            section.code.text === "Summary of findings entry for a single outcome" ||
            section.code.text === "Summary of net effect contributions entry for a single outcome")) {
            let sectionTitle = section.title;
            compositionTableDict["Data"][sectionTitle] = {};
            if ((section.text?.status === "additional" || section.text?.status === "generated") &&
                section.text.div) {
                compositionTableDict["Data"][sectionTitle]["REPLACEROW"] = section.text.div;
            } else {
                for (const subsection of section.section) {
                    let label;
                    let sectionCode = JSON.stringify(subsection.code);
                    for (const tableIndexLabel in tableIndex) {
                        if (tableIndex[tableIndexLabel] === sectionCode) {
                            label = tableIndexLabel;
                            compositionTableDict["Data"][sectionTitle][label] = subsection.text?.div;
                            if (!subsection.text || subsection.text.status === "empty") {
                                if (subsection.emptyReason) {
                                    let emptyReasonString = "";
                                    if (subsection.emptyReason.coding?.length > 0) {
                                        if (subsection.emptyReason.coding[0].display) {
                                            emptyReasonString = subsection.emptyReason.coding[0].display;
                                        } else if (subsection.emptyReason.coding[0].code) {
                                            emptyReasonString = subsection.emptyReason.coding[0].code;
                                        }
                                    }
                                    if (subsection.emptyReason.text) {
                                        emptyReasonString += " " + subsection.emptyReason.text;
                                    }
                                    if (emptyReasonString) {
                                        compositionTableDict["Data"][sectionTitle][label] = "NO DATA: " + emptyReasonString;
                                    }
                                } else {
                                    compositionTableDict["Data"][sectionTitle][label] = "<div xmlns=\"http://www.w3.org/1999/xhtml\"><div style=\"color: #AAAAAA;\">[No summary statement.]</div></div>";
                                }
                            }
                            break
                        }
                    }
                    if (!label) { console.log(subsection.title); }
                }
            }
        }
    }
    return compositionTableDict;
}

export default createCompositionTableDict;


